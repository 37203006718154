/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useLocation, Location } from 'react-router-dom';
import { IMenuItem } from '@app/modules/main/menu-sidebar/MenuSidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
const MenuItem = ({ menuItem }: { menuItem: IMenuItem }) => {
	const [isMenuExtended, setIsMenuExtended] = useState(false);
	const [isExpandable, setIsExpandable] = useState(false);
	const [isMainActive, setIsMainActive] = useState(false);
	const [isOneOfChildrenActive, setIsOneOfChildrenActive] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();

	const toggleMenu = () => {
		setIsMenuExtended(!isMenuExtended);
	};

	const handleMainMenuAction = () => {
		if (isExpandable) {
			toggleMenu();
			return;
		}
		navigate(menuItem.path ? menuItem.path : '/');
	};

	const calculateIsActive = (url: Location) => {
		setIsMainActive(false);
		setIsOneOfChildrenActive(false);
		if (isExpandable && menuItem && menuItem.children) {
			menuItem.children.forEach((item) => {
				if (item.path === url.pathname) {
					setIsOneOfChildrenActive(true);
					setIsMenuExtended(true);
				}
			});
		} else if (menuItem.path === url.pathname) {
			setIsMainActive(true);
		}
	};

	useEffect(() => {
		if (location) {
			calculateIsActive(location);
		}
	}, [location, isExpandable, menuItem]);

	useEffect(() => {
		if (!isMainActive && !isOneOfChildrenActive) {
			setIsMenuExtended(false);
		}
	}, [isMainActive, isOneOfChildrenActive]);

	useEffect(() => {
		setIsExpandable(
			Boolean(menuItem && menuItem.children && menuItem.children.length > 0)
		);
	}, [menuItem]);

	return (
		<li className={`nav-item${isMenuExtended ? ' menu-open' : ''}`}>
			<a
				className={`nav-link${
					isMainActive || isOneOfChildrenActive
						? ' active selected bg-secondary'
						: ''
				}`}
				role='link'
				onClick={handleMainMenuAction}
				style={{ cursor: 'pointer', color: 'white' }}
			>
				{menuItem.icon}
				<p>{menuItem.name}</p>
				{isExpandable ? (
					<FontAwesomeIcon icon={faAngleLeft} className='right' />
				) : null}
			</a>
			{isExpandable &&
				menuItem &&
				menuItem.children &&
				menuItem.children.map((item) => (
					<ul key={item.name} className='nav nav-treeview'>
						<li className='nav-item'>
							<NavLink className='nav-link text-white bg-main' to={`${item.path}`}>
								{item.icon}
								<p>{item.name}</p>
							</NavLink>
						</li>
					</ul>
				))}
		</li>
	);
};

export default MenuItem;