import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Form, InputGroup, Spinner, Button, Dropdown } from 'react-bootstrap';
import { ContentHeader } from '@components';
import { testPaperGenerator } from '@app/services/generator';
import { useDispatch, useSelector } from 'react-redux';
import { setTestPaper } from '@app/store/reducers/testPaper';
import TimeProgressBar from '@app/components/progressBar/TimeProgressBar';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { getTestPaperFormtData, getTestPaperSessionData } from '@app/utils/helpers';
import { bigScreenCheck } from '@app/utils/helpers';

const TestPaperGenerator = () => {
	const bigScreen = bigScreenCheck();
	const [isGenerating, setIsGenerating] = useState(false);
	const [show, setShow] = useState(true);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const dropdownOptions = [
		{ value: '10', label: '10 questions' },
		{ value: '20', label: '20 questions' },
		{ value: '30', label: '30 questions' }
	];

	const testPaperData = getTestPaperSessionData();
	const [testPaperForm, setTestPaperForm] = useState(getTestPaperFormtData());

	useEffect(() => {
		if (testPaperData === undefined) {
			setShow(true);
		} else setShow(false);
	}, [testPaperData]);

	const handleNewTestPaper = () => {
		const newForm = {
			title: '',
			totalMarks: '',
			unitChapter: '',
			gradeLevel: '',
			timeLimit: '',
			numberOfQuestions: '',
			board: '',
			subject: ''
		};
		localStorage.setItem('test-paper-form', JSON.stringify(newForm));
		setTestPaperForm(newForm);
		setIsGenerating(false);
		location.reload();
	};

	const { handleChange, values, handleSubmit, touched, errors } = useFormik({
		initialValues: testPaperForm,
		validationSchema: Yup.object({
			title: Yup.string().required(),
			totalMarks: Yup.string().required(),
			unitChapter: Yup.string().required(),
			gradeLevel: Yup.string().required(),
			timeLimit: Yup.string().required(),
			numberOfQuestions: Yup.string().required(),
			board: Yup.string().required(),
			subject: Yup.string().required()
		}),

		onSubmit: async (values) => {
			console.log('values getting on submit : ', values);

			setIsGenerating(true);
			try {
				const response = await testPaperGenerator(values);
				if (response.status === 201) {
					dispatch(setTestPaper(response.data));
					sessionStorage.setItem('testPaperData', JSON.stringify(response.data));
					toast.success('Testpaper successfully created');
					setTimeout(() => {
						navigate('/test-paper-plan');
					}, 3000);
				} else {
					setIsGenerating(false);
					// toast.error('Failed to create test paper. Please try again.');
				}
			} catch (error) {
				setIsGenerating(false);
			}
		}
	});

	const handleLastTestPaper = (e: any) => {
		navigate('/test-paper-history');
		setTestPaperForm(testPaperForm);
	};

	return (
		<div>
			<ContentHeader title={'Test Paper Generator'} />
			{/* <div className='d-flex justify-content-end me-5 my-n4'>
				<button className='btn-outline-purple px-5' onClick={handleNewTestPaper} style={{ height: '50px' }}>
					New Test Paper
				</button>
			</div> */}
			<div className={bigScreen ? 'mx-5' : ''}>
				<form onSubmit={handleSubmit}>
					{/* Title  */}
					<div className='mb-3'>
						<div className='fs-5 py-3'>Title</div>
						<InputGroup className='mb-3'>
							<Form.Control
								className='p-4'
								id='title'
								name='title'
								type='text'
								onChange={handleChange}
								value={values.title}
								isValid={touched.title && !errors.title}
								isInvalid={touched.title && !!errors.title}
							/>
							{/* {touched.grade && errors.grade ? (
							<Form.Control.Feedback type='invalid'>{errors.grade}</Form.Control.Feedback>
						) : null} */}
						</InputGroup>
					</div>

					{/* subject  */}
					<div className='mb-3'>
						<div className='fs-5 py-3'>Subject</div>
						<InputGroup className='mb-3'>
							<Form.Control
								className='p-4'
								id='subject'
								name='subject'
								type='text'
								onChange={handleChange}
								value={values.subject}
								isValid={touched.subject && !errors.subject}
								isInvalid={touched.subject && !!errors.subject}
							/>
							{/* {touched.subject && errors.subject ? (
							<Form.Control.Feedback type='invalid'>{errors.subject}</Form.Control.Feedback>
						) : null} */}
						</InputGroup>
					</div>

					<div className='mb-3'>
						<div className='fs-5 py-3'>Chapter</div>
						<InputGroup className='mb-3'>
							<Form.Control
								className='p-4'
								id='unitChapter'
								name='unitChapter'
								type='text'
								onChange={handleChange}
								value={values.unitChapter}
								isValid={touched.unitChapter && !errors.unitChapter}
								isInvalid={touched.unitChapter && !!errors.unitChapter}
							/>
							{/* {touched.chapter && errors.chapter ? (
							<Form.Control.Feedback type='invalid'>{errors.chapter}</Form.Control.Feedback>
						) : null} */}
						</InputGroup>
					</div>

					{/* grade */}
					<div className='mb-3'>
						<div className='fs-5 py-3'>Grade</div>
						<InputGroup className='mb-3'>
							<Form.Control
								className='p-4'
								id='gradeLevel'
								name='gradeLevel'
								type='text'
								onChange={handleChange}
								value={values.gradeLevel}
								isValid={touched.gradeLevel && !errors.gradeLevel}
								isInvalid={touched.gradeLevel && !!errors.gradeLevel}
							/>
							{/* {touched.topic && errors.topic ? (
							<Form.Control.Feedback type='invalid'>{errors.topic}</Form.Control.Feedback>
						) : null} */}
						</InputGroup>
					</div>

					{/* time limit */}
					<div className='mb-3'>
						<div className='fs-5 py-3'>Time Limit</div>
						<InputGroup className='mb-3'>
							<Form.Control
								className='p-4'
								id='timeLimit'
								name='timeLimit'
								type='text'
								onChange={handleChange}
								value={values.timeLimit}
								isValid={touched.timeLimit && !errors.timeLimit}
								isInvalid={touched.timeLimit && !!errors.timeLimit}
							/>
							{/* {touched.topic && errors.topic ? (
							<Form.Control.Feedback type='invalid'>{errors.topic}</Form.Control.Feedback>
						) : null} */}
						</InputGroup>
					</div>

					{/* total marks */}
					<div className='mb-3'>
						<div className='fs-5 py-3'>Total Marks</div>
						<InputGroup className='mb-3'>
							<Form.Control
								className='p-4'
								id='totalMarks'
								name='totalMarks'
								type='text'
								onChange={handleChange}
								value={values.totalMarks}
								isValid={touched.totalMarks && !errors.totalMarks}
								isInvalid={touched.totalMarks && !!errors.totalMarks}
							/>
							{/* {touched.topic && errors.topic ? (
							<Form.Control.Feedback type='invalid'>{errors.topic}</Form.Control.Feedback>
						) : null} */}
						</InputGroup>
					</div>

					{/* number of questions */}
					{/* Number of Questions Dropdown */}
					{/* Number of Questions Dropdown */}
					<div className='mb-3'>
						<div className='fs-5 py-3'>Number of Questions</div>
						<InputGroup className='mb-3'>
							<Form.Select
								className='p-3'
								id='numberOfQuestions'
								name='numberOfQuestions'
								value={values.numberOfQuestions || ''}
								onChange={handleChange}
								isValid={touched.numberOfQuestions && !errors.numberOfQuestions}
								isInvalid={touched.numberOfQuestions && !!errors.numberOfQuestions}
							>
								<option value='' disabled>
									Select number of questions
								</option>
								{dropdownOptions.map((option) => (
									<option key={option.value} value={option.value}>
										{option.label}
									</option>
								))}
							</Form.Select>
						</InputGroup>
					</div>

					{/* board */}
					<div className='mb-3'>
						<div className='fs-5 py-3'>Board</div>
						<InputGroup className='mb-3'>
							<Form.Control
								className='p-4'
								id='board'
								name='board'
								type='text'
								onChange={handleChange}
								value={values.board}
								isValid={touched.board && !errors.board}
								isInvalid={touched.board && !!errors.board}
							/>
							{/* {touched.topic && errors.topic ? (
							<Form.Control.Feedback type='invalid'>{errors.topic}</Form.Control.Feedback>
						) : null} */}
						</InputGroup>
					</div>

					<div>
						{isGenerating ? (
							<TimeProgressBar />
						) : (
							<div className={`d-flex justify-content-between my-4`}>
								<button
									className={`btn btn-outline-secondary px-3 mx-1`}
									onClick={(e) => handleLastTestPaper(e)}
									style={{ height: '50px' }}
								>
									Test Paper History
								</button>
								<button className='btn-purple px-5' type='submit' style={{ height: '50px' }}>
									Generate
								</button>
							</div>
						)}
					</div>
				</form>
			</div>
		</div>
	);
};

export default TestPaperGenerator;
