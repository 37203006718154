import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpFromBracket } from '@fortawesome/free-solid-svg-icons';

function ExportButton({ docxChild, pdfChild }: { docxChild: any, pdfChild: any }) {
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	return (
		<>
			<button className='btn-purple py-2 px-3' onClick={handleShow}>
				<FontAwesomeIcon icon={faArrowUpFromBracket} />
				<span className='px-2'>Export</span>
			</button>
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Export the document</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div style={{ height: '100px' }}>
					{/* <div className='w-100' style={{ width: '100px' }}>
							{docxChild}
						</div> */}
						<div className='w-100' style={{ width: '100px' }}>
							{pdfChild}
						</div>
					</div>
				</Modal.Body>
				{/* <Modal.Footer>
					<Button variant='secondary' onClick={handleClose}>
						Close
					</Button>
					<Button variant='primary' onClick={handleClose}>
						Save Changes
					</Button>
				</Modal.Footer> */}
			</Modal>
		</>
	);
}

export default ExportButton;
