import { useState } from 'react';

export const AnswerModel = ({ data }: any) => {
	const [hideAnswer, setHideAnswer] = useState(true);

	return (
		<div className='d-flex justify-content-between'>
			<div>
				&nbsp;
				<div className={hideAnswer ? 'd-none' : 'text-secondary mt-n3'}>
					Ans: {data?.correctAnswer || data?.correct_answer || data?.answer}
				</div>
			</div>
			<button className='btn btn-sm btn-outline border-bottom' onClick={() => setHideAnswer(!hideAnswer)}>
				{hideAnswer ? 'Show Answer' : 'Hide Answer'}
			</button>
		</div>
	);
};

export const QuestionModel = ({ data, quesNo }: any) => {
	const [selectedOption, setSelectedOption] = useState('');

	return (
		<div className='mt-2 ml-3 mb-2' style={{
			fontFamily: 'Poppins, sans-serif',
		}}>
			{quesNo}. {data?.question} <br />
			{data?.options?.map((option: any, index: number) => (
				<div className='px-3' key={index}>
					<input
						type='radio'
						value={option}
						checked={selectedOption === option}
						onChange={(e) => setSelectedOption(e.target.value)}
					/>
					<span className='px-2'>{option}</span>
				</div>
			))}
			{data?.choices?.map((option: any, index: number) => (
				<div className='px-3' key={index}>
					<input
						type='radio'
						value={option}
						checked={selectedOption === option}
						onChange={(e) => setSelectedOption(e.target.value)}
					/>
					<span className='px-2'>{option}</span>
				</div>
			))}
		</div>
	);
};

export const MatchingModel = ({ data, quesNo }: any) => {
	return (
		<div className='border rounded-3 p-3 my-3'>
			<div className='fs-5 pb-2'>
				{quesNo}. {data?.question} <br />
			</div>
			<div className='fs-5 d-flex justify-content-between'>
				<div>
					{data?.options?.map((option: string) => {
						return <div>{option}</div>;
					})}
				</div>
				<div>
					{data?.correctAnswer?.map((answer: string) => {
						return <div>{answer}</div>;
					})}
				</div>
				<br />
			</div>
		</div>
	);
};

export const SolutionModel = ({ quizData }: any) => {
	return (
		<div className='border rounded'>
			{quizData?.questions?.map((data: any, index: number) => (
				<div className='p-2 mx-3' key={index}>
					<div>Q: {data?.question}</div>
					<div className=''>Ans: {data?.correctAnswer || data?.correct_answer || data?.answer}</div>
				</div>
			))}
		</div>
	);
};
