import { createSlice } from '@reduxjs/toolkit';

export interface UserState {
	userInfo?: any;
}

const initialState: UserState = {
	userInfo: undefined
};

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUserInfo: (state: any, { payload }: any) => {
			state.userInfo = payload;
		}
	}
});

export const { setUserInfo } = userSlice.actions;

export default userSlice.reducer;