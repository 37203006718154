import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from '@app/App';
import store from '@store/store';
import { GoogleOAuthProvider } from '@react-oauth/google';

// import './utils/i18n';
import './index.css';
import './scss/styles.scss';
// import * as bootstrap from 'bootstrap';
import * as serviceWorker from './serviceWorker';

declare const window: any;

window.PF = {
	config: {
		mode: 'bs4'
	}
};

const container: any = document.getElementById('root');
const root = createRoot(container);
root.render(
	<GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}>
		<Provider store={store}>
			<App />
		</Provider>
	</GoogleOAuthProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
