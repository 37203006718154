import { createSlice } from '@reduxjs/toolkit';

export interface AuthState {
	testPaperData?: any;
}

const initialState: AuthState = {
	testPaperData: undefined
};

export const testPaperSlice: any = createSlice({
	name: 'testPaper',
	initialState,
	reducers: {
		setTestPaper: (state: any, { payload }: any) => {
			state.testPaperData = payload;
			console.log('testPaper payload ', payload);
		}
	}
});

export const { setTestPaper } = testPaperSlice.actions;

export default testPaperSlice.reducer;
