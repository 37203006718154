import { ContentHeader } from '@app/components';
import ExportToDocx from '@app/components/export/DocsExport';
import ExportButton from '@app/components/export/ExportButton';
import ExportToPDF from '@app/components/export/PdfExport';
import { AnswerModel, QuestionModel } from '@app/components/quiz-model/quiz-model';
import { getAllQuiz } from '@app/services/databaseApi';
import { setQuiz } from '@app/store/reducers/quiz';
import { bigScreenCheck, getQuizSessionData } from '@app/utils/helpers';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux/';
import { useNavigate } from 'react-router-dom';
import { deleteQuiz } from '@app/services/databaseApi';
import QuizQuestionPdfExport from '@app/components/export/quiz/QuizQuestionPdfExport';

interface Questions {
	question: string;
	choices: string[];
	correctAnswer: string;
}

interface QuizData {
	quiztype: string;
	difficulty: string;
	questions: Questions[];
}

const QuizQuestions = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const bigScreen = bigScreenCheck();

	const queryParameters = new URLSearchParams(location.search);
	const id = queryParameters.get('id') || '0';
	const quizData = useSelector((state: any) => state?.quiz?.quizData);
	
	useEffect(() => {
		fetchQuizHistory();
	}, []);

	const fetchQuizHistory = async () => {
		const quizHistoryList = await getAllQuiz();
		dispatch(setQuiz(quizHistoryList[id]));
		console.log('quizData', quizData);
	};
	
	const handleDelete = async () => {
		await deleteQuiz(quizData?._id);

		navigate('/quiz-history');
	};

	const handleClick = () => {
        navigate("/quiz-history");
    };

	return (
		<div>
			<ContentHeader title={'Quiz Questions'} />
			{/* <div className='d-flex justify-content-end me-5 mt-n5'>
				<button className='btn-outline-purple px-5' onClick={() => navigate('/quiz')} style={{ height: '50px' }}>
					New Quiz
					</button>
				</div> */}
			<div className={`mb-3 ${bigScreen ? 'mx-5': ''}`}>
				<div className='d-flex justify-content-between'>
				<div className='d-flex btn-purple'>
                        <button className='btn' onClick={handleClick} style={{ color: "white", backgroundColor: "#a084dc", width: "66px" }}>
                            Back
                        </button>
                    </div>
					<div> 
					<ExportButton
						docxChild={
							<ExportToDocx
								child={
									<div>
										{quizData?.questions?.map((data: any, index: number) => (
											<div className='border rounded p-3 my-3' key={index}>
												<QuestionModel data={data} quesNo={index + 1} />
											</div>
										))}
									</div>
								}
								fileName={'quiz-question'}
								innerClass={'d-flex justify-content-center'}
							/>
						}
						pdfChild={
							<QuizQuestionPdfExport
								fileName={'Quiz questions'}
								innerClass={'d-flex justify-content-center'}
								jsonData={quizData}
							/>
						}
					/>
					</div>
				</div>
				<div style={{
			fontFamily: 'Poppins, sans-serif',
            
		}}>
				<div className=''>
					{quizData?.questions?.map((data: any, index: number) => (
						<div className='border rounded my-3' key={index}>
							<div className='mt-2'> <QuestionModel data={data} quesNo={index + 1} /></div>
						</div>
					))}
				</div>
				<div className='d-flex justify-content-between'>
					<button className='btn btn-outline-danger py-2 px-3' onClick={handleDelete}>
						Delete Quiz
					</button>
					<button className='btn-purple  py-2 px-3' onClick={() => navigate('/quiz-solution')}>
						View Solution
					</button>
				</div>
			</div>
		</div>
		</div>
	);
};

export default QuizQuestions;
