import Generator from '@app/components/generator/Generator';

const Academic = () => {
  return (
    <div>
        <Generator header='Academic Calendar Generator'/>
    </div>
  )
}

export default Academic