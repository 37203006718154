import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Form, InputGroup, Spinner, Button } from 'react-bootstrap';
import { ContentHeader } from '@components';
import { assignmentGenerator } from '@app/services/generator';
import { useDispatch, useSelector } from 'react-redux';
import { setAssignment } from '@app/store/reducers/assignment';
import TimeProgressBar from '@app/components/progressBar/TimeProgressBar';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { bigScreenCheck, getAssignmenFormtData, getAssignmentSessionData } from '@app/utils/helpers';

// assignment
const AssignmentGenerator = () => {
	const [isGenerating, setIsGenerating] = useState(false);
	const [show, setShow] = useState(true);
	const bigScreen = bigScreenCheck();

	const navigate = useNavigate();
	const dispatch = useDispatch();
	// const assignmentData = getLessonSessionData();
	const assignmentData = getAssignmentSessionData();
	// const [lessonForm, setLessonForm] = useState(getLessonFormData());
	const [assignmentForm, setAssignmentForm] = useState(getAssignmenFormtData());

	useEffect(() => {
		if (assignmentData === undefined) {
			setShow(true);
		} else setShow(false);
	}, [assignmentData]);

	const handleNewAssignment = () => {
		const newForm = {
			subject: '',
			assignmentType: '',
			topic: '',
			grade: ''
		};
		localStorage.setItem('assignment-form', JSON.stringify(newForm));
		setAssignmentForm(newForm);
		setIsGenerating(false);
		location.reload();
	};

	const { handleChange, values, handleSubmit, touched, errors } = useFormik({
		initialValues: assignmentForm,
		validationSchema: Yup.object({
			subject: Yup.string().required(),
			assignmentType: Yup.string().required(),
			topic: Yup.string().required(),
			grade: Yup.string().required()
		}),

		onSubmit: async (values) => {
			setIsGenerating(true);
			try {
				const response = await assignmentGenerator(values);
				if (response.status === 201) {
					dispatch(setAssignment(response.data));
					sessionStorage.setItem('assignmentData', JSON.stringify(response.data));
					toast.success('Assignment successfully created');
					setTimeout(() => {
						navigate('/assignment-plan');
					}, 3000);
				} else {
					setIsGenerating(false);
					// toast.error('Failed to create assignment. Please try again.');
				}
			} catch (error) {
				setIsGenerating(false);
				//   toast.error('Failed to create assignment. Please try again.');
			}
		}
	});

	const handleLastAssignment = (e: any) => {
		navigate('/assignment-history');
		setAssignmentForm(assignmentForm);
	};

	return (
		<div>
			<ContentHeader title={'Assignment Generator'} />

			<div className='mx-5'>
				<form onSubmit={handleSubmit}>
					{isGenerating ? (
						<TimeProgressBar />
					) : (
						<div className={bigScreen ? 'mx-5' : 'mx-1'}>
							<div>
								<div className={`fs-5 ${bigScreen ? 'py-3' : 'py-1'}`}>Grade</div>
								<InputGroup className='mb-3'>
									<Form.Control
										className='p-4'
										id='grade'
										name='grade'
										type='text'
										onChange={handleChange}
										value={values.grade}
										isValid={touched.grade && !errors.grade}
										isInvalid={touched.grade && !!errors.grade}
									/>
									{/* {touched.grade && errors.grade ? (
												<Form.Control.Feedback type='invalid'>{errors.grade}</Form.Control.Feedback>
											) : null} */}
								</InputGroup>
							</div>
							<div>
								<div className={`fs-5 ${bigScreen ? 'py-3' : 'py-1'}`}>Subject</div>
								<InputGroup className='mb-3'>
									<Form.Control
										className='p-4'
										id='subject'
										name='subject'
										type='text'
										onChange={handleChange}
										value={values.subject}
										isValid={touched.subject && !errors.subject}
										isInvalid={touched.subject && !!errors.subject}
									/>
									{/* {touched.subject && errors.subject ? (
												<Form.Control.Feedback type='invalid'>{errors.subject}</Form.Control.Feedback>
											) : null} */}
								</InputGroup>
							</div>
							<div className='mb-3'>
								<div className={`fs-5 ${bigScreen ? 'py-3' : 'py-1'}`}>Assignment Type</div>
								<InputGroup className='mb-3'>
									<Form.Select
										className='p-2'
										id='assignmentType'
										name='assignmentType'
										onChange={handleChange}
										value={values.assignmentType}
										isValid={touched.assignmentType && !errors.assignmentType}
										isInvalid={touched.assignmentType && !!errors.assignmentType}
									>
										<option value=''>Select Assignment Type</option>
										<option value='Group work'>Group work</option>
										<option value='Essay'>Essay</option>
										<option value='Subjective'>Subjective</option>
										<option value='Long-answer'>Long-answer</option>
									</Form.Select>
									{/* {touched.assignmentType && errors.assignmentType ? (
            <Form.Control.Feedback type='invalid'>{errors.assignmentType}</Form.Control.Feedback>
        ) : null} */}
								</InputGroup>
							</div>

							<div className='mb-3'>
								<div className={`fs-5 ${bigScreen ? 'py-3' : 'py-1'}`}>topic</div>
								<InputGroup className='mb-3'>
									<Form.Control
										className='p-4'
										id='topic'
										name='topic'
										type='text'
										onChange={handleChange}
										value={values.topic}
										isValid={touched.topic && !errors.topic}
										isInvalid={touched.topic && !!errors.topic}
									/>
									{/* {touched.topic && errors.topic ? (
												<Form.Control.Feedback type='invalid'>{errors.topic}</Form.Control.Feedback>
											) : null} */}
								</InputGroup>
							</div>

							<div>
								<div className={`d-flex justify-content-between my-4`}>
									<button
										className={`btn btn-outline-secondary px-3 mx-1`}
										onClick={(e) => handleLastAssignment(e)}
										style={{ height: '50px' }}
									>
										Assignment History
									</button>
									<button className='btn-purple px-5' type='submit' style={{ height: '50px' }}>
										Generate
									</button>
								</div>
							</div>
						</div>
					)}
				</form>
			</div>
		</div>
	);
};

export default AssignmentGenerator;
