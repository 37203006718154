import { ContentHeader } from '@app/components';
import ExportToDocx from '@app/components/export/DocsExport';
import ExportToPDF from '@app/components/export/PdfExport';
// import { AnswerModel, QuestionModel } from '@app/components/quiz-model/quiz-model';

import { setAssignment } from '@app/store/reducers/assignment';
import { bigScreenCheck, getAssignmentSessionData } from '@app/utils/helpers';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux/';
import { useNavigate } from 'react-router-dom';
// import LessonPlanCard from './LessonPlanCard';
import ExportButton from '@app/components/export/ExportButton';
import { getAllAssignment, deleteAssignment } from '@app/services/databaseApi';
import AssignmentCard from './AssignmentCard';
import AssignmentPdfExport from '@app/components/export/AssignmentPdfExport';
import AssignmentDocxExport from '@app/components/export/AssignmentDocxExport';

export interface AssignmentPropData {
	question: string;
	instructions: string;
	hint: string[];
}

export interface AssignmentData {
	subject: string;
	assignmentType: string;
	unitChapter: string;
	gradeLevel: string;
	assignments: AssignmentPropData[];
}

const Assignment = () => {
	const dispatch = useDispatch();
	const bigScreen = bigScreenCheck();
	const navigate = useNavigate();

	const queryParameters = new URLSearchParams(location.search);
	const id = queryParameters.get('id') || '0';

	const assignmentData = useSelector((state: any) => state?.assignment?.assignmentData);

	console.log('id', id);

	useEffect(() => {
		fetchAssignmentHistory();
	}, []);

	const fetchAssignmentHistory = async () => {
		const assignmentHistory = await getAllAssignment();
		dispatch(setAssignment(assignmentHistory[id]));
		console.log('AssignementData', assignmentData);
	};

	const handleDelete = async () => {
		await deleteAssignment(assignmentData?._id);

		navigate('/assignment-history');
	};

	const handleClick = () => {
        navigate("/assignment-history");
    };

	var counter = 1;
	var docxCounter = 1;

	return (
		<div>
			<ContentHeader title={'Assignment'} />
			<div className={bigScreen ? 'mx-5' : ''}>
				<div className='d-flex justify-content-between '>
				<div className='d-flex btn-purple'>
                        <button className='btn' onClick={handleClick} style={{ color: "white", width: "66px" }}>
                            Back
                        </button>
                    </div>  
				 {/* mt-n5 */}
				 <div>
					<ExportButton
						docxChild={
							<AssignmentDocxExport
								fileName={'assignment'}
								innerClass={'d-flex justify-content-center'}
								assignmentData={assignmentData}
							/>
						}
						pdfChild={
							<AssignmentPdfExport
								fileName={'assignment'}
								innerClass={'d-flex justify-content-center'}
								assignmentData={assignmentData}
							/>
						}
					/>
					</div>
				</div>
				<div className='border rounded p-3 my-3' style={{boxShadow:"0 0 1px 0"}}>
					<AssignmentCard data={assignmentData} />
				</div>
				<div className='d-flex justify-content-end'>
					<button className='btn btn-outline-danger px-5' onClick={handleDelete} style={{ height: '50px' }}>
						Delete Assignment
					</button>
				</div>
			</div>
		</div>
	);
};

export default Assignment;
