
interface AssignmentData {
  subject: string;
  assignmentType: string;
  unitChapter: string;
  gradeLevel: string;
  assignment: {
    question: string;
    instructions: string;
    hint: string[];
  };
}

interface ExportAssignmentToDocxProps {
  fileName: string;
  innerClass: string;
  assignmentData: AssignmentData;
}

function AssignmentDocxExport({ fileName, innerClass, assignmentData }: ExportAssignmentToDocxProps) {
  const generateHintList = (hint: string[]) =>
    hint.map((hintItem, index) => `${index + 1}. ${hintItem}`).join('\n');

  const handleExportClick = () => {
    const docContent = `
      Assignment Details\n\n
      Subject:${assignmentData.subject}\n
      Assignment Type:${assignmentData.assignmentType}\n
      Unit Chapter:${assignmentData.unitChapter}\n
      Grade Level:${assignmentData.gradeLevel}\n\n
      Assignment Question:\n${assignmentData.assignment.question}\n\n
      Instructions:\n${assignmentData.assignment.instructions}\n\n
      Hints:\n${generateHintList(assignmentData.assignment.hint)}
    `;
    const blob = new Blob([docContent], { type: 'application/msword' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName + '.doc';
    link.click();
  };

  return (
    <div>
      <div className={innerClass}>
        <button
          onClick={handleExportClick}
          className='btn btn-primary btn-purple my-2'
          style={{ backgroundColor: '#a084dc', padding: '10px 22px' }}
        >
          Export as Doc
        </button>
      </div>
    </div>
  );
}

export default AssignmentDocxExport;