import React from 'react';
import { saveAs } from 'file-saver';
import ReactDOMServer from 'react-dom/server';

function ExportDocx({ child, fileName, innerClass }: any) {
	const downloadDocx = () => {
		// Create a sample content for the Word document

		const docxContent = ReactDOMServer.renderToStaticMarkup(child);

		// Create a Blob containing the HTML content
		const blob = new Blob([docxContent], {
			type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
		});

		// Save the Blob as a file using FileSaver.js
		saveAs(blob, fileName);
	};

	return (
		<div className={innerClass}>
			<button className='btn btn-primary btn-purple px-3' style={{ backgroundColor: '#a084dc' }} onClick={downloadDocx}>
				Export as docx
			</button>
		</div>
	);
}

export default ExportDocx;
