import axios, { AxiosError } from 'axios';
import { logout } from './oidc-providers';

let isRefreshing = false;
let failedQueue: ((token: string) => void)[] = [];

const onRequest = (config: any) => {
    const token = JSON.parse(localStorage.getItem('tokens') || '{"access_token":"","refresh_token":""}');
    config.headers['Authorization'] = `Bearer ${token.access_token}`;
    return config;
};

const onRequestError = (error: any) => {
    return Promise.reject(error);
};

const onResponse = (response: any) => {
    return response;
};

const onResponseError = async (error: AxiosError) => {
    if (error.response && error.response.status === 401) {
        const originalRequest = error.config;

        if (!isRefreshing) {
            isRefreshing = true;
            try {
                const storedToken = JSON.parse(localStorage.getItem('tokens') || '{"access_token":"","refresh_token":""}');
                const refreshResponse = await axios.post('/api/auth/refresh', {
                    refresh_token: storedToken.refresh_token
                });
                const { access_token } = refreshResponse.data;
                localStorage.setItem('tokens', JSON.stringify({ access_token, refresh_token: storedToken.refresh_token }));

                // Retry original request
                if (!originalRequest) {
                    throw new Error('Original request is undefined');
                }
                originalRequest.headers['Authorization'] = `Bearer ${access_token}`;
                return axios(originalRequest);
            } catch (refreshError) {
                await logout();
                return Promise.reject(refreshError);
            } finally {
                isRefreshing = false;
            }
        } else {
            // If already refreshing, queue the request
            return new Promise((resolve, reject) => {
                failedQueue.push((token: string) => {
                    if (!originalRequest) {
                        reject(new Error('Original request is undefined'));
                        return;
                    }
                    originalRequest.headers['Authorization'] = `Bearer ${token}`;
                    resolve(axios(originalRequest));
                });
            });
        }
    }
    return Promise.reject(error);
};

const processQueue = (token: string) => {
    failedQueue.forEach(callback => callback(token));
    failedQueue = [];
};

export const setupInterceptors = (axiosInstance: any) => {
    axiosInstance.interceptors.request.use(onRequest, onRequestError);
    axiosInstance.interceptors.response.use(onResponse, onResponseError);
    return axiosInstance;
};

// Call this function when a token is refreshed successfully
export const tokenRefreshed = (newToken: string) => {
    processQueue(newToken);
};
