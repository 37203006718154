import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Form, InputGroup, Spinner, Button } from 'react-bootstrap';
import ContentHeader from '../content-header/ContentHeader';
import AiForm from './AiForm';
import { bigScreenCheck } from '@app/utils/helpers';

const Generator = ({ header }: { header: string }) => {
	const bigScreen = bigScreenCheck();

	const { handleChange, values, handleSubmit, touched, errors } = useFormik({
		initialValues: {
			header: ''
		},
		validationSchema: Yup.object({
			header: Yup.string().required()
		}),
		onSubmit: (values) => {
			console.log(values.header);
		}
	});

	return (
		<div>
			<ContentHeader title={header} />
			<form onSubmit={handleSubmit}>
				<div className={bigScreen ? 'mb-3 mx-5' : ''}>
					<div className='fs-5 py-3'>Title</div>
					<div className='d-flex flex-row'>
						<div className='w-100'>
							<Form.Control
								className='p-4'
								id='header'
								name='header'
								type='text'
								onChange={handleChange}
								value={values.header}
								isValid={touched.header && !errors.header}
								isInvalid={touched.header && !!errors.header}
							/>
							{touched.header && errors.header ? (
								<Form.Control.Feedback type='invalid'>{errors.header}</Form.Control.Feedback>
							) : null}
						</div>
						<button className='btn-purple ms-3 w-25' type='submit' style={{ height: '50px' }}>
							Generate
						</button>
					</div>
				</div>
			</form>
			<div className={bigScreen ? 'mx-4 px-3' : 'mx-n1'}>
				<AiForm />
			</div>
		</div>
	);
};

export default Generator;
