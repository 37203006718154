import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Form, InputGroup, Spinner, Button } from 'react-bootstrap';
import { ContentHeader } from '@components';
import { notesGenerator } from '@app/services/generator';
import { useDispatch, useSelector } from 'react-redux';
import { setNotes } from '@app/store/reducers/notes';
import TimeProgressBar from '@app/components/progressBar/TimeProgressBar';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { bigScreenCheck, getNotesFormData, getNotesSessionData } from '@app/utils/helpers';
import ExportButton from '@app/components/export/ExportButton';
import ExportToDocx from '@app/components/export/DocsExport';
import ExportToPDF from '@app/components/export/PdfExport';
import { deleteNotes, getAllNotes } from '@app/services/databaseApi';
import ClassNotesPdfExport from '@app/components/export/ClassNotesPdfExport';
import ClassNotesDocxExport from '@app/components/export/ClassNotesDocxExport';

const ClassNotes = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const bigScreen = bigScreenCheck();

	const queryParameters = new URLSearchParams(location.search);
	const id = queryParameters.get('id') || '0';
	const notesData = useSelector((state: any) => state?.notes?.notesData);

	useEffect(() => {
		fetchNotes();
	}, []);

	const fetchNotes = async () => {
		const lessonHistoryList = await getAllNotes();
		dispatch(setNotes(lessonHistoryList[id]));
		console.log('notesData', notesData);
	};
	const renderNotesAsList = (notes: string) => {
		if (!notes) return null;

		const notesLines = notes.split('\n\n');
		return (
			<ul>
				{notesLines.map((line, index) => (
					<li key={index}>{line}</li>
				))}
			</ul>
		);
	};

	const convertMarkdownToHTML = (markdownText: any) => {
		if (!markdownText) return null;

		// Convert **text** to <strong>text</strong>
		let htmlText = markdownText.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

		// Convert ### Heading to <h3>Heading</h3>
		htmlText = htmlText.replace(/^### (.+)$/gm, '<p>$1</p>');

		htmlText = htmlText.replace(/^## (.+)$/gm, '<p>$1</p>');
		htmlText = htmlText.replace(/^####\s+(.+)$/gm, '<p>$1</p>');
		htmlText = htmlText.replace(/^# (.+)$/gm, '<p>$1</p>');
		htmlText = htmlText.replace(/^#\s+(.+)$/gm, '<p>$1</p>');

		// Convert --- to <hr>
		htmlText = htmlText.replace(/^---$/gm, '<hr>');

		// Convert ```cpp code block to <pre><code class="language-cpp">code block</code></pre>
		htmlText = htmlText.replace(/```cpp\n(.*?)\n```/gs, '<pre><code class="language-cpp">$1</code></pre>');

		// Remove * characters that don't match bullet point syntax
		htmlText = htmlText.replace(/([^*\n])\*([^*\n])/g, '$1$2');

		// Remove # characters that don't match heading syntax
		htmlText = htmlText.replace(/([^#\n])#/g, '$1');

		// Convert * item to <li>item</li> for bullet points
		htmlText = htmlText.replace(/(?:^|\n)\* (.*)/gm, '<li>$1</li>');

		// Wrap the bullet points with <ul> tags
		htmlText = htmlText.replace(/^(?!\s*$)(.+)$/gm, '<ul>$1</ul>');

		// Convert numbered item to <li>item</li> for numbered lists
		htmlText = htmlText.replace(/(?:^|\n)\d+\. (.*)/gm, '<li>$1</li>');

		// Wrap the numbered list with <ol> tags
		htmlText = htmlText.replace(/^(?!\s*$)(.+)$/gm, '<ol>$1</ol>');

		return htmlText;
	};

	const renderNotesAsHTML = (notes: any) => {
		if (!notes) return null;

		const htmlNotes = convertMarkdownToHTML(notes);

		return <div className='mt-2' dangerouslySetInnerHTML={{ __html: htmlNotes }} />;
	};

	const handleDelete = async () => {
		await deleteNotes(notesData?._id);

		navigate('/notes-history');

	};
	const handleClick = () => {
		navigate("/notes-history");
	};

	return (
		<div>
			<ContentHeader title={'Class Notes'} />
			<div className={`${bigScreen && 'mx-5'} `}>
				<div className='d-flex justify-content-between'>
					<div className='d-flex btn-purple'>
                        <button className='btn' onClick={handleClick} style={{ color: "white", width:"66px"}}>
                            Back
                        </button>
                    </div>
					<div> 
					<ExportButton
						docxChild={
							<ClassNotesDocxExport
								fileName={'notes'}
								innerClass={'d-flex justify-content-center'}
								jsonData={notesData}
							/>
						}
						pdfChild={
							<ClassNotesPdfExport
								fileName={'notes'}
								innerClass={'d-flex justify-content-center'}
								jsonData={notesData}
							/>
						}
					/>
				</div>
				</div>
				<div style={{
			fontFamily: 'Poppins, sans-serif',
            
		}}>	
				<div className='border rounded my-4 p-3'>
					<div className='mt-2'>
						<span>Grade: </span>
						<span className='text-muted'>{notesData?.gradeLevel}</span>
					</div>
					<div className='mt-2'>
						<span>Subject: </span>
						<span className='text-muted'>{notesData?.subject}</span>
					</div>
					<div className='mt-2'>
						<span>Topic: </span>
						<span className='text-muted'>{notesData?.unitChapter}</span>
					</div>
					<div className='mt-2'>
						<span>Summary: </span>
						<span className='text-muted'>{notesData?.summary}</span>
					</div>	
					<div className='mt-2'>
						<span>Notes: </span>
						<span className='text-muted'> {renderNotesAsHTML(notesData?.notes)}</span>
					</div>
					<div className='d-flex justify-content-end'>
						<button className='btn btn-outline-danger px-5' onClick={handleDelete} style={{ height: '50px' }}>
							Delete Note
						</button>
					</div>
				</div>
			</div>
		</div>
		</div>
	);
};

export default ClassNotes;
