import { JSXElementConstructor, Key, ReactElement, ReactNode, ReactPortal, useEffect, useState } from 'react';
import { LessonData, LecturePlan } from './LessonPlan';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
/*Step 1: storing only some basic data*/
/**/


const LessonPlanCard = ({ data }: { data: LessonData }) => {

	const [holdData, setHoldData] = useState<LecturePlan[]>([]);
	const [datatoggle, setToggleStates] = useState<boolean[]>([]);

	useEffect(() => {
		if (data?.lecturePlan) {
			setHoldData(data.lecturePlan);
			setToggleStates(new Array(data.lecturePlan.length).fill(false)); // Initialize toggle states
		}
	}, [data]);
	
	var questionCounter = 1;
	const resetCount = (): string => {
		questionCounter = 1;
		return '';
	};

	const changeToggle = (index: number) => {
		setToggleStates(prevStates =>
		  prevStates.map((state, i) => (i === index ? !state : state))
		);
	  };
	return (
		<div style={{
			fontFamily: 'Poppins, sans-serif',
		}}>
			<div className='border rounded my-3'>
			<div className='ml-3'>
				<div className='mt-2'>Learning Objectives:</div>
				{data?.learningObjectives?.map((objective, index) => {
					return (
						<div className='text-muted' key={index}>
							- {objective}
						</div>
					);
				})}
			</div>
			<div className='ml-3 mb-2'>
				<div className='mt-2'>Teaching Methodologies:</div>
				{data?.learningObjectives?.map((objective, index) => {
					return (
						<div className='text-muted ' key={index}>
							- {objective}
						</div>
					);
				})}
			</div>
			</div>
			<div className='' key={'abcd'}>
				<div className='mb-2'>Lecture Plan:</div>
				{holdData?.map((plan, index) => {
					return (
						<div className='border rounded' key={index} style={{marginBottom: "30px", position: "relative"}}>
							<div className='mt-2 ml-3'>
								Lecture Number:.
								<span className='text-muted' key={index+'1'}>
									{plan?.lectureNumber}
								</span>
							</div>
							<div className='mt-2 ml-3'>
								Duration:
								<span className='ps-1 text-muted' key={index+'2'}>
									{plan?.duration}
								</span>
							</div>
							<div className='mt-2 ml-3'>
								Grade:
								<span className='ps-1 text-muted ' key={index+'2'}>
									{data?.grade}
								</span>
							</div>
							<div className='mt-2 ml-3 mb-2'>
								Topics Covered:
								{plan?.topicsCovered?.map((topics, index2) => {
									return (
										<div className='ps-3 text-muted ' key={index2}>
											- {topics}
										</div>
									);
								})}
							</div>
							{ datatoggle[index]? <> 
								<div className='toggle'> 
							<div className='mt-2 ml-3'>
								Key Concepts:
								{plan?.keyConcepts?.map((concepts, index2) => {
									return (
										<div className='ps-3 text-muted ' key={index2}>
											- {concepts}
										</div>
									);
								})}
							</div>
							<div className='mt-2 ml-3'>
								Activities:
								{plan?.activities?.map((activity, index2) => {
									return (
										<div className='ps-3 text-muted ' key={index2}>
											- {activity}
										</div>
									);
								})}
							</div>
							<div className='mb-2'>
								<div className='mt-2 ml-3'>Assessment Questions</div>
								{plan?.assessment?.questions?.map((question, index2) => {
									return (
										<div className='ps-3 text-muted ' key={index2}>
											{questionCounter++}. {question}
										</div>
									);
								})}
								{resetCount()}
							</div>
							</div>
							</>: <>
							</>}
							<button style={{
								position:"absolute", height:"34px", color:"#ffffff", backgroundColor:"#a084dc", borderRadius:"50%", boxShadow:'none', border: "none", padding:"0 9px 0 9px",
								fontSize:"20px",
								fontWeight: "thin",
								bottom:"10px",
								right:"30px",
								marginLeft:"10px"
								}}
								onClick={() => changeToggle(index)}
								>
								{
									datatoggle[index]? <FontAwesomeIcon icon={faAngleUp}/> : <FontAwesomeIcon icon={faAngleDown} style={{marginTop: "8px"}}/>
								}
							</button>
						</div>
					)
				})}
			</div>
		</div>
	);
};

export default LessonPlanCard;
