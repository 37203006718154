import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Main from '@modules/main/Main';
import Login from '@modules/login/Login';
import Register from '@app/modules/register/RegisterationForm';
import ForgetPassword from '@app/modules/forgot-password/resetPassword.tsx';
import RecoverPassword from '@modules/recover-password/RecoverPassword';
import LessonGenerator from '@app/pages/lesson-plan/LessonGenerator';
import LessonPlan from '@app/pages/lesson-plan/LessonPlan';
import PreviousLessonPlan from './pages/lesson-plan/PreviousLessonPlan';
// import Assistant from '@app/pages/assistant.tsx'
import Quiz from '@app/pages/quiz/Quiz';
import Academic from '@pages/Academic';
import ClassNotes from '@app/pages/class-notes/ClassNotes';
import NotesGenerator from './pages/class-notes/NotesGenerator';
import QuizQuestions from '@app/pages/quiz/QuizQuestions';
import QuizMatching from '@app/pages/quiz/QuizMatching';
import QuizSolution from '@app/pages/quiz/QuizSolution';
import PreviousQuiz from './pages/quiz/PreviousQuiz';
import AssignmentGenerator from './pages/assignment/AssignmentGenerator';
import PreviousAssignment from './pages/assignment/PreviousAssignment';
import Assignment from './pages/assignment/Assignment.tsx';

import TestPaperGenerator from './pages/testPaper/TestPaperGenerator';
import PreviousTestPaper from './pages/testPaper/PreviousTestPaper';
import TestPaper from './pages/testPaper/TestPaper.tsx';


import { useWindowSize } from '@app/hooks/useWindowSize';
import { calculateWindowSize } from '@app/utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { setWindowSize } from '@app/store/reducers/ui';

import Dashboard from '@app/pages/Dashboard';
import Blank from '@pages/Blank';
import SubMenu from '@pages/SubMenu';
import Profile from '@pages/profile/Profile';

import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/PrivateRoute';
import { setAuthentication } from './store/reducers/auth';
import { setUserInfo } from './store/reducers/userInfo';
import { getAuthStatus } from './utils/oidc-providers';

import { clarity } from 'clarity-js';
import ReactGA from 'react-ga4';
import { gaPage } from './utils/helpers';
import * as Sentry from '@sentry/react';
import PreviousNotes from './pages/class-notes/PreviousNotes';


if (import.meta.env.VITE_NODE_ENV === 'production') {
	const GA_TRACKING_ID = import.meta.env.VITE_GA_TRACKING_ID;
	const SENTRY_ID = import.meta.env.VITE_SENTRY_ID;
	const MS_CLARITY = import.meta.env.VITE_MS_CLARITY;

	ReactGA.initialize(GA_TRACKING_ID);

	clarity.start({
		projectId: MS_CLARITY,
		upload: 'https://t.clarity.ms/collect',
		track: true,
		content: true
	});

	Sentry.init({
		dsn: SENTRY_ID,
		integrations: [
			new Sentry.BrowserTracing({
				// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
				tracePropagationTargets: [`${import.meta.env.VITE_API_URL}/api/`, 'production.website']
			}),
			new Sentry.Replay()
		],
		// Performance Monitoring
		tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
}

const App = () => {
	const windowSize = useWindowSize();
	const screenSize = useSelector((state: any) => state.ui.screenSize);
	const dispatch = useDispatch();
	const [isAppLoading, setIsAppLoading] = useState(true);

	const checkSession = async () => {
		try {
			let responses: any = await Promise.all([getAuthStatus()]);
			responses = responses.filter((r: any) => Boolean(r));

			if (responses && responses.length > 0) {
				dispatch(setAuthentication(responses[0]));
				dispatch(setUserInfo(responses[0]));
			}
		} catch (error: any) {
			console.log('error', error);
		}
		setIsAppLoading(false);
	};

	useEffect(() => {
		checkSession();
	}, []);

	useEffect(() => {
		const size = calculateWindowSize(windowSize.width);
		if (screenSize !== size) {
			dispatch(setWindowSize(size));
		}
	}, [windowSize]);

	if (isAppLoading) {
		// return (
		// 	<div className='d-flex flex-column justify-content-center align-items-center'>
		// 		<h2 className='m-3 border-bottomd border-5 border-secondary'>Edu Assist</h2>
		// 		<img
		// 			src='https://cdn.dribbble.com/users/1523313/screenshots/16134521/media/3975730626bdae63cf9b25d3b634bac3.gif'
		// 			alt='loading-page'
		// 			className='row-10'
		// 			style={{ marginTop: '20px', boxShadow: '0px 10px 25px 35px #272c35' }}
		// 		/>
		// 	</div>
		// );
		return <p>Loading</p>;
	}

	return (
		<BrowserRouter>
			<Routes>
				<Route path='/login' element={<PublicRoute />}>
					<Route path='/login' element={<Login />} />
				</Route>
				<Route path='/register' element={<PublicRoute />}>
					<Route path='/register' element={<Register />} />
				</Route>
				<Route path='/reset-password' element={<PublicRoute />}>
					<Route path='/reset-password' element={<ForgetPassword />} />
				</Route>
				<Route path='/recover-password' element={<PublicRoute />}>
					<Route path='/recover-password' element={<RecoverPassword />} />
				</Route>
				<Route path='/' element={<PrivateRoute />}>
					<Route path='/' element={<Main />}>
						<Route path='/sub-menu-2' element={<Blank />} />
						<Route path='/lesson' element={<LessonGenerator />} />
						<Route path='/lesson-plan' element={<LessonPlan />} />
						<Route path='/lesson-history' element={<PreviousLessonPlan />} />
						<Route path='/quiz' element={<Quiz />} />
						<Route path='/quiz-questions' element={<QuizQuestions />} />
						{/* <Route path='/quiz-fill' element={<FillTypeQuiz />} /> */}
						<Route path='/quiz-matching' element={<QuizMatching />} />
						<Route path='/assignment-history' element={<PreviousAssignment />} />
						<Route path='/assignment' element={<AssignmentGenerator />} />
						<Route path='/assignment-plan' element={<Assignment />} />
						<Route path='/test-paper-history' element={<PreviousTestPaper />} />
						<Route path='/testPaper' element={<TestPaperGenerator />} />
						<Route path='/test-paper-plan' element={<TestPaper />} />
						<Route path='/quiz-solution' element={<QuizSolution />} />
						<Route path='/quiz-history' element={<PreviousQuiz />} />
						<Route path='/academic' element={<Academic />} />
						<Route path='/notes-history' element={<PreviousNotes />} />
						<Route path='/notes' element={<NotesGenerator />} />
						<Route path='/class-notes' element={<ClassNotes />} />
						<Route path='/test-paper' element={<TestPaper />} />
						<Route path='/sub-menu-1' element={<SubMenu />} />
						<Route path='/blank' element={<Blank />} />
						<Route path='/profile' element={<Profile />} />
						<Route path='/' element={<Dashboard />} />
					</Route>
				</Route>
			</Routes>
			<ToastContainer
				autoClose={3000}
				draggable={false}
				position='top-right'
				hideProgressBar={false}
				newestOnTop
				closeOnClick
				rtl={false}
				pauseOnHover
			/>
		</BrowserRouter>
	);
};

export default App;
