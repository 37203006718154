import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { setAuthentication } from '@store/reducers/auth';
import { setWindowClass } from '@app/utils/helpers';
// import { PfButton } from '@profabric/react-components';
import Hero from '@app/components/hero/Hero';

import * as Yup from 'yup';
import { GoogleLogin } from '@react-oauth/google';
import { authLogin, fetchGoogleUser } from '@app/utils/oidc-providers';
import { Form, InputGroup, Spinner, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import logo from '@image/logo.png';

const Register = () => {
	const [isAuthLoading, setAuthLoading] = useState(false);
	const [isGoogleAuthLoading, setGoogleAuthLoading] = useState(false);
	const dispatch = useDispatch();

	const navigate = useNavigate();

	const { handleChange, values, handleSubmit, touched, errors } = useFormik({
		initialValues: {
			name: '',
			email: '',
			phone: '',
			password: '',
			retypePassword: ''
		},
		validationSchema: Yup.object({
			email: Yup.string().email('Invalid email address').required('Required'),
			password: Yup.string()
				.min(5, 'Must be 5 characters or more')
				.max(30, 'Must be 30 characters or less')
				.required('Required')
		}),
		onSubmit: (values) => {
			// login(values.email, values.password);
		}
	});

	useEffect(() => {
		setWindowClass('hold-transition login-page');
	}, []);

	return (
		<div className='container'>
			<img
				src={logo}
				alt='logo'
				height='80vh'
				style={{ position: 'absolute', top: 10, left: 10 }}
				className='d-none d-lg-block'
			/>
			<div className='row d-flex justify-content-center'>
				<div className='col-lg-4 mr-n3'>
					<div className='login-box w-100'>
						<div
							className={`border-0 ${
								window.innerWidth < 576
									? 'p-0 overflow-hidden'
									: 'rounded rounded-end-0 shadow card card-primary card-outline'
							}`}
						>
							<div className='card-header text-center d-lg-none d-md-block justify-content-center'>
								<img src={logo} alt='logo' height='80vh' />
							</div>
							<div className='card-body'>
								<h5 className='login-box-msg text-main px-0'>Empowering Teachers, Inspiring Students.</h5>

								<div className='social-auth-links p-0 mt-4 mb-3 col-12 d-flex justify-content-center'>
									{/* <GoogleLogin
										onSuccess={async (credentialResponse) => {
											loginByGoogle(credentialResponse);
										}}
										onError={() => {
											console.log('Login Failed');
											toast.error('Login Failed');
										}}
									/> */}
									<button>Google</button>
								</div>
								<div className='col-12'>
									<div className='my-2 d-flex justify-content-center'>Or</div>
								</div>
								<form onSubmit={handleSubmit}>
									<div className='mb-3'>
										<InputGroup className='mb-3'>
											<Form.Control
												className='p-4'
												id='name'
												name='name'
												type='name'
												placeholder='Name'
												onChange={handleChange}
												value={values.name}
												isValid={touched.name && !errors.name}
												isInvalid={touched.name && !!errors.name}
											/>
											{touched.name && errors.name ? (
												<Form.Control.Feedback type='invalid'>{errors.name}</Form.Control.Feedback>
											) : (
												<InputGroup.Text>
													<FontAwesomeIcon icon={faEnvelope} />
												</InputGroup.Text>
											)}
										</InputGroup>
									</div>
									<div className='mb-3'>
										<InputGroup className='mb-3'>
											<Form.Control
												className='p-4'
												id='email'
												name='email'
												type='email'
												placeholder='Email'
												onChange={handleChange}
												value={values.email}
												isValid={touched.email && !errors.email}
												isInvalid={touched.email && !!errors.email}
											/>
											{touched.email && errors.email ? (
												<Form.Control.Feedback type='invalid'>{errors.email}</Form.Control.Feedback>
											) : (
												<InputGroup.Text>
													<FontAwesomeIcon icon={faEnvelope} />
												</InputGroup.Text>
											)}
										</InputGroup>
									</div>
									<div className='mb-3'>
										<InputGroup className='mb-3'>
											<Form.Control
												className='p-4'
												id='phone'
												name='phone'
												type='phone'
												placeholder='Phone Number'
												onChange={handleChange}
												value={values.phone}
												isValid={touched.phone && !errors.phone}
												isInvalid={touched.phone && !!errors.phone}
											/>
											{touched.phone && errors.phone ? (
												<Form.Control.Feedback type='invalid'>{errors.phone}</Form.Control.Feedback>
											) : (
												<InputGroup.Text>
													<FontAwesomeIcon icon={faLock} />
												</InputGroup.Text>
											)}
										</InputGroup>
									</div>
									<div className='mb-3'>
										<InputGroup className='mb-3'>
											<Form.Control
												className='p-4'
												id='password'
												name='password'
												type='password'
												placeholder='Password'
												onChange={handleChange}
												value={values.password}
												isValid={touched.password && !errors.password}
												isInvalid={touched.password && !!errors.password}
											/>
											{touched.password && errors.password ? (
												<Form.Control.Feedback type='invalid'>{errors.password}</Form.Control.Feedback>
											) : (
												<InputGroup.Text>
													<FontAwesomeIcon icon={faLock} />
												</InputGroup.Text>
											)}
										</InputGroup>
									</div>
									<div className='mb-3'>
										<InputGroup className='mb-3'>
											<Form.Control
												className='p-4'
												id='retypePassword'
												name='retypePassword'
												type='retypePassword'
												placeholder='Retype Password'
												onChange={handleChange}
												value={values.retypePassword}
												isValid={touched.retypePassword && !errors.retypePassword}
												isInvalid={touched.retypePassword && !!errors.retypePassword}
											/>
											{touched.retypePassword && errors.retypePassword ? (
												<Form.Control.Feedback type='invalid'>{errors.retypePassword}</Form.Control.Feedback>
											) : (
												<InputGroup.Text>
													<FontAwesomeIcon icon={faLock} />
												</InputGroup.Text>
											)}
										</InputGroup>
									</div>

									<div className='row'>
										<div className='col-12'>
											<Button disabled={isGoogleAuthLoading} className='w-100 btn-purple' type='submit'>
												{isAuthLoading ? (
													<>
														<Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
														<span className='visually-hidden'> Loading...</span>
													</>
												) : (
													<span style={{ height: '100px' }}>Sign In</span>
												)}
											</Button>
										</div>
									</div>
								</form>
								<div className='col mt-4 text-center justify-content-center'>
									<Link to='/login' className='text-center'>
										I already have an account
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='col-lg-4 d-none d-lg-block ml-n2'>{<Hero height={616} />}</div>
			</div>
		</div>
	);
};

export default Register;