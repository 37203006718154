import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { setAuthentication } from '@store/reducers/auth';
import { setWindowClass } from '@app/utils/helpers';
// import { PfButton } from '@profabric/react-components';
import Hero from '@app/components/hero/Hero';

import * as Yup from 'yup';
import { GoogleLogin } from '@react-oauth/google';
import { authLogin, fetchGoogleUser } from '@app/utils/oidc-providers';
import { Form, InputGroup, Spinner, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import logo from '@image/logo.png';

const resetPassword = () => {
	const [isAuthLoading, setAuthLoading] = useState(false);
	const [isGoogleAuthLoading, setGoogleAuthLoading] = useState(false);
	const dispatch = useDispatch();

	const navigate = useNavigate();

	const { handleChange, values, handleSubmit, touched, errors } = useFormik({
		initialValues: {
			currentPassword: '',
			newPassword: '',
			retypeNewPassword: '',
		},
		validationSchema: Yup.object({
        newPassword: Yup.string()
				.min(5, 'Must be 5 characters or more')
				.max(30, 'Must be 30 characters or less')
				.required('Required'),
        retypeNewPassword: Yup.string()
				.min(5, 'Must be 5 characters or more')
				.max(30, 'Must be 30 characters or less')
				.required('Required')
		}),
		onSubmit: (values) => {
			// login(values.email, values.password);
		}
	});

	useEffect(() => {
		setWindowClass('hold-transition login-page');
	}, []);

	return (
		<div className='container'>
			<img
				src={logo}
				alt='logo'
				height='80vh'
				style={{ position: 'absolute', top: 10, left: 10 }}
				className='d-none d-lg-block'
			/>
			<div className='row d-flex justify-content-center'>
				<div className='col-lg-4 mr-n3'>
					<div className='login-box w-100'>
						<div
							className={`border-0 ${
								window.innerWidth < 576
									? 'p-0 overflow-hidden'
									: 'rounded rounded-end-0 shadow card card-primary card-outline'
							}`}
						>
							<div className='card-header text-center d-lg-none d-md-block justify-content-center'>
								<img src={logo} alt='logo' height='80vh' />
							</div>
							<div className='card-body'>
								<h5 className='login-box-msg text-main px-0'>Reset Your password</h5>

								<form onSubmit={handleSubmit}>
									<div className='mb-3'>
										<InputGroup className='mb-3'>
											<Form.Control
												className='p-4'
												id='newPassword'
												name='newPassword'
												type='password'
												placeholder='New Password'
												onChange={handleChange}
												value={values.newPassword}
												isValid={touched.newPassword && !errors.newPassword}
												isInvalid={touched.newPassword && !!errors.newPassword}
											/>
											{touched.newPassword && errors.newPassword ? (
												<Form.Control.Feedback type='invalid'>{errors.newPassword}</Form.Control.Feedback>
											) : (
												<InputGroup.Text>
													<FontAwesomeIcon icon={faLock} />
												</InputGroup.Text>
											)}
										</InputGroup>
									</div>
									<div className='mb-3'>
										<InputGroup className='mb-3'>
											<Form.Control
												className='p-4'
												id='retypeNewPassword'
												name='retypeNewPassword'
												type='password'
												placeholder='Confirm Password'
												onChange={handleChange}
												value={values.retypeNewPassword}
												isValid={touched.retypeNewPassword && !errors.retypeNewPassword}
												isInvalid={touched.retypeNewPassword && !!errors.retypeNewPassword}
											/>
											{touched.retypeNewPassword && errors.retypeNewPassword ? (
												<Form.Control.Feedback type='invalid'>{errors.retypeNewPassword}</Form.Control.Feedback>
											) : (
												<InputGroup.Text>
													<FontAwesomeIcon icon={faLock} />
												</InputGroup.Text>
											)}
										</InputGroup>
									</div>
								</form>
								<div className='col mt-4 text-center justify-content-center'>
									<Link to='/login' className='text-center'>
										Login with current password
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='col-lg-4 d-none d-lg-block ml-n2'>{<Hero height={240} />}</div>
			</div>
		</div>
	);
};

export default resetPassword;