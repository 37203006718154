import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { gaClick } from '../../utils/helpers';

export interface SmallBoxProps {
	image: string;
	title: string;
	route: string;
}

const SmallBox = ({ title, route, image }: SmallBoxProps) => {
	const navigate = useNavigate();
	const handleClick = () => {
		navigate(route);
		gaClick('dashboard-card', `hit ${title}`);
	};

	return (
		<div className='col-lg-4 col-md-6 col-sm-12' onClick={handleClick} >
			<div className='card'>
				<div className='text-center'>
					<img src={image} alt={image} height={200} />
					<p  style={{ cursor: 'pointer' }}>{title}</p>
				</div>
			</div>
		</div>
	);
};

export default SmallBox;