import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import { setQuiz } from '@app/store/reducers/quiz';
import { setLesson } from '@app/store/reducers/lesson';
import { setNotes } from '@app/store/reducers/notes';
import { useDispatch, useSelector } from 'react-redux/';
import { setAssignment } from '@app/store/reducers/assignment';
import { setTestPaper } from '@app/store/reducers/testPaper';


export const sleep = (time: number) => {
	new Promise((res) => setTimeout(res, time));
};

export const calculateWindowSize = (windowWidth: number) => {
	if (windowWidth >= 1200) {
		return 'lg';
	}
	if (windowWidth >= 992) {
		return 'md';
	}
	// if (windowWidth >= 768) {
	//   return 'sm';
	// }
	return 'xs';
};

export const setWindowClass = (classList: string) => {
	const window: HTMLElement | null = document && document.getElementById('root');
	if (window) {
		// @ts-ignore
		window.classList = classList;
	}
};
export const addWindowClass = (classList: string) => {
	const window: HTMLElement | null = document && document.getElementById('root');
	if (window) {
		// @ts-ignore
		window.classList.add(classList);
	}
};

export const removeWindowClass = (classList: string) => {
	const window: HTMLElement | null = document && document.getElementById('root');
	if (window) {
		// @ts-ignore
		window.classList.remove(classList);
	}
};

export const bigScreenCheck = () => {
	const screenSize = useSelector((state: any) => state.ui.screenSize);
	if(screenSize === 'xs') {
		return false;
	} return true;
}

export const gaClick = (category: string, action: string) => {
	ReactGA.event({
		category: category,
		action: action
	});
};

export const gaPage = (location: string, title: string) => {
	ReactGA.send({ hitType: 'pageview', page: location, title: title });
};

export const getQuizSessionData = () => {
	const quizData = useSelector((state: any) => state?.quiz?.quizData);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	if (quizData === undefined) {
		const quizSessionData = sessionStorage.getItem('quizData');
		if (quizSessionData === null || quizSessionData === 'undefined') {
			useEffect(() => {
				navigate('/quiz');
			}, []);
		} else {
			dispatch(setQuiz(JSON.parse(quizSessionData || "{'quizData':''}")));
		}
	}
	return quizData;
};
export const getQuizFormData = () => {
	const quizLocalData = localStorage.getItem('quiz-form');
	if (quizLocalData === null || quizLocalData === 'undefined') {
		return {
			grade: '',
			subject: '',
			topic: '',
			quizType: '',
			questionsCount: '',
			difficultyLevel: ''
		};
	}
	return JSON.parse(quizLocalData);
};
export const getLessonSessionData = () => {
	const lessonData = useSelector((state: any) => state?.lesson?.lessonData);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	if (lessonData === undefined) {
		const lessonSessionData = sessionStorage.getItem('lessonData');
		if (lessonSessionData === null || lessonSessionData === 'undefined') {
			useEffect(() => {
				navigate('/lesson');
			}, []);
		} else {
			dispatch(setLesson(JSON.parse(lessonSessionData || "{'lessonData':''}")));
		}
	}
	return lessonData;
};
export const getLessonFormData = () => {
	const lessonLocalData = localStorage.getItem('lesson-form');
	if (lessonLocalData === null || lessonLocalData === 'undefined') {
		return {
			grade: '',
			subject: '',
			chapter: '',
			topic: '',
			lecture: '',
			duration: ''
		};
	}
	return JSON.parse(lessonLocalData);
};
export const getNotesSessionData = () => {
	const notesData = useSelector((state: any) => state?.notes?.notesData);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	if (notesData === undefined) {
		const notesSessionData = sessionStorage.getItem('notesData');
		if (notesSessionData === null || notesSessionData === 'undefined') {
			useEffect(() => {
				navigate('/class-notes');
			}, []);
		} else {
			dispatch(setNotes(JSON.parse(notesSessionData || "{'notesData':''}")));
		}
	}
	return notesData;
};
export const getNotesFormData = () => {
	const notesLocalData = localStorage.getItem('notes-form');
	if (notesLocalData === null || notesLocalData === 'undefined') {
		return {
			grade: '',
			subject: '',
			topic: '',
		};
	}
	return JSON.parse(notesLocalData);
};


export const getAssignmentSessionData = () => {
	const assignmentData = useSelector((state: any) => state?.assignment?.assignmentData);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	if (assignmentData === undefined) {
		const assignmentSessionData = sessionStorage.getItem('assignmentData');
		if (assignmentSessionData === null || assignmentSessionData === 'undefined') {
			useEffect(() => {
				navigate('/assignment');
			}, []);
		} else {
			dispatch(setAssignment(JSON.parse(assignmentSessionData || "{'assignmentData':''}")));
		}
	}
	return assignmentData;
};
export const getAssignmenFormtData = () => {
	const assignmentData = localStorage.getItem('assignment-form');
	if (assignmentData === null || assignmentData === 'undefined') {
		return {
			subject: '',
			assignmentType: '',
			topic: '',
			grade: '',
		};
	}
	return JSON.parse(assignmentData);
};

export const getTestPaperSessionData = () => {
	const testPaperData = useSelector((state: any) => state?.testPaper?.testPaperData);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	if (testPaperData === undefined) {
		const testPaperSessionData = sessionStorage.getItem('testPaperData');
		if (testPaperSessionData === null || testPaperSessionData === 'undefined') {
			useEffect(() => {
				navigate('/testPaper');
			}, []);
		} else {
			dispatch(setTestPaper(JSON.parse(testPaperSessionData || "{'testPaperData':''}")));
		}
	}
	return testPaperData;
};

export const getTestPaperFormtData = () => {
	const testPaperData = localStorage.getItem('test-paper-form');
	if (testPaperData === null || testPaperData === 'undefined') {
		return {
			title: '',
			totalMarks: '',
			unitChapter: '',
			gradeLevel: '',
			timeLimit: '',
			numberOfQuestions: '',
			board: '',
			subject: '',
		};
	}
	return JSON.parse(testPaperData);
};

