import * as React from 'react';
import { useState } from 'react';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { deleteAssignment, deleteLessonPlan, deleteNotes, deleteQuiz, deleteTestPaper } from '@app/services/databaseApi';
import { constants } from 'buffer';
import { LensTwoTone } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import styled from 'styled-components';


const StyledIcon = styled(FontAwesomeIcon)`
  color: #a084dc;
  transition: color 0.3s ease;
  margin-right: 16px;
  font-size: 16px;
  height: 14px;

  &:hover {
    color: red;
  }
`;

const StyledIconEye = styled(FontAwesomeIcon)`
  color: #a084dc;
  transition: color 0.3s ease, transform 0.3s ease;
  margin-right: 8px;
  height: 14px;
  &:hover {
    transform: scale(1.1);
  }
`;


const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 440,
	border: 'none',
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 4,
  };

export default function CardView(props: any) {
    const navigate = useNavigate();
    const { columns, rows, route } = props;

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
	const [rowToDelete, setRowToDelete] = useState<any | null>(null);
	const [open, setOpen] = React.useState(false);
	const handleClose = () => setOpen(false);

	const handleOpen = (row: any) => {
		setRowToDelete(row);
		setOpen(true);
	};


    const handleDelete = async (row: any) => {
        let response;
        switch (route) {
            case 'lesson-plan':
                response = await deleteLessonPlan(row.id);
                break;
            case 'quiz-questions':
                response = await deleteQuiz(row.id);
                break;
            case 'class-notes':
                response = await deleteNotes(row.id);
                break;
            case 'assignment-plan':
                response = await deleteAssignment(row.id);
                break;
            case 'test-paper-plan':
                response = await deleteTestPaper(row.id);
                break;
            default:
                console.log('route not defined');
        }
        window.location.reload();
    };



    const confirmDelete = () => {
        if (rowToDelete) {
            handleDelete(rowToDelete);
        }
		handleClose();
    };

	
    return (
        <div style={{ backgroundColor: '#ffffff' }}>
  <div>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
				<Box sx={style}>
					<Typography id="keep-mounted-modal-title" variant="h6" component="h2">
						Confirm Deletion
					</Typography>
					<Typography id="keep-mounted-modal-description" sx={{ mt: 2, mb: 2}}>
						Are you sure you want to delete this item?
					</Typography>
					<Button
                    onClick={handleClose} 
                    style={{color: '#a084dc', border:'solid #a084dc 1px'}}
                    >
						Cancel
					</Button>
					<Button onClick={confirmDelete} 
                    style={{color: 'red', marginLeft:"10px"}} 
                    >
						Confirm
					</Button>
				</Box>
      </Modal>
    </div>
            {rows.length === 0 ? (
                <Typography align="center" sx={{ padding: '20px', fontSize: '18px', color: '#999' }}>
                    No data available
                </Typography>
            ) : (
                rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: any, index: number) => (
                    <Card key={index} sx={{ marginBottom: 3, marginTop: 1, boxShadow: '0 0 1px 0', borderRadius: 3, backgroundColor: '#ffffff', position: 'relative' }}>
                        <CardContent>
                            {columns.map((column: any) => {
                                const value = row[column.id];
                                return (
                                    <Typography
                                        key={column.id}
                                        sx={{
                                            fontFamily: 'Poppins, sans-serif',
                                            fontSize: column.id === 'time' ? '15px' : '16px',
                                            fontWeight: column.id !== 'time' ? 400 : 500,
                                            marginTop: column.id === 'grade' ? '20px' : '',
                                            lineHeight: '24px',
                                            textAlign: 'left',
                                            marginBottom: 2,
                                            color: column.id === 'time' ? 'grey' : 'inherit',
                                        }}
                                    >
                                        {column.id === 'time' ? (
                                            value
                                        ) : (
                                            <>
                                                <span style={{ fontWeight: 500 }}>{column.label}: </span>
                                                <span className="text-muted">{column.format && typeof value === 'number' ? column.format(value) : value}</span>
                                            </>
                                        )}
                                    </Typography>
                                );
                            })}
                        </CardContent>
                        <CardActions sx={{ justifyContent: 'space-between' }}>
                            <div
                                className="d-flex"
                                style={{
                                    position: 'absolute',
                                    bottom: '10px',
                                    right: '10px',
                                    marginRight: '5px',
                                }}
                            >
                                <StyledIcon
                                    icon={faTrash}
                                    // style={{ marginRight: '16px', fontSize: '16px', height: '14px'}}
									onClick={() => handleOpen(row)}
                                />
                                <StyledIconEye
                                    icon={faEye}
                                    onClick={() => navigate(`/${route}?id=${index}`)}
                                />
                            </div>
                            {/* <Button size="small" onClick={() => navigate(`/${route}?id=${index}`)}>
                                View Details
                            </Button> */}
                        </CardActions>
                    </Card>
                ))
            )}
        </div>
    );
}