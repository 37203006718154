import { useRef } from 'react';
import jsPDF from 'jspdf';

interface AssessmentMethod {
  method: string;
  questions: string[];
}

interface LecturePlan {
  lectureNumber: string;
  duration: string;
  topicsCovered: string[];
  keyConcepts: string[];
  activities: string[];
  assessment: AssessmentMethod;
}

interface ExportToPDFProps {
  fileName: string;
  innerClass: string;
  jsonData: {
    grade: string;
    subject: string;
    unitChapter: string;
    learningObjectives: string[];
    teachingMethodologies: string[];
    advancedActivities: string[];
    simplifiedExplanations: string[];
    lecturePlan: LecturePlan[];
  };
}

function LessonPlanPdfExport({ fileName, innerClass, jsonData }: ExportToPDFProps) {
  const handleExportClick = (topicName: string) => {
    const doc = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4',
    });

    // Set font size and type
    doc.setFontSize(12);
    doc.setFont('helvetica', 'normal');

    // Add content to document
    let yPosition = 15;
    const maxTextWidth = doc.internal.pageSize.width - 35; // Maximum available width for text
    const indent = 20; // Indentation for text

    doc.text('Lesson Plan', 15, yPosition);
    yPosition += 10;
    doc.text(`Grade: ${jsonData.grade}`, 15, yPosition);
    yPosition += 10;
    doc.text(`Subject: ${jsonData.subject}`, 15, yPosition);
    yPosition += 10;
    doc.text(`Unit Chapter: ${jsonData.unitChapter}`, 15, yPosition);
    yPosition += 10;
    doc.text('Learning Objectives:', 15, yPosition);
    yPosition += 5;
    jsonData.learningObjectives.forEach((obj, index) => {
      const lines = doc.splitTextToSize(`${index + 1}. ${obj}`, maxTextWidth);
      lines.forEach((line: any) => {
        doc.text(line, indent, yPosition);
        yPosition += 5;
      });
    });
    yPosition += 5; // Add space after learning objectives

    // Check if new page is needed
    if (yPosition > doc.internal.pageSize.height - 20) {
      doc.addPage();
      yPosition = 15;
    }

    doc.text('Teaching Methodologies:', 15, yPosition);
    yPosition += 5;
    jsonData.teachingMethodologies.forEach((method, index) => {
      const lines = doc.splitTextToSize(`${index + 1}. ${method}`, maxTextWidth);
      lines.forEach((line: any) => {
        doc.text(line, indent, yPosition);
        yPosition += 5;
      });
    });
    yPosition += 5; // Add space after teaching methodologies

    // Check if new page is needed
    if (yPosition > doc.internal.pageSize.height - 20) {
      doc.addPage();
      yPosition = 15;
    }

    doc.text('Simplified Explanations:', 15, yPosition);
    yPosition += 5;
    jsonData.simplifiedExplanations.forEach((explanation, index) => {
      const lines = doc.splitTextToSize(`${index + 1}. ${explanation}`, maxTextWidth);
      lines.forEach((line: any) => {
        doc.text(line, indent, yPosition);
        yPosition += 5;
      });
    });
    yPosition += 5; // Add space after simplified explanations

    // Check if new page is needed
    if (yPosition > doc.internal.pageSize.height - 20) {
      doc.addPage();
      yPosition = 15;
    }

    // Lecture Plan
    jsonData.lecturePlan.forEach(plan => {
      doc.text(`Lecture Number: ${plan.lectureNumber}`, 15, yPosition);
      yPosition += 5;
      doc.text(`Duration: ${plan.duration}`, 15, yPosition);
      yPosition += 5;
      doc.text('Topics Covered:', 15, yPosition);
      yPosition += 5;
      plan.topicsCovered.forEach(topic => {
        const lines = doc.splitTextToSize(`- ${topic}`, maxTextWidth);
        lines.forEach((line: any) => {
          doc.text(line, indent, yPosition);
          yPosition += 5;
        });
      });
      doc.text('Key Concepts:', 15, yPosition);
      yPosition += 5;
      plan.keyConcepts.forEach(concept => {
        const lines = doc.splitTextToSize(`- ${concept}`, maxTextWidth);
        lines.forEach((line: any) => {
          doc.text(line, indent, yPosition);
          yPosition += 5;
        });
      });
      doc.text('Activities:', 15, yPosition);
      yPosition += 5;
      plan.activities.forEach(activity => {
        const lines = doc.splitTextToSize(`- ${activity}`, maxTextWidth);
        lines.forEach((line: any) => {
          doc.text(line, indent, yPosition);
          yPosition += 5;
        });
      });
      doc.text('Assessment Questions:', 15, yPosition);
      yPosition += 5;
      plan.assessment.questions.forEach((question, index) => {
        const lines = doc.splitTextToSize(`${index + 1}. ${question}`, maxTextWidth);
        lines.forEach((line: any) => {
          doc.text(line, indent, yPosition);
          yPosition += 5;
        });
      });
      yPosition += 10; // Add some space between lecture plans

      // Check if new page is needed
      if (yPosition > doc.internal.pageSize.height - 20) {
        doc.addPage();
        yPosition = 15;
      }
    });

    // Save document
    doc.save(`${topicName}.pdf`);
  };

  return (
    <div>
      <div className={innerClass}>
        <button
          onClick={() => handleExportClick(jsonData.unitChapter)}
          className='btn btn-primary btn-purple my-2'
          style={{ backgroundColor: '#a084dc', padding: '10px 22px' }}
        >
          Export as Pdf
        </button>
      </div>
    </div>
  );
}

export default LessonPlanPdfExport;
