// import { ContentHeader } from '@app/components';
// import ExportToDocx from '@app/components/export/DocsExport';
// import ExportToPDF from '@app/components/export/PdfExport';
// import LessonPlanPdfExport from '@app/components/export/LessonPlanPdfExport';
// // import { AnswerModel, QuestionModel } from '@app/components/quiz-model/quiz-model';
// import { setLesson } from '@app/store/reducers/lesson';
// import { bigScreenCheck, getLessonSessionData } from '@app/utils/helpers';
// import { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux/';
// import { useNavigate } from 'react-router-dom';
// import LessonPlanCard from './LessonPlanCard';
// import ExportButton from '@app/components/export/ExportButton';
// import { deleteLessonPlan, getAllLessonPlan } from '@app/services/databaseApi';
// import LessonPlanDocxExport from '@app/components/export/LessonPlanDocxExport';

// export interface LecturePlan {
// 	lectureNumber: string;
// 	duration: string;
// 	topicsCovered: string[];
// 	keyConcepts: string[];
// 	activities: string[];
// 	assessment: {
// 		method: string;
// 		questions: string[];
// 	};
// }
// export interface LessonData {
// 	grade: string;
// 	subject: string;
// 	topic: string;
// 	numberOfLectures: string;
// 	learningObjectives: string[];
// 	teachingMethodologies: string[];
// 	advancedActivities: string[];
// 	simplifiedExplanations: string[];
// 	assessmentMethods: string[];
// 	lecturePlan: LecturePlan[];
// }


// const LessonPlan = () => {
// 	const dispatch = useDispatch();
// 	const bigScreen = bigScreenCheck();

// 	// eslint-disable-next-line no-restricted-globals
// 	const queryParameters = new URLSearchParams(location.search);
// 	const id = queryParameters.get('id') || '0';
// 	const lessonData = useSelector((state: any) => state?.lesson?.lessonData);

// 	useEffect(() => {
// 		fetchLessonHistory();
// 	}, []);

// 	const fetchLessonHistory = async () => {
// 		const lessonHistoryList = await getAllLessonPlan();
// 		dispatch(setLesson(lessonHistoryList[id]));
// 		console.log('lessonData', lessonData);
// 	};

// 	const handleDelete = async () => {
// 		await deleteLessonPlan(lessonData?._id);

// 		navigate('/lesson-history');
// 	};
	
// const handleClick = () => {
// 		navigate("/lesson-history");

// 	};
		
// 	var counter = 1;
// 	var docxCounter = 1;
	
// 	return (
// 		<div>

// 			<ContentHeader title={'Lesson Plan'} />
// 			{/* <div className='d-flex justify-content-end me-5 mt-n5'>
// 				<button className='btn-outline-purple px-5' onClick={() => navigate('/lesson')} style={{ height: '50px' }}>
// 					New Lesson
// 				</button>
// 			</div> */}
// 			<div className={bigScreen ? 'mx-5' : ''}>

// <div className='d-flex justify-content-between'>
// 	<div className='d-flex btn-purple'>
// 		<button className='btn' onClick={handleClick} style={{ color: "white" }}>
// 			Back
// 		</button>
// 	</div>
// 	<div>
// 		<ExportButton
// 			docxChild={
// 				<LessonPlanDocxExport
// 					fileName={'Lesson Plan'}
// 					innerClass={'d-flex justify-content-center'}
// 					jsonData={lessonData}
// 				/>
// 			}
// 			pdfChild={
// 				<LessonPlanPdfExport
// 					fileName={'Lesson Plan'}
// 					innerClass={'d-flex justify-content-center'}
// 					jsonData={lessonData}
// 				/>
// 			}
// 		/>
// 	</div>
// {/* >>>>>>> Stashed changes */}
// </div>
// 				<div className='border rounded p-3 my-3'>
// 					<LessonPlanCard data={lessonData} />
// 				</div>
// 				<div className='d-flex justify-content-end'>
// 					<button className='btn btn-outline-danger px-5' onClick={handleDelete} style={{ height: '50px' }}>
// 						Delete Lesson
// 					</button>
// 				</div>
// 			</div>
// 		</div>
// 	);
// };

// export default LessonPlan;
import { ContentHeader } from '@app/components';
import ExportToDocx from '@app/components/export/DocsExport';
import ExportToPDF from '@app/components/export/PdfExport';
import LessonPlanPdfExport from '@app/components/export/LessonPlanPdfExport';
import { setLesson } from '@app/store/reducers/lesson';
import { bigScreenCheck, getLessonSessionData } from '@app/utils/helpers';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux/';
import { useNavigate } from 'react-router-dom';
import LessonPlanCard from './LessonPlanCard';
import ExportButton from '@app/components/export/ExportButton';
import { deleteLessonPlan, getAllLessonPlan } from '@app/services/databaseApi';
import LessonPlanDocxExport from '@app/components/export/LessonPlanDocxExport';

export interface LecturePlan {
    lectureNumber: string;
    duration: string;
    topicsCovered: string[];
    keyConcepts: string[];
    activities: string[];
    assessment: {
        method: string;
        questions: string[];
    };
}

export interface LessonData {
    grade: string;
    subject: string;
    topic: string;
    numberOfLectures: string;
    learningObjectives: string[];
    teachingMethodologies: string[];
    advancedActivities: string[];
    simplifiedExplanations: string[];
    assessmentMethods: string[];
    lecturePlan: LecturePlan[]; 
}

const LessonPlan = () => {
    const dispatch = useDispatch();
    const bigScreen = bigScreenCheck();
    const navigate = useNavigate();

    const queryParameters = new URLSearchParams(window.location.search);
    const id = queryParameters.get('id') || '0';
    const lessonData = useSelector((state: any) => state?.lesson?.lessonData);

    useEffect(() => {
        fetchLessonHistory();
    }, []);

    const fetchLessonHistory = async () => {
        try {
            const lessonHistoryList = await getAllLessonPlan();
            dispatch(setLesson(lessonHistoryList[id]));
        } catch (error) {
            console.error("Error fetching lesson history:", error);
        }
    };

    const handleDelete = async () => {
        try {
            await deleteLessonPlan(lessonData?._id);
            navigate('/lesson-history');
        } catch (error) {
            console.error("Error deleting lesson:", error);
        }
    };

    const handleClick = () => {
        navigate("/lesson-history");
    };

    return (
        <div>
            <ContentHeader title={'Lesson Plan'} />
            <div className={bigScreen ? 'mx-5' : ''}>
                <div className='d-flex justify-content-between'>
                    <div className='d-flex btn-purple'>
                        <button className='btn' onClick={handleClick} style={{ color: "white", width: "66px" }}>
                            Back
                        </button>
                    </div>
                    <div style={{width: "140px"}}>
                        <ExportButton
                            docxChild={
                                <LessonPlanDocxExport
                                    fileName={'Lesson Plan'}
                                    innerClass={'d-flex justify-content-center'}
                                    jsonData={lessonData}
                                />
                            }
                            pdfChild={
                                <LessonPlanPdfExport
                                    fileName={'Lesson Plan'}
                                    innerClass={'d-flex justify-content-center'}
                                    jsonData={lessonData}
                                />
                            }
                        />
                    </div>
                </div>
                <div>
                    <LessonPlanCard data={lessonData} />
                </div>
                <div className='d-flex justify-content-end'>
                    <button className='btn btn-outline-danger px-5' onClick={handleDelete} style={{ height: '50px' }}>
                        Delete Lesson
                    </button>
                </div>
            </div>
        </div>
    );
};

export default LessonPlan;
