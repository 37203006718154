import axios from 'axios';

import { toast } from 'react-toastify';


export const authSignup = async (
	name: string,
	email: string,
	mobileNumber: string,
	password: string,
  ) => {
	
	try {
	  const response = await axios.post(`${import.meta.env.VITE_API_URL}/api/auth/signup`, {
		email,
		password,
		name,
		mobileNumber
	  });
  
	  // Handle success response
	  if (response.status === 200) {
		const data = response.data;
		toast.success('Sign up successful');
		// localStorage.setItem('authentication', JSON.stringify(data?._id));
		// localStorage.setItem('user', JSON.stringify(data));
		console.log(data)
		window.location.href='/login'
		
	  } else {
		// Handle non-200 responses
		toast.error('User creation failed');
		return null;
	  }
	} catch (error: any) { 
	  
	  console.error('Error occurred during sign up:', error);
	  let errorMessage = 'User creation failed'; // Default error message
	  if (error.response && error.response.data) {
		const responseData = error.response.data;
		if (Array.isArray(responseData.message)) {
		  errorMessage = responseData.message.join(', '); // Join array elements into a single string
		} else if (typeof responseData.message === 'string') {
		  errorMessage = responseData.message;
		}
	  }
	  toast.error(errorMessage); 
	  return null;
	}
  };
  
  
  



export const authLogin = (email: string, password: string) => {
	return new Promise(async (res, rej) => {
		try {
			const response = await fetch(`${import.meta.env.VITE_API_URL}/api/auth/signin`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				credentials: 'same-origin',
				body: JSON.stringify({ email, password })
			});

			if (response.ok) {
				// Successful sign in
				// const currentDate = new Date();
				// const expiryDate = new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000); // Add 7 days in milliseconds
				// const expiryTime = expiryDate.toUTCString();

				const data = await response.json();
				localStorage.setItem('authentication', JSON.stringify(data.user._id));
				localStorage.setItem('user', JSON.stringify(data.user));
				localStorage.setItem('tokens', JSON.stringify(data.tokens));

				// document.cookie = `jwt=${data?.tokens?.access_token}; expires=${expiryTime}; path=/`;
				// document.cookie = `rf-jwt=${data?.tokens?.refresh_token}; expires=${expiryTime}; path=/`;

				// console.log('Sign in successful');
				toast.success('Logged in successfully!');
				console.log(data)
				return res({ profile: { ...data.user } });
			} else {
				// Error in sign in
				const errorData = await response.json();
				// Assuming the API returns the error message in the response body
				return rej({ message: errorData.message });
			}
		} catch (error) {
			console.error('Error occurred during sign in:', error);
			toast.error('login failed!');
		}
	});
};

export const fetchGoogleUser = async (
	token: string
	// inviteCode: string | null
) => {
	return new Promise(async (res, rej) => {
		// console.log('google login', token);
		try {
			const requestOptions: RequestInit = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`
				},
				credentials: 'include',
				mode: 'cors'
			};

			// if (inviteCode !== null) {
			// 	const body = { inviteCode: inviteCode };
			// 	requestOptions.body = JSON.stringify(body);
			// }

			const response = await fetch(`/api/auth/google`, requestOptions);
			// const response = await fetch(`${import.meta.env.VITE_API_URL}/api/auth/google`, requestOptions);
			// console.log('google response', response);

			if (response.ok) {
				const data = await response.json();
				// console.log(response);
				localStorage.setItem('authentication', JSON.stringify(data.user._id));
				localStorage.setItem('user', JSON.stringify(data.user));
				localStorage.setItem('tokens', JSON.stringify(data.tokens));
				return res({ profile: { ...data.user } });
				// return data.user;
			} else {
				const errorData = await response.json(); // Assuming the API returns the error message in the response body
				// return rej({ message: errorData.message });
				return errorData.message;
				// throw new Error('Failed to send data to the server');
			}
		} catch (error) {
			console.error('Error occurred during sign in:', error);
			toast.error('Login failed!');
			return '';
		}
	});
};

export const updateUser = async (id: string | undefined) => {
	return new Promise(async (res, rej) => {
		await refreshAccessToken();
		try {
			const response = await fetch(`${import.meta.env.VITE_API_URL}/api/users/${id}`, {
				headers: {
					'Content-Type': 'application/json'
				},
				credentials: 'include',
				mode: 'cors'
			});
			if (response.ok) {
				const user = await response.json();
				localStorage.setItem('authentication', JSON.stringify(user._id));
				return res({ profile: { ...user } });
			} else {
				const errorData = await response.json();
				return rej({ message: errorData.message });
			}
		} catch (error: any) {
			// localStorage.removeItem('authentication');
			// console.log(error);
			return rej({ message: error.message });
		}
	});
};

export const forgotPassword = async (email: string) => {
    try {
        const response = await axios.post(`${import.meta.env.VITE_API_URL}/api/auth/forgot-password`, { email });
        if (response.status === 200) {
            toast.success('Password reset link sent successfully!');
        } else {
            toast.error('Failed to send password reset link');
        }
    } catch (error) {
        console.error('Error occurred while sending password reset link:', error);
        toast.error('Failed to send password reset link');
    }
};

export const resetPassword = async (token: string, newPassword: string) => {
    try {
        const response = await axios.post(`${import.meta.env.VITE_API_URL}/api/auth/reset-password`, { token, newPassword });
        if (response.status === 200) {
            toast.success('Password reset successfully!');
            // Optionally redirect the user to the login page
            window.location.href = '/login';
        } else {
            toast.error('Failed to reset password');
        }
    } catch (error) {
        console.error('Error occurred while resetting password:', error);
        toast.error('Failed to reset password');
    }
};


export const getAuthStatus = async () => {
	return new Promise(async (res, rej) => {
		try {
			let userInfo = localStorage.getItem('user');
			if (userInfo) {
				const parsedUserInfo = JSON.parse(userInfo);
				refreshAccessToken();
				return res(parsedUserInfo);
			}
			return res(undefined);
		} catch (error) {
			// console.log('get auth status got hit');
			return res(undefined);
		}
	});
};

// export const getAuthStatus = async () => {
// 	return new Promise(async (res, rej) => {
// 		try {
// 			let authentication = localStorage.getItem('authentication');
// 			// console.log(`authentication:`, authentication)
// 			if (authentication) {
// 				const parsedAuthentication = JSON.parse(authentication);
// 				refreshAccessToken();
// 				const newAuthentication = await updateUser(
// 					// parsedAuthentication
// 					parsedAuthentication?.profile?.user?._id ?? parsedAuthentication
// 				);
// 				return res(newAuthentication);
// 			}
// 			return res(undefined);
// 		} catch (error) {
// 			return res(undefined);
// 		}
// 	});
// };

// export const logout = async () => {
//     try {
//         const tokens = JSON.parse(localStorage.getItem('tokens') || '{"refresh_token":""}');
//         const body = { refreshToken: tokens.refresh_token };

//         // Send logout request
//         await axios.post('/api/auth/logout', body);

//         // Clear local storage and session storage
//         localStorage.clear();
//         sessionStorage.clear();

//         // Optionally redirect the user to the login page or another appropriate location
//         // window.location.href = '/login';

//         console.log('Logged out successfully');
//     } catch (error) {
//         // Handle errors that occur during logout
//         console.error('Error occurred during logout:', error);

//         // Clear local storage and session storage even if the logout request fails
//         localStorage.clear();
//         sessionStorage.clear();
//     }
// };
// export const refreshAccessToken = async () => {
//     const tokens = JSON.parse(localStorage.getItem('tokens') || '{"access_token":"","refresh_token":""}');

//     if (!tokens.access_token || !tokens.refresh_token) {
//         // If access token or refresh token is missing, perform logout
//         await logout();
//         return false;
//     }

//     const requestBody = JSON.stringify({ refreshToken: tokens.refresh_token });

//     try {
//         const response = await fetch(`${import.meta.env.VITE_API_URL}/api/auth/refresh`, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json'
//             },
//             credentials: 'include',
//             mode: 'cors',
//             body: requestBody
//         });

//         if (response.ok) {
//             const data = await response.json();
//             localStorage.setItem('tokens', JSON.stringify(data));
//             return true;
//         } else if (response.status === 401 && response.statusText === 'Unauthorized, Refresh Token Not Provided') {
//             // If refresh token is expired or invalid, perform logout
//             await logout();
//             return false;
//         } else {
//             // Handle other non-OK responses
//             console.error('Refresh token request failed:', response.statusText);
//             return false;
//         }
//     } catch (error) {
//         // If an error occurs during the refresh token request, perform logout
//         console.error('Error occurred during refresh token request:', error);
//         await logout();
//         return false;
//     }
// };

export const refreshAccessToken = async () => {
    try {
        const tokens = JSON.parse(localStorage.getItem('tokens') || '{"access_token":"","refresh_token":""}');

        if (!tokens.access_token || !tokens.refresh_token) {
            // If access token or refresh token is missing, perform logout
            await logout();
            return false;
        }

        const requestBody = JSON.stringify({ refreshToken: tokens.refresh_token });

        const response = await fetch(`${import.meta.env.VITE_API_URL}/api/auth/refresh`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            mode: 'cors',
            body: requestBody
        });

        if (response.ok) {
            const data = await response.json();
            localStorage.setItem('tokens', JSON.stringify(data));
            return true;
        } else {
            // Handle refresh token failure
            console.error('Refresh token request failed:', response.statusText);
            await logout();
            return false;
        }
    } catch (error) {
        // Handle refresh token request errors
        console.error('Error occurred during refresh token request:', error);
        await logout();
        return false;
    }
};

export const logout = async () => {
    try {
        const tokens = JSON.parse(localStorage.getItem('tokens') || '{"refresh_token":""}');
        const body = { refreshToken: tokens.refresh_token };

        // Send logout request
        await axios.post(`${import.meta.env.VITE_API_URL}/api/auth/logout`, body);

        // Clear local storage
        localStorage.clear();

        // Redirect the user to the login page
        window.location.href = '/login';

        console.log('Logged out successfully');
    } catch (error) {
        // Handle errors that occur during logout
        console.error('Error occurred during logout:', error);

        // Clear local storage even if the logout request fails
        localStorage.clear();

        // Redirect the user to the login page
        window.location.href = '/login';
    }
};

