import React, { useState, useEffect, useCallback } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSidebarMenu } from '@app/store/reducers/ui';
import { addWindowClass, removeWindowClass, sleep } from '@app/utils/helpers';
import Header from '@app/modules/main/header/Header';
import MenuSidebar from '@app/modules/main/menu-sidebar/MenuSidebar';
import Footer from '@app/modules/main/footer/Footer';
import { PfImage } from '@profabric/react-components';
import logo from '@image/logo.png';
import { setUserInfo } from '@app/store/reducers/userInfo';

const Main = () => {
	const dispatch = useDispatch();
	const menuSidebarCollapsed = useSelector((state: any) => state.ui.menuSidebarCollapsed);
	const screenSize = useSelector((state: any) => state.ui.screenSize);
	const authentication = useSelector((state: any) => state.auth.authentication);
	const [isAppLoaded, setIsAppLoaded] = useState(false);

	const handleToggleMenuSidebar = () => {
		dispatch(toggleSidebarMenu());
	};

	useEffect(() => {
		setIsAppLoaded(Boolean(authentication));
	}, [authentication]);

	useEffect(() => {
		removeWindowClass('register-page');
		removeWindowClass('login-page');
		removeWindowClass('hold-transition');
		addWindowClass('sidebar-mini');

		// fetchProfile();
		return () => {
			removeWindowClass('sidebar-mini');
		};
	}, []);

	useEffect(() => {
		try {
			var temp: any = localStorage.getItem('user');
			temp = JSON.parse(temp);
			dispatch(setUserInfo(temp));
		} catch {
			console.log('user not avilable in localStorage');
		}
	}, []);

	useEffect(() => {
		removeWindowClass('sidebar-closed');
		removeWindowClass('sidebar-collapse');
		removeWindowClass('sidebar-open');
		if (menuSidebarCollapsed && screenSize === 'lg') {
			addWindowClass('sidebar-collapse');
		} else if (menuSidebarCollapsed && screenSize === 'xs') {
			addWindowClass('sidebar-open');
		} else if (!menuSidebarCollapsed && screenSize !== 'lg') {
			addWindowClass('sidebar-closed');
			addWindowClass('sidebar-collapse');
		}
	}, [screenSize, menuSidebarCollapsed]);

	const getAppTemplate = useCallback(() => {
		if (!isAppLoaded) {
			return (
				<div className='preloader flex-column justify-content-center align-items-center'>
					<PfImage className='animation__shake' src={logo} alt='EduAssist Logo' height={60} width={60} />
				</div>
			);
		}
		return (
			<>
				<Header />
				<MenuSidebar />
				<div className='content-wrapper' style={{backgroundColor:"#ffffff"}}>
					<div className='pt-3' />
					<section className='content'>
						<Outlet />
					</section>
				</div>
				<Footer />
				<div id='sidebar-overlay' role='presentation' onClick={handleToggleMenuSidebar} onKeyDown={() => {}} />
			</>
		);
	}, [isAppLoaded]);

	return <div className='wrapper'>{getAppTemplate()}</div>;
};

export default Main;
