import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Form, InputGroup, Spinner, Button } from 'react-bootstrap';
import { ContentHeader } from '@components';
import { lessonGenerator } from '@app/services/generator';
import { useDispatch, useSelector } from 'react-redux';
import { setLesson } from '@app/store/reducers/lesson';
import TimeProgressBar from '@app/components/progressBar/TimeProgressBar';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { bigScreenCheck, getLessonFormData, getLessonSessionData } from '@app/utils/helpers';

const LessonGenerator = () => {
	const [isGenerating, setIsGenerating] = useState(false);
	const [show, setShow] = useState(true);
	const bigScreen = bigScreenCheck();

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const lessonData = getLessonSessionData();
	const [lessonForm, setLessonForm] = useState(getLessonFormData());

	useEffect(() => {
		if (lessonData === undefined) {
			setShow(true);
		} else setShow(false);
	}, [lessonData]);

	const handleNewLesson = () => {
		const newForm = {
			grade: '',
			subject: '',
			chapter: '',
			topic: '',
			lecture: '',
			duration: ''
		};
		localStorage.setItem('lesson-form', JSON.stringify(newForm));
		setLessonForm(newForm);
		setIsGenerating(false);
		location.reload();
	};

	const { handleChange, values, handleSubmit, touched, errors } = useFormik({
		initialValues: lessonForm,
		validationSchema: Yup.object({
			grade: Yup.string().required(),
			subject: Yup.string().required(),
			chapter: Yup.string(),
			topic: Yup.string().required(),
			lecture: Yup.string(),
			duration: Yup.string()
		}),
		onSubmit: async (values) => {
			if (values.lessonType === '' || values.questionsCount === '' || values.difficultyLevel === '') {
				toast.error('Please select all fields');
				return;
			}

			setIsGenerating(true);
			try {
				const response = await lessonGenerator(values);
				if (response.status === 201) {
					dispatch(setLesson(response.data));
					sessionStorage.setItem('lessonData', JSON.stringify(response.data));
					toast.success('Lesson successfully created');
					setTimeout(() => {
						navigate('/lesson-plan');
					}, 3000);
				} else {
					setIsGenerating(false);
					// toast.error('Failed to create lesson. Please try again.');
				}
			} catch (error) {
				setIsGenerating(false);
			}
		}
	});

	const handleLastLesson = (e: any) => {
		navigate('/lesson-history');
		setLessonForm(lessonForm);
	};

	return (
		<div>
			<ContentHeader title={'Lesson Plan Generator'} />
			<div className={`${bigScreen && 'mx-5'} `}>
				{/* <div className='d-flex justify-content-end'>
					<button className='btn-outline-purple px-5' onClick={handleNewLesson} style={{ height: '50px' }}>
						New Lesson Plan
					</button>
				</div> */}
				<form onSubmit={handleSubmit}>
					{isGenerating ? (
						<TimeProgressBar />
					) : (
						<div>
							<div className='mb-3'>
								<div className='fs-5 py-3'>Grade level</div>
								<InputGroup className='mb-3'>
									<Form.Control
										className='p-4'
										id='grade'
										name='grade'
										type='text'
										onChange={handleChange}
										value={values.grade}
										isValid={touched.grade && !errors.grade}
										isInvalid={touched.grade && !!errors.grade}
									/>
									{/* {touched.grade && errors.grade ? (
									<Form.Control.Feedback type='invalid'>{errors.grade}</Form.Control.Feedback>
								) : null} */}
								</InputGroup>
							</div>
							<div className=''>
								<div className='fs-5 py-3'>Subject</div>
								<InputGroup className='mb-3'>
									<Form.Control
										className='p-4'
										id='subject'
										name='subject'
										type='text'
										onChange={handleChange}
										value={values.subject}
										isValid={touched.subject && !errors.subject}
										isInvalid={touched.subject && !!errors.subject}
									/>
									{/* {touched.subject && errors.subject ? (
									<Form.Control.Feedback type='invalid'>{errors.subject}</Form.Control.Feedback>
								) : null} */}
								</InputGroup>
							</div>
							<div className='mb-3'>
								<div className='fs-5 py-3'>Chapter</div>
								<InputGroup className='mb-3'>
									<Form.Control
										className='p-4'
										id='chapter'
										name='chapter'
										type='text'
										onChange={handleChange}
										value={values.chapter}
										isValid={touched.chapter && !errors.chapter}
										isInvalid={touched.chapter && !!errors.chapter}
									/>
									{/* {touched.chapter && errors.chapter ? (
									<Form.Control.Feedback type='invalid'>{errors.chapter}</Form.Control.Feedback>
								) : null} */}
								</InputGroup>
							</div>
							<div className='mb-3'>
								<div className='fs-5 py-3'>topic</div>
								<InputGroup className='mb-3'>
									<Form.Control
										className='p-4'
										id='topic'
										name='topic'
										type='text'
										onChange={handleChange}
										value={values.topic}
										isValid={touched.topic && !errors.topic}
										isInvalid={touched.topic && !!errors.topic}
									/>
									{/* {touched.topic && errors.topic ? (
									<Form.Control.Feedback type='invalid'>{errors.topic}</Form.Control.Feedback>
								) : null} */}
								</InputGroup>
							</div>

							{/* <div className='mb-3'>
								<div className='fs-5 py-3'>Required Lectures</div>
								<InputGroup className='mb-3'>
									<Form.Control
										className='p-4'
										id='lecture'
										name='lecture'
										type='text'
										onChange={handleChange}
										value={values.lecture}
										isValid={touched.lecture && !errors.lecture}
										isInvalid={touched.lecture && !!errors.lecture}
									/>
									{touched.lecture && errors.lecture ? (
									<Form.Control.Feedback type='invalid'>{errors.lecture}</Form.Control.Feedback>
								) : null}
								</InputGroup>
							</div> */}
							<div className='mb-3'>
								<div className='fs-5 py-3'>Required Lectures</div>
								<div className='form-group'>
									<select
										className='form-select form-select-lg'
										id='lecture'
										name='lecture'
										value={values.lecture}
										onChange={handleChange}
									>
										<option value='' className='text-muted'>
											Select Required Lectures
										</option>
										<option value='1'>1</option>
										<option value='2'>2</option>
										<option value='3'>3</option>
										<option value='4'>4</option>
										<option value='5'>5</option>
									</select>
								</div>
							</div>

							<div className='mb-3'>
								<div className='fs-5 py-3'>Lecture Duration</div>
								<div className='form-group'>
									<select
										className='form-select form-select-lg'
										id='duration'
										name='duration'
										value={values.duration}
										onChange={handleChange}
									>
										<option value='' className='text-muted'>
											- Select -
										</option>
										<option value='20 minutes'>20 minutes</option>
										<option value='30 minutes'>30 minutes</option>
										<option value='60 minutes'>60 minutes</option>
										<option value='90 minutes'>90 minutes</option>
										<option value='120 minutes'>120 minutes</option>
									</select>
								</div>
							</div>
							<div>
								<div className={`d-flex justify-content-between my-4`}>
									<button
										className={`btn btn-outline-secondary px-3 mx-1`}
										onClick={(e) => handleLastLesson(e)}
										style={{ height: '50px' }}
									>
										Lesson Plan History
									</button>
									<button className='btn-purple px-5' type='submit' style={{ height: '50px' }}>
										Generate
									</button>
								</div>
							</div>
						</div>
					)}
				</form>
			</div>
		</div>
	);
};

export default LessonGenerator;
