import React from 'react';
// import {useTranslation} from 'react-i18next';
// import {DateTime} from 'luxon';
import packageJSON from '../../../../package.json';

const Footer = () => {
  // const [t] = useTranslation();
  const dateYear = new Date().getFullYear();
  return (
    <footer className="main-footer" style={{backgroundColor: "#ffffff"}}>
      <strong>
        <span>Copyright © {dateYear} </span>
        <a href="https://astconsulting.in/" target="_blank" rel="noopener noreferrer" className='text-bold'>
          ai47labs
        </a>
        <span>.</span>
      </strong>
      <div className="float-right d-none d-sm-inline-block">
        <b>Version</b>
        <span>&nbsp;0.1.0</span>
      </div>
    </footer>
  );
};

export default Footer;
