import { createSlice } from '@reduxjs/toolkit';

export interface AuthState {
	assignmentData?: any;
}

const initialState: AuthState = {
	assignmentData: undefined
};

export const assignmentSlice: any = createSlice({
	name: 'assignment',
	initialState,
	reducers: {
		setAssignment: (state: any, { payload }: any) => {
			state.assignmentData = payload;
			console.log('assignment payload ', payload);
		}
	}
});

export const { setAssignment } = assignmentSlice.actions;

export default assignmentSlice.reducer;
