import { createSlice } from '@reduxjs/toolkit';

export interface AuthState {
	quizData?: any;
}

const initialState: AuthState = {
	quizData: undefined
};

export const quizSlice: any = createSlice({
	name: 'quiz',
	initialState,
	reducers: {
		setQuiz: (state: any, { payload }: any) => {
			state.quizData = payload;
			console.log('quiz payload ', payload);
		}
	}
});

export const { setQuiz } = quizSlice.actions;

export default quizSlice.reducer;
