import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAllNotes } from '@app/services/databaseApi';
import { ContentHeader } from '@app/components';
import MuiTable from '@app/components/basic-table/MuiTable';
import { bigScreenCheck } from '@app/utils/helpers';
import { setNotes } from '@app/store/reducers/notes';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs'; // Import dayjs library

interface Data {
	id: string;
	time: string;
	grade: string;
	subject: string;
	topic: string;
}

interface Column {
	id: 'time' | 'grade' | 'subject' | 'topic';
	label: string;
	minWidth?: number;
	align?: string;
	format?: (value: string) => string;
}

function createData(id: string, time: string, grade: string, subject: string, topic: string): Data {
	return { id, time, grade, subject, topic };
}

const PreviousNotes = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const bigScreen = bigScreenCheck();

	const buttonStyle = {
		height: '50px',
		marginLeft: bigScreen ? '59vh' : '10px', // margin on the right
		padding: '0 10px', // minimum padding
		width: '160px', // fixed width
		alignSelf: 'flex-end', // align to the right within the flex container
	  };

	const formatDate = (dateTime: string): string => {
		return dayjs(dateTime).format('DD/MM/YYYY hh:mm A');
	};

	const columns: readonly Column[] = [
		{ id: 'time', label: 'Time', minWidth: 150, format: formatDate },
		{
			id: 'grade',
			label: 'Grade',
			align: 'center',
			
			
		},
		{
			id: 'subject',
			label: 'Subject',
			align: 'left',
			minWidth: 100
		},
		{
			id: 'topic',
			label: 'Topic',
			align: 'left'
		},
	];

	const [rows, setRows] = useState<any[]>([]);

	useEffect(() => {
		fetchNotesHistory();
	}, []);

	const fetchNotesHistory = async () => {
		try {
			const notesHistoryList = await getAllNotes();
			dispatch(setNotes(notesHistoryList));
			setRows(notesHistoryList.map((item: any) => createData(
				item?._id || 'invalid id',
				formatDate(item?.updatedAt) || 'unavailable', // Apply formatDate to updatedAt
				item?.gradeLevel || 'unavailable',
				item?.subject || 'unavailable',
				item?.unitChapter || 'unavailable'
			)));
		} catch (error) {
			console.error('Error fetching notes history:', error);
		}
	};

	return (
			<div>
			<div style={{display: "flex"}}>
			<ContentHeader title={'Previous Notes'} />
			<div className={`d-flex justify-content-end ${bigScreen && 'me-5'} mb-3`}>
				<button className='btn-outline-purple px-7'onClick={() => navigate('/notes')} style={buttonStyle}>
					Generate Notes	
				</button>
			</div>
			</div>
			<div className={bigScreen ? 'mx-5' : ''}>
				<MuiTable columns={columns} rows={rows} route={'class-notes'} />
			</div>
		</div>
	);
};

export default PreviousNotes;
