import teacher from '@image/teacher.png';

const Hero = ({height}: {height: number}) => {
	return (
		<div className=''>
			<img
				src={teacher}
				alt='teacher'
				height={`${height}rem`}
				className='rounded rounded-start-0'
			/>
		</div>
	);
};

export default Hero;
