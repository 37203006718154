import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Form, InputGroup, Spinner, Button } from 'react-bootstrap';
import { ContentHeader } from '@components';
import { notesGenerator } from '@app/services/generator';
import { useDispatch, useSelector } from 'react-redux';
import { setNotes } from '@app/store/reducers/notes';
import TimeProgressBar from '@app/components/progressBar/TimeProgressBar';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { bigScreenCheck, getNotesFormData, getNotesSessionData } from '@app/utils/helpers';

const NotesGenerator = () => {
	const [isGenerating, setIsGenerating] = useState(false);
	const [show, setShow] = useState(true);
	const bigScreen = bigScreenCheck();

	const navigate = useNavigate();
	const dispatch = useDispatch();
	// const notesData = getNotesSessionData();
	// const [notesForm, setNotesForm] = useState(getNotesFormData());

	// useEffect(() => {
	// 	if (notesData === undefined) {
	// 		setShow(true);
	// 	} else setShow(false);
	// }, [notesData]);

	const handleNewNotes = () => {
		const newForm = {
			grade: '',
			subject: '',
			topic: '',
		};

		// localStorage.setItem('notes-form', JSON.stringify(newForm));
		// setNotesForm(newForm);
		setIsGenerating(false);
		location.reload();
	};

	const { handleChange, values, handleSubmit, touched, errors } = useFormik({
		initialValues: {
			grade: '',
			subject: '',
			topic: '',
		},
		validationSchema: Yup.object({
			grade: Yup.string().required(),
			subject: Yup.string().required(),
			topic: Yup.string().required(),
		}),
		onSubmit: async (values) => {
			try{
			setIsGenerating(true);
			// localStorage.setItem('notes-form', JSON.stringify(values));
			// setNotesForm(values);
			const response: any = await notesGenerator(values);
			console.log('response in classnotes', response);
			// setIsGenerating(false);
			if (response.status === 201) {
			dispatch(setNotes(response.data));
			sessionStorage.setItem('notesData', JSON.stringify(response.data));
			
			toast.success('Notes successfully created');
			setTimeout(() => {
			  navigate('/class-notes');
			}, 3000);
		  } else {
			setIsGenerating(false);
			// toast.error('Failed to create notes. Please try again.');
		  }
		} catch (error) {
		  setIsGenerating(false);
		//   toast.error('Failed to create notes. Please try again.');
		}
	  }
	});

	const handleLastNotes = (e: any) => {
		navigate('/notes-history');
		// setNotesForm(notesForm);
	};

	return (
		<div>
			<ContentHeader title={'Class Notes Generator'} />
			{/* <div className='d-flex justify-content-end me-5 my-n4'>
				<button className='btn-outline-purple px-5' onClick={handleNewNotes} style={{ height: '50px' }}>
					New Notes
				</button>
			</div> */}
			<form onSubmit={handleSubmit}>
				{
					isGenerating ? (	<TimeProgressBar />) : (<div className={`${bigScreen && 'mx-5'} `}>
					<div>
					<div className={`fs-5 ${bigScreen ? 'py-3' : 'py-1'}`}>Grade Level</div>
						<Form.Control
							className='p-4'
							id='grade'
							name='grade'
							type='text'
							onChange={handleChange}
							value={values.grade}
							isValid={touched.grade && !errors.grade}
							isInvalid={touched.grade && !!errors.grade}
						/>
						{/* {touched.grade && errors.grade ? (
							<Form.Control.Feedback type='invalid'>{errors.grade}</Form.Control.Feedback>
						) : null} */}
					</div>
					<div>
					<div className={`fs-5 ${bigScreen ? 'py-3' : 'py-1'}`}>Subject</div>
						<Form.Control
							className='p-4'
							id='subject'
							name='subject'
							type='text'
							onChange={handleChange}
							value={values.subject}
							isValid={touched.subject && !errors.subject}
							isInvalid={touched.subject && !!errors.subject}
						/>
						{/* {touched.subject && errors.subject ? (
							<Form.Control.Feedback type='invalid'>{errors.subject}</Form.Control.Feedback>
						) : null} */}
					</div>
					{/* <div>
						<div className='fs-5 py-3'>Class/Section</div>
						<Form.Control
							className='p-4'
							id='class'
							name='class'
							type='text'
							onChange={handleChange}
							value={values.class}
							isValid={touched.class && !errors.class}
							isInvalid={touched.class && !!errors.class}
						/>
					</div> */}
					<div>
					<div className={`fs-5 ${bigScreen ? 'py-3' : 'py-1'}`}>Topic</div>
						<Form.Control
							className='p-4'
							id='topic'
							name='topic'
							type='text'
							onChange={handleChange}
							value={values.topic}
							isValid={touched.topic && !errors.topic}
							isInvalid={touched.topic && !!errors.topic}
						/>
						{/* {touched.topic && errors.topic ? (
							<Form.Control.Feedback type='invalid'>{errors.topic}</Form.Control.Feedback>
						) : null} */}
					</div>
					<div>
						
							<div className={`d-flex justify-content-between my-4`}>
								<button
									className={`btn btn-outline-secondary px-5`}
									onClick={(e) => handleLastNotes(e)}
									style={{ height: '50px' }}
								>
									Notes history
								</button>
								<button className='btn-purple px-5' type='submit' style={{ height: '50px' }}>
									Generate
								</button>
							</div>
					
					</div>
				</div>)
				}
				
			</form>
		</div>
	);
};

export default NotesGenerator;

