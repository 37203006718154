import { toast } from 'react-toastify';
import api from './api';

export const getAllLessonPlan = async () => {
	try {
		// toast.loading('Fetching lesson plans...');
		const response = await api.get(`${import.meta.env.VITE_API_URL}/api/lesson-plan`);
		toast.dismiss();
		// toast.success('Fetched all lesson plans!');
		// console.log('getAllLessonPlan', response?.data);
		return response?.data?.data?.reverse();
	} catch (error) {
		console.error('Error while getting all lesson plans', error);
		toast.dismiss();
		toast.error('Error in getting lesson plans');
	}
};

export const getAllAssignment = async () => {
	try {
		// toast.loading('Fetching Assignments...');
		const response = await api.get(`${import.meta.env.VITE_API_URL}/api/assignment`);
		toast.dismiss();
		// toast.success('Fetched all assignements!');
		// console.log('getAllAssignment', response?.data);
		return response?.data?.data?.reverse();
	} catch (error) {
		console.error('Error while getting all assignments', error);
		toast.dismiss();
		toast.error('Error in getting assignments');
	}
};

export const deleteAssignment = async (id: string) => {
	try {
		const response = await api.delete(`${import.meta.env.VITE_API_URL}/api/assignment/${id}`);
		// toast.success('Deleted 1 assignment!');
	
		return response?.data;
	} catch (error: any) {
		// console.log('Error in Deleting assignment', error?.response?.data?.message);
		// console.log(error);
		toast.error('Error in Deleting assignment');
		return '';
	}
};


export const deleteLessonPlan = async (id: string) => {
	try {
		const response = await api.delete(`${import.meta.env.VITE_API_URL}/api/lesson-plan/${id}`);
		toast.success('Deleted 1 lesson plan!');
		return response?.data;
	} catch (error: any) {
		// console.log('Error in Deleting lesson plan', error?.response?.data?.message);
		// console.log(error);
		toast.error('Error in Deleting lesson plan');
		return '';
	}
};

export const getAllQuiz = async () => {
	try {
		// toast.loading('Fetching all quiz...');
		const response = await api.get(`${import.meta.env.VITE_API_URL}/api/quiz`);
		toast.dismiss();
		// toast.success('Fetched all quizes!');
		// console.log(response?.data?.data);
		return response?.data?.data?.reverse();
	} catch (error) {
		// console.log('Error while getting all quizes', error);
		toast.dismiss();
		toast.error('Error in getting all quizes');
		return [];
	}
};

export const deleteQuiz = async (id: string) => {
	try {
		// toast.loading('Deleting this quiz!');
		const response = await api.delete(`${import.meta.env.VITE_API_URL}/api/quiz/${id}`);
		toast.dismiss();
		toast.success('Deleted 1 quiz!');
		return response?.data;
	} catch (error: any) {
		// console.log('Error in Deleting quiz', error?.response?.data?.message);
		// console.log(error);
		toast.dismiss();
		toast.error('Error in Deleting quiz');
		return '';
	}
};
export const getAllNotes = async () => {
	try {
		// toast.loading('Fetching all classnotes...');
		const response = await api.get(`${import.meta.env.VITE_API_URL}/api/notes`);
		toast.dismiss();
		// toast.success('Fetched classnotes!');
		// console.log(response?.data?.data);
		return response?.data?.data?.reverse();
	} catch (error) {
		// console.log('Error while getting classnotes', error);
		toast.dismiss();
		toast.error('Error in getting classnotes');
		return [];
	}
};

export const deleteNotes = async (id: string) => {
	// console.log('delete notes for ', id);
	try {
		// toast.loading('Deleting this notes!');
		const response = await api.delete(`${import.meta.env.VITE_API_URL}/api/notes/${id}`);
		toast.dismiss();
		toast.success('Deleted 1 notes!');
		return response?.data;
	} catch (error: any) {
		// console.log('Error in Deleting notes', error?.response?.data?.message);
		// console.log(error);
		toast.dismiss();
		toast.error('Error in Deleting notes');
		return '';
	}
};

export const getAllTestPaper = async () => {
	try {
		// toast.loading('Fetching test papers...');
		const response = await api.get(`${import.meta.env.VITE_API_URL}/api/test-paper`);
	
		toast.dismiss();
		// toast.success('Fetched all test papers!');
	
		return response?.data?.data?.reverse();
	} catch (error) {
		console.error('Error while getting all test papers', error);
		toast.dismiss();
		toast.error('Error in getting test papers');
	}
};

export const deleteTestPaper = async (id: string) => {
	try {
		const response = await api.delete(`${import.meta.env.VITE_API_URL}/api/test-paper/${id}`);
		toast.success('Deleted 1 test paper!');
	
		return response?.data;
	} catch (error: any) {
		// console.log('Error in Deleting assignment', error?.response?.data?.message);
		// console.log(error);
		toast.error('Error in Deleting test paper');
		return '';
	}
};



