import React, { useState, useEffect } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';

function MessageComponent() {
  const [messageIndex, setMessageIndex] = useState(0);
  const [progress, setProgress] = useState(0);

  const responseMessages = [
    "EduAssist AI is your co-pilot in creating exceptional teaching content...",
    "Crafting engaging lessons with EduAssist AI for your students...",
    "Empower your teaching with EduAssist AI's content generation...",
    "EduAssist AI is your teaching partner, helping you shine in the classroom...",
    "Designing interactive lessons with EduAssist AI to inspire your students...",
    "EduAssist AI - your secret weapon for delivering top-notch education...",
    "Elevating your teaching game one lesson at a time...",
    "With EduAssist AI, you're on the cutting edge of educational innovation...",
    "Your students' success is the mission; EduAssist AI is your ally...",
    "Teach smarter, not harder, with the support of EduAssist AI...",
    "EduAssist AI: Pioneering the future of teaching and learning...",
    "Unlock the potential of EduAssist AI for a brighter classroom experience...",
  ];
  useEffect(() => {
    const duration = 3000; // 3 seconds per message
    const interval = 100; // Update interval in milliseconds
    const steps = duration / interval;
    let currentStep = 0;

    const progressInterval = setInterval(() => {
      currentStep++;
      const newProgress = (currentStep / steps) * 125;
      setProgress(newProgress);

      if (currentStep >= steps) {
        clearInterval(progressInterval);
        setMessageIndex((prevIndex) => (prevIndex + 1) % responseMessages.length); // Move to the next message
        setProgress(0); // Reset progress for the next message
      }
    }, interval);

    return () => {
      setProgress(0); // Reset progress for the next message
      clearInterval(progressInterval);
    };
  }, [messageIndex]);

  return (
    <div>
      <span className='fs-5 text-muted'>{responseMessages[messageIndex]}</span>
      <ProgressBar animated now={progress} />
    </div>
  );
}

export default MessageComponent;
