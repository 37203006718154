import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Form, InputGroup, Button, Spinner } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';

import { authSignup, fetchGoogleUser } from '@app/utils/oidc-providers';
import { setAuthentication } from '@app/store/reducers/auth';
import { setUserInfo } from '@app/store/reducers/userInfo';
import { setWindowClass } from '@app/utils/helpers';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock, faSignature, faMobile,faEyeSlash,faEye } from '@fortawesome/free-solid-svg-icons';
import logo from '@image/logo.png';
import Hero from '@app/components/hero/Hero';
// import HCaptcha from 'react-hcaptcha'; // Import hCaptcha

const RegistrationForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isAuthLoading, setAuthLoading] = useState(false);
  const [isGoogleAuthLoading, setGoogleAuthLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<string>('91');
  const [showPassword, setShowPassword] = useState(false);
  // const [captchaValue, setCaptchaValue] = useState<string | null>(null);

  const register = async (email: string, password: string, phone: string, name: string) => {
    try {
      setAuthLoading(true);
      const response = await authSignup(name, email, phone, password);
      dispatch(setAuthentication(response as any));
      dispatch(setUserInfo(response as any));
      setAuthLoading(false);
  
  
      if (response as any) {
        navigate('/login');
      }
    } catch (error) {
      console.error(error);
      toast.error('User creation Failed');
      setAuthLoading(false); 
    }
  };
  
  

  const registerByGoogle = async (credentialResponse: any) => {
    try {
      console.log('Register by Google ');
      setGoogleAuthLoading(true);
      setAuthLoading(true);
      const response = await fetchGoogleUser(credentialResponse.credential);
      console.log(response);
      dispatch(setAuthentication(response as any));
      dispatch(setUserInfo(response as any));
      setAuthLoading(false);
      setGoogleAuthLoading(false);
    } catch (error: any) {
      setAuthLoading(false);
      setGoogleAuthLoading(false);
      toast.error(error.message || 'Failed');
    }
  };

  useEffect(() => {
    setWindowClass('hold-transition login-page');
  }, []);

  const { handleChange, values, handleSubmit, touched, errors } = useFormik({
    initialValues: {
      email: '',
      password: '',
      passwordRetype: '',
      phone: '',
      name: ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
      email: Yup.string().email('Invalid email address').required('Required'),
      password: Yup.string()
        .min(5, 'Must be 5 characters or more')
        .max(30, 'Must be 30 characters or less')
        .required('Required'),
      passwordRetype: Yup.string()
        .min(5, 'Must be 5 characters or more')
        .max(30, 'Must be 30 characters or less')
        .oneOf([Yup.ref('password')], 'Passwords must match')
        .required('Required')
    }),

    onSubmit: (values) => {
      register(values.email, values.password, phoneNumber, values.name);
    }
  });

  return (
    <div className='container'>
     
			<Link to="/" style={{ textDecoration: 'none' }}>
    <img
        src={logo}
        alt='logo'
        height='80vh'
        style={{ position: 'absolute', top: 10, left: 10, cursor: 'pointer' }}
        className='d-none d-lg-block'
    />
</Link>
      <div className='row d-flex justify-content-center'>
        <div className='mr-n3' style={{ width: '400px' }}>
          <div className='login-box w-100'>
            <div
              className={`border-0 ${
                window.innerWidth < 576
                  ? 'p-0 overflow-hidden'
                  : 'rounded rounded-end-0 shadow card card-primary card-outline'
              }`}
            >
              <div className='card-header text-center d-lg-none d-md-block justify-content-center'>
                <img src={logo} alt='logo' height='80vh' />
              </div>
              <div className='card-body'>
                <h5 className='login-box-msg text-main px-0'>Empowering Teachers, Inspiring Students.</h5>

                <div className='social-auth-links p-0 mt-4 mb-3 col-12 d-flex justify-content-center'>
                  <GoogleLogin
                    onSuccess={async (credentialResponse) => {
                      console.log('SignUp Started');
                      registerByGoogle(credentialResponse);
                    }}
                    onError={() => {
                      console.log('SignUp Failed');
                      toast.error('SignUp Failed');
                    }}
                  />
                </div>
                <div className='col-12'>
                  <div className='my-2 d-flex justify-content-center'>Or</div>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className='mx-2'>
                    <div className='mb-3'>
                      <InputGroup className='mb-3'>
                        <Form.Control
                          className='p-3'
                          id='name'
                          name='name'
                          type='name'
                          placeholder='Name'
                          onChange={handleChange}
                          value={values.name}
                          isInvalid={touched.name && !!errors.name}
                          style={{ outline: '1px solid #ced4da', boxShadow: 'none' }} 
                        />
                        {touched.name && errors.name ? (
                          <Form.Control.Feedback type='invalid'>{errors.name}</Form.Control.Feedback>
                        ) : (
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faSignature} />
                          </InputGroup.Text>
                        )}
                      </InputGroup>
                    </div>
                    <div className='mb-3'>
                      <InputGroup className='mb-3'>
                        <Form.Control
                          className='p-3'
                          id='email'
                          name='email'
                          type='email'
                          placeholder='Email'
                          onChange={handleChange}
                          value={values.email}
                          isInvalid={touched.email && !!errors.email}
                          style={{ outline: '1px solid #ced4da', boxShadow: 'none' }} 
                        />
                        {touched.email && errors.email ? (
                          <Form.Control.Feedback type='invalid'>{errors.email}</Form.Control.Feedback>
                        ) : (
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faEnvelope} />
                          </InputGroup.Text>
                        )}
                      </InputGroup>
                    </div>
                    <div className='mb-3 d-flex'>
                      <PhoneInput
                        prefix='+'
                        placeholder='Mobile Number'
                        containerClass=''
                        inputClass='w-100 border rounded-end-0'
                        value={phoneNumber}
                        onChange={(value: string) => {
                          setPhoneNumber('+' + value);
                        }}
                        isValid={phoneNumber.length > 1}
                      />
                      <InputGroup.Text className='border border-start-0 rounded-start-0'>
                        <FontAwesomeIcon icon={faMobile} />
                      </InputGroup.Text>
                    </div>
                    <div className='mb-3'>
											<div className='position-relative'>
												<Form.Control
													className='p-3' 
													id='password'
													name='password'
													type={showPassword ? 'text' : 'password'} 
													placeholder='Password'
													onChange={handleChange}
													value={values.password}
													isValid={touched.password && !errors.password}
													isInvalid={touched.password && !!errors.password}
													style={{ outline: '1px solid #ced4da', boxShadow: 'none' }} 
												/>
												<FontAwesomeIcon
													icon={showPassword ? faEyeSlash : faEye}
													style={{
														position: 'absolute',
														top: '50%',
														right: '10px',
														transform: 'translateY(-50%)',
														cursor: 'pointer'
													}}
													onClick={() => setShowPassword(!showPassword)}
												/>
												{/* {touched.password && errors.password && (
												<Form.Control.Feedback type='invalid'>{errors.password}</Form.Control.Feedback>
											)} */}
											</div>
											{touched.password && errors.password && <div className='text-danger text-sm'>{errors.password}</div>}
										</div>
                    <div className='mb-3'>
                    <div className='position-relative'>
                      {/* <InputGroup className='mb-3'> */}
                        <Form.Control
                          className='p-3'
                          id='passwordRetype'
                          name='passwordRetype'
                          type={showPassword ? 'text' : 'password'} 
                          placeholder='Confirm Password'
                          onChange={handleChange}
                          value={values.passwordRetype}
                          isValid={touched.passwordRetype && !errors.passwordRetype}
                          isInvalid={touched.passwordRetype && !!errors.passwordRetype}
                          style={{ outline: '1px solid #ced4da', boxShadow: 'none' }} 
                        />
                        <FontAwesomeIcon
													icon={showPassword ? faEyeSlash : faEye}
													style={{
														position: 'absolute',
														top: '50%',
														right: '10px',
														transform: 'translateY(-50%)',
														cursor: 'pointer'
													}}
													onClick={() => setShowPassword(!showPassword)}
												/>
                        {/* {touched.passwordRetype && errors.passwordRetype ? (
                          <Form.Control.Feedback type='invalid'>{errors.passwordRetype}</Form.Control.Feedback>
                        ) : (
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faLock} />
                          </InputGroup.Text>
                        )} */}

                      {/* </InputGroup> */}
                      </div>
                      {touched.passwordRetype && errors.passwordRetype && <div className='text-danger text-sm'>{errors.passwordRetype}</div>}
                    </div>
                    <div className='row'>
                      <div className='col-12'>
                    
                        {/* <HCaptcha
                          sitekey="97bfb377-3aa5-4371-8bca-f6cb64bd5e00"
                          onVerify={(value) => {
                            setCaptchaValue(value);
                          }}
                        /> */}
                        <Button
                          // disabled={!captchaValue || isAuthLoading}
                          className='w-100 btn-purple'
                          type='submit'
                        >
                          {isAuthLoading ? (
                            <>
                              <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                              <span className='visually-hidden'> Loading...</span>
                            </>
                          ) : (
                            <span style={{ height: '100px' }}>Sign Up</span>
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                </form>
                <div className='col mt-4 text-center justify-content-center'>
                  <Link to='/login' className='text-center'>
                    I already have an account
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-4 d-none d-lg-block ml-n2'>{<Hero height={567} />}</div>
      </div>
    </div>
  );
};

export default RegistrationForm;
