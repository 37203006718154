import { ContentHeader } from '@app/components';
import { SolutionModel } from '@app/components/quiz-model/quiz-model';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { bigScreenCheck, getQuizSessionData } from '@app/utils/helpers';
import ExportToPDF from '@app/components/export/PdfExport';
import ExportToDocx from '@app/components/export/DocsExport';
import ExportButton from '@app/components/export/ExportButton';
import QuizAnswerPdfExport from '@app/components/export/quiz/QuizAnswerPdfExport';

const QuizSolution = () => {
	const navigate = useNavigate();
	const quizData = getQuizSessionData();
	const bigScreen = bigScreenCheck();

	return (
		<div className=''>
			<ContentHeader title={'Quiz Solution'} />

			<div className={bigScreen ? 'mx-5': ''}>
				<div className='d-flex justify-content-end mt-n5'>
					<ExportButton
						docxChild={
							<ExportToDocx
								child={
									<div className='border rounded my-4'>
										{quizData?.questions?.map((data: any, index: number) => (
											<div className='p-2 mx-3 fs-5' key={index}>
												<div>
													{index + 1}. {data?.question}
												</div>
												<div>
													Ans:{' '}
													{data?.options[data?.correctAnswer - 1] || data?.correctAnswer?.length > 1
														? data?.correctAnswer.map((answer: string) => {
																return answer + ', ';
														  })
														: data?.correctAnswer}
												</div>
											</div>
										))}
									</div>
								}
								fileName={'quiz-solution'}
								innerClass={'d-flex justify-content-center'}
							/>
						}
						pdfChild={
							<QuizAnswerPdfExport
							
								fileName={'quiz-solution'}
								innerClass={'d-flex justify-content-center'}
								jsonData={quizData}
							/>
						}
					/>
				</div>

				<div className='border rounded my-4'>
					{quizData?.questions?.map((data: any, index: number) => (
						<div className='p-2 mx-3 fs-5' key={index}>
							<div>
								{index + 1}. {data?.question}
							</div>
							<div>
								Ans:{' '}
								{data?.options[data?.correctAnswer - 1] || data?.correctAnswer?.length > 1
									? data?.correctAnswer.map((answer: string) => {
											return answer + ', ';
									  })
									: data?.correctAnswer}
							</div>
						</div>
					))}
				</div>
				<div className='d-flex justify-content-end'>
					<button className='btn-purple px-5' onClick={() => navigate('/quiz-questions')} style={{ height: '50px' }}>
						View Questions
					</button>
				</div>
			</div>
		</div>
	);
};

export default QuizSolution;
