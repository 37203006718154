import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Form, InputGroup, Spinner, Button } from 'react-bootstrap';
import { ContentHeader } from '@components';
import { quizGenerator } from '@app/services/generator';
import { useDispatch, useSelector } from 'react-redux';
import { setQuiz } from '@app/store/reducers/quiz';
import TimeProgressBar from '@app/components/progressBar/TimeProgressBar';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { bigScreenCheck, getQuizFormData, getQuizSessionData } from '@app/utils/helpers';

const Quiz = () => {
	const [isGenerating, setIsGenerating] = useState(false);
	const [show, setShow] = useState(true);
	const bigScreen = bigScreenCheck();

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const queryParameters = new URLSearchParams(location.search);
	const id = queryParameters.get('id') || '0';

	const quizData = getQuizSessionData();
	const [quizForm, setQuizForm] = useState(getQuizFormData());

	useEffect(() => {
		if (quizData === undefined) {
			setShow(true);
		} else setShow(false);
	}, [quizData]);

	const handleNewQuiz = () => {
		const newForm = {
			grade: '',
			subject: '',
			topic: '',
			quizType: '',
			questionsCount: '',
			difficultyLevel: ''
		};
		localStorage.setItem('quiz-form', JSON.stringify(newForm));
		setQuizForm(newForm);
		setIsGenerating(false);
		location.reload();
	};

	const { handleChange, values, handleSubmit, touched, errors } = useFormik({
		initialValues: quizForm,
		validationSchema: Yup.object({
			grade: Yup.string().required(),
			subject: Yup.string().required(),
			topic: Yup.string().required(),
			quizType: Yup.string(),
			questionsCount: Yup.string(),
			difficultyLevel: Yup.string()
		}),
		onSubmit: async (values) => {
			if (values.quizType === '' || values.questionsCount === '' || values.difficultyLevel === '') {
			  toast.error('Please select all fields');
			  return;
			}
		  
			setIsGenerating(true);
			try {
			  const response = await quizGenerator(values);
			  if (response.status === 201) {
				dispatch(setQuiz(response.data));
				sessionStorage.setItem('quizData', JSON.stringify(response.data));
				toast.success('Quiz successfully created');
				setTimeout(() => {
				  navigate('/quiz-questions');
				}, 3000);
			  } else {
				setIsGenerating(false);
				// toast.error('Failed to create quiz. Please try again.');
			  }
			} catch (error) {
			  setIsGenerating(false);
			//   toast.error('Failed to create quiz. Please try again.');
			}
		  }
		  
	});

	const handleLastQuiz = (e: any) => {
		navigate('/quiz-history');
		setQuizForm(quizForm);
	};

	return (
		<div>
			<ContentHeader title={'Quiz Generator'} />
			{/* <div className={`d-flex justify-content-end ${bigScreen && 'me-5 my-n4'}`}>
				<button className='btn-outline-purple px-5' onClick={handleNewQuiz} style={{ height: '50px' }}>
					New Quiz
				</button>
			</div> */}
			<form onSubmit={handleSubmit}>
				{isGenerating ? (
					<TimeProgressBar />
				) : (
					<div className={bigScreen ? 'mx-5' : 'mx-1'}>
						<div>
							<div className={`fs-5 ${bigScreen ? 'py-3' : 'py-1'}`}>Grade</div>
							<Form.Control
								className='p-4'
								id='grade'
								name='grade'
								type='text'
								onChange={handleChange}
								value={values.grade}
								isValid={touched.grade && !errors.grade}
								isInvalid={touched.grade && !!errors.grade}
							/>
							{/* {touched.grade && errors.grade ? (
							<Form.Control.Feedback type='invalid'>{errors.grade}</Form.Control.Feedback>
						) : null} */}
						</div>
						<div>
							<div className={`fs-5 ${bigScreen ? 'py-3' : 'py-1'}`}>Subject</div>
							<Form.Control
								className='p-4'
								id='subject'
								name='subject'
								type='text'
								onChange={handleChange}
								value={values.subject}
								isValid={touched.subject && !errors.subject}
								isInvalid={touched.subject && !!errors.subject}
							/>
							{/* {touched.subject && errors.subject ? (
							<Form.Control.Feedback type='invalid'>{errors.subject}</Form.Control.Feedback>
						) : null} */}
						</div>
						<div>
							<div className={`fs-5 ${bigScreen ? 'py-3' : 'py-1'}`}>Topic</div>
							<Form.Control
								className='p-4'
								id='topic'
								name='topic'
								type='text'
								onChange={handleChange}
								value={values.topic}
								isValid={touched.topic && !errors.topic}
								isInvalid={touched.topic && !!errors.topic}
							/>
							{/* {touched.topic && errors.topic ? (
							<Form.Control.Feedback type='invalid'>{errors.topic}</Form.Control.Feedback>
						) : null} */}
						</div>
						<div>
							<div className={`fs-5 ${bigScreen ? 'py-3' : 'py-1'}`}>Type of quiz</div>
							<div className='form-group'>
								<select
									className='form-select form-select-lg'
									id='quizType'
									name='quizType'
									value={values.quizType}
									onChange={handleChange}
								>
									<option value='' className='text-muted'>
										- Select -
									</option>
									<option value='MCQ with Single Correct'>MCQ with Single Correct</option>
									<option value='MCQ with Multiple Correct'>MCQ with Multiple Correct</option>
									<option value='Fill in the blanks'>Fill in the blanks</option>
									<option value='Matching'>Matching</option>
									<option value='True - False'>True - False</option>
									<option value='Long Answer Type'>Long Answer Type</option>
								</select>
							</div>
						</div>
						<div>
							<div className={`fs-5 ${bigScreen ? 'py-3' : 'py-1'}`}>Number of Questions</div>
							<div className='form-group'>
								<select
									className='form-select form-select-lg'
									id='questionsCount'
									name='questionsCount'
									value={values.questionsCount}
									onChange={handleChange}
								>
									<option value='' className='text-muted'>
										- Select -
									</option>
									<option value='5'>5 questions</option>
									<option value='10'>10 questions</option>
									<option value='20'>20 questions</option>
								</select>
							</div>
						</div>
						<div>
							<div className={`fs-5 ${bigScreen ? 'py-3' : 'py-1'}`}>Difficulty Level</div>
							<div className='form-group'>
								<select
									className='form-select form-select-lg'
									id='difficultyLevel'
									name='difficultyLevel'
									value={values.difficultyLevel}
									onChange={handleChange}
								>
									<option value='' className='text-muted'>
										- Select -
									</option>
									<option value='Easy'>Easy</option>
									<option value='Medium'>Medium</option>
									<option value='Hard'>Hard</option>
								</select>
							</div>
						</div>
						<div>
							<div className={`d-flex justify-content-between my-4`}>
								<button
									className={`btn btn-outline-secondary px-5`}
									onClick={(e) => handleLastQuiz(e)}
									style={{ height: '50px' }}
								>
									Quiz History
								</button>
								<button className='btn-purple px-5' type='submit' style={{ height: '50px' }}>
									Generate
								</button>
							</div>
						</div>
					</div>
				)}
			</form>
		</div>
	);
};

export default Quiz;
