import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { MenuItem } from '@components';
import { PfImage } from '@profabric/react-components';
import styled from 'styled-components';
import { SidebarSearch } from '@app/components/sidebar-search/SidebarSearch';
// import i18n from '@app/utils/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCalendar,
	faPersonChalkboard,
	faFileLines,
	faPenToSquare,
	faCompassDrafting,
	faMicroscope
} from '@fortawesome/free-solid-svg-icons';
import DefaultProfile from '@image/default-profile.png';
import Logo from '@image/logo.png';

export interface IMenuItem {
	name: string;
	icon: ReactNode;
	path?: string;
	children?: Array<IMenuItem>;
}

export const MENU: IMenuItem[] = [
	{
		name: 'Lesson Plan Generator',
		icon: <FontAwesomeIcon icon={faPersonChalkboard} className='nav-icon' />,
		path: '/lesson-history'
	},
	{
		name: 'Quiz Generator',
		icon: <FontAwesomeIcon icon={faFileLines} className='nav-icon' />,
		path: '/quiz-history'
	},
	{
		name: 'Assignment Generator',
		icon: <FontAwesomeIcon icon={faCompassDrafting} className='nav-icon' />,
		path: '/assignment-history'
	},
	// {
	// 	name: 'Academic Calendar',
	// 	icon: <FontAwesomeIcon icon={faCalendar} className='nav-icon' />,
	// 	path: '/academic'
	// },
	{
		name: 'Class Notes Generator',
		icon: <FontAwesomeIcon icon={faPenToSquare} className='nav-icon' />,
		path: '/notes-history'
	},
	{
		name: 'Test Paper Generator',
		icon: <FontAwesomeIcon icon={faCompassDrafting} className='nav-icon' />,
		path: '/test-paper-history'
	}

	// {
	//   name: "Assignment Generator",
	//   icon: "far fa-caret-square-down nav-icon",
	//   children: [
	//     {
	//       name: "Academic Calendar Generator",
	//       icon: "fas fa-hammer nav-icon",
	//       path: "/sub-menu-1",
	//     },

	//     {
	//       name: "blank",
	//       icon: "fas fa-cogs nav-icon",
	//       path: "/sub-menu-2",
	//     },
	//   ],
	// },
];

const StyledBrandImage = styled(PfImage)`
	float: left;
	line-height: 0.8;
	margin: -1px 8px 0 6px;
	opacity: 0.8;
	--pf-box-shadow: inset 33px 0 #fff, 0 6px 10px #5e5e5e;
`;

const StyledUserImage = styled(PfImage)`
	--pf-box-shadow: 0 3px 6px #00000029, 0 3px 6px #0000003b !important;
`;

const MenuSidebar = () => {
	const authentication = useSelector((state: any) => state.auth.authentication);
	const userInfo = useSelector((state: any) => state.user.userInfo);
	const sidebarSkin = useSelector((state: any) => state.ui.sidebarSkin);
	const menuItemFlat = useSelector((state: any) => state.ui.menuItemFlat);
	const menuChildIndent = useSelector((state: any) => state.ui.menuChildIndent);
	// console.log(userInfo.profilePictureUrl);

	return (
		<aside className={`main-sidebar elevation-4 ${sidebarSkin} bg-theme`} style={{ position: 'fixed' }}>
			<Link to='/' className='brand-link' style={{borderBottom:'2px solid #e7e0f6', width:'94%', marginLeft:'8px'}}>
				<StyledBrandImage src={Logo} alt='EduAssist Logo' height={33} style={{marginRight:'4px', marginLeft: '2px'}} rounded/>
				<span className='brand-text' style={{color:"#000000"}}>EduAssist</span>
			</Link>
			<div className='sidebar'>
				<div className='user-panel mt-3 pb-3 mb-3 d-flex' style={{borderBottom:'2px solid #e7e0f6'}}>
					<div className='image'>
						<StyledUserImage
							src={userInfo?.profilePictureUrl}
							fallbackSrc={DefaultProfile}
							alt='User'
							width={34}
							height={34}
							rounded
						/>
					</div>
					<div className='info'> 
						<Link to='/profile' className='d-block text-light'>
							{userInfo?.name && userInfo.name.charAt(0).toUpperCase() + userInfo.name.slice(1)}
						</Link>
					</div>
				</div>

				{/* <div className="form-inline">
          <SidebarSearch />
        </div> */}

				<nav className='mt-2' style={{ overflowX: 'hidden' }}>
					<ul
						className={`nav nav-pills nav-sidebar flex-column${menuItemFlat ? ' nav-flat' : ''}${
							menuChildIndent ? ' nav-child-indent' : ''
						}`}
						role='menu'
					>
						{MENU.map((menuItem: IMenuItem) => (
							<MenuItem key={menuItem.name + menuItem.path} menuItem={menuItem} />
						))}
					</ul>
				</nav>
			</div>
		</aside>
	);
};

export default MenuSidebar;
