import React, { useState } from 'react';
import {  useSelector } from 'react-redux';

import ProfileDataForm from './ProfileDataForm';
import ProfileAccountDataForm from './ProfileAccountDataForm';
import DefaultProfile from '@image/default-profile.png';
import styled from 'styled-components';
import { PfImage } from '@profabric/react-components';

const StyledUserImage = styled(PfImage)`
  --pf-box-shadow: 0 3px 6px #00000029, 0 3px 6px #0000003b !important;
`;

const Profile = () => {
  const userData = useSelector((state: any) => state?.user?.userInfo);
  const [activeForm, setActiveForm] = useState('profile'); 

  const handleProfileClick = () => {
    setActiveForm('profile');
  };

  const handleAccountSettingsClick = () => {
    setActiveForm('account');
  };

  return (
    <div style={{ padding: '20px' }}>
      <div className="image-circle text-center">
        <StyledUserImage
          src={userData?.profilePictureUrl}
          fallbackSrc={DefaultProfile}
          alt="User"
          width={150}
          height={150}
          rounded
        />
        <p className="theme" style={{ fontSize: '28px', fontWeight: 'bold', marginTop: '10px' }}>
          {userData.name}
        </p>
      </div>

      <div style={{ display: 'flex' }}>
				<p
					onClick={handleProfileClick}
					className={`theme ${activeForm === 'profile' ? 'active' : ''}`}
					style={{
						fontSize: '24px',
						fontWeight: 'bold',
						marginTop: '10px',
						cursor: 'pointer',
						marginRight: '30px',
					}}
				>
					<span style={{
						borderBottom: activeForm === 'profile' ? '3px solid #9a5ac7' : 'none',
						display: 'inline-block',
						padding: '2px',
						borderRadius: '5px',
					
					}}>
						Profile
					</span>
				</p>

					<p
						onClick={handleAccountSettingsClick}
						className={`theme ${activeForm === 'account' ? 'active' : ''}`}
						style={{
							fontSize: '24px',
							fontWeight: 'bold',
							marginTop: '10px',
							cursor: 'pointer',
							marginRight: '30px',
						}}
					>
						<span style={{
							borderBottom: activeForm === 'account' ? '4px solid #9a5ac7' : 'none',
							display: 'inline-block',
							padding: '2px',
							borderRadius: '5px',
						
						}}>
							Account Settings
						</span>
			</p>

      </div>

      {activeForm === 'profile' && <ProfileDataForm />}
      {activeForm === 'account' && <ProfileAccountDataForm />}
    </div>
  );
};

export default Profile;
