import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAllAssignment } from '@app/services/databaseApi';
import { ContentHeader } from '@app/components';
import MuiTable from '@app/components/basic-table/MuiTable';
import { bigScreenCheck } from '@app/utils/helpers';
import { useDispatch } from 'react-redux';
import { setAssignment } from '@app/store/reducers/assignment';
import dayjs from 'dayjs'; // Import dayjs library

interface Data {
	id: string;
	time: string;
	grade: string;
	subject: string;
	assignmentType: string;
	topic: string;
}

interface Column {
	id: 'time' | 'grade' | 'subject' | 'assignmentType' | 'topic' ;
	label: string;
	minWidth?: number;
	align?: string;
	format?: (value: string) => string; // Adjusted format function to accept string
}

function createData(
	id: string,
	time: string,
	grade: string,
	subject: string,
	assignmentType: string,
	topic: string,
): Data {
	return { id, time, grade, subject, assignmentType, topic};
}

const PreviousAssignment = () => {
	const bigScreen = bigScreenCheck();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const buttonStyle = {
		height: '50px',
		marginLeft: bigScreen ? '59vh' : '0px', // margin on the right
		marginRight: '10px',
		padding: '0 10px', // minimum padding
		width: '175px', // fixed width
		alignSelf: 'flex-end', // align to the right within the flex container
	  };

	const [rows, setRows] = useState<Data[]>([]); // Corrected type to Data[]

	const formatDate = (dateTime: string): string => {
		return dayjs(dateTime).format('DD/MM/YYYY hh:mm A');
	};
	

	const columns: readonly Column[] = [
		{ id: 'time', label: 'Time', minWidth: 150, format: formatDate },
		{
			id: 'grade',
			label: 'Grade',
			align: 'center'
		},
		{
			id: 'subject',
			label: 'Subject',
			align: 'left',
			minWidth: 100
		},
		{
			id: 'topic',
			label: 'Topic',
			align: 'left'
		},
		{
			id: 'assignmentType',
			label: 'Assignment Type',
			align: 'left'
		}
	];
	

	useEffect(() => {
		fetchLessonHistory();
	}, []);

	const fetchLessonHistory = async () => {
		try {
			const assignmentHistory = await getAllAssignment();
			dispatch(setAssignment(assignmentHistory));
			setRows(assignmentHistory.map((item: any) => createData(
				item?._id || 'invalid id',
				formatDate(item?.updatedAt) || 'unavailable',
				item?.gradeLevel || 'unavailable',
				item?.subject || 'unavailable',
				item?.unitChapter || 'unavailable',
				item?.assignmentType || 'unavailable'
			)));
		} catch (error) {
			console.error('Error fetching assignment history:', error);
		}
	};

	return (
		<div>
			<div style={{display: "flex"}}>
			<ContentHeader title={'Assignment'} />
			<div className={`d-flex justify-content-end ${bigScreen && 'me-5'} mb-3`}>
				<button className='btn-outline-purple px-7'onClick={() => navigate('/assignment')} style={buttonStyle}>
					Generate Assignment	
				</button>
			</div>
			</div>
			<div className={bigScreen ? 'mx-5' : ''}>
				<MuiTable columns={columns} rows={rows} route={'assignment-plan'} />
			</div>
		</div>
	);
};

export default PreviousAssignment;
