import { useRef } from 'react';
import jsPDF from 'jspdf';

interface QuizQuestion {
  question: string;
  options: string[];
  correctAnswer: string[]; // Array of correct answer(s) as option letters
}

interface ExportQuizToPDFProps {
  fileName: string;
  innerClass: string;
  jsonData: {
    grade: string;
    subject: string;
    unitChapter: string;
    quizType: string;
    difficultyLevel: string;
    numberOfQuestions: string;
    questions: QuizQuestion[];
  };
}

function getCorrectAnswerText(question: QuizQuestion): string {
  const correctAnswers = question.correctAnswer;

  if (correctAnswers.length === 0) {
    return 'No correct answer specified';
  }

  return correctAnswers.join(', ');
}

function QuizAnswerPdfExport({ fileName, innerClass, jsonData }: ExportQuizToPDFProps) {
  const handleExportClick = () => {
    const doc = new jsPDF();

    // Define content
    const content = `
      Quiz Solution\n\n
      Grade: ${jsonData.grade}\n
      Subject: ${jsonData.subject}\n
      Unit Chapter: ${jsonData.unitChapter}\n
      Quiz Type: ${jsonData.quizType}\n
      Difficulty Level: ${jsonData.difficultyLevel}\n
      Number of Questions: ${jsonData.numberOfQuestions}\n\n
      Quiz Questions & Solutions:\n\n
      ${jsonData.questions.map(generateQuestionBox).join('\n\n')}
    `;

    // Set font size
    doc.setFontSize(12);

    // Split content into lines
    const lines = doc.splitTextToSize(content, doc.internal.pageSize.width - 20);

    // Add content to document
    doc.text(lines, 10, 10);

    // Save document
    doc.save(`${fileName}.pdf`);
  };

  // Function to generate question box
  const generateQuestionBox = (question: QuizQuestion, index: number) => {
    const questionContent = `Q${index + 1}: ${question.question}\n\n
      Correct Answer: ${getCorrectAnswerText(question)}\n\n
      ${question.options.map((option, optionIndex) => `${String.fromCharCode(65 + optionIndex)}. ${option}`).join('\n')}
    `;
    return questionContent;
  };

  return (
    <div>
      <div className={innerClass}>
        <button
          onClick={handleExportClick}
          className='btn btn-primary btn-purple my-2'
          style={{ backgroundColor: '#a084dc', padding: '10px 22px' }}
        >
          Export as Pdf
        </button>
      </div>
    </div>
  );
}

export default QuizAnswerPdfExport;
