import { configureStore } from '@reduxjs/toolkit';

import { authSlice } from '@app/store/reducers/auth';
import { uiSlice } from '@app/store/reducers/ui';
import { userSlice } from '@app/store/reducers/userInfo';
import { quizSlice } from '@app/store/reducers/quiz';
import { lessonSlice } from '@app/store/reducers/lesson';
import { notesSlice } from '@app/store/reducers/notes';
import { assignmentSlice } from '@app/store/reducers/assignment';
import { testPaperSlice } from './reducers/testPaper';

// import {createLogger} from 'redux-logger';

const store = configureStore({
	reducer: {
		auth: authSlice.reducer,
		user: userSlice.reducer,
		ui: uiSlice.reducer,
		quiz: quizSlice.reducer,
		lesson: lessonSlice.reducer,
		notes: notesSlice.reducer,
		assignment : assignmentSlice.reducer,
		testPaper : testPaperSlice.reducer,
	}
	// middleware: (getDefaultMiddleware) => [
	//   ...getDefaultMiddleware().concat(createLogger())
	// ]
});

export default store;
