import { bigScreenCheck } from '@app/utils/helpers';
import { fontWeight } from 'html2canvas/dist/types/css/property-descriptors/font-weight';

const ContentHeader = ({title}: {title: string}) => {
  const bigScreen = bigScreenCheck();

  const sectionStyle = {
    fontFamily: 'Poppins, sans-serif',
    width: '600px',
    minWidth: '230px', // Set minimum width here
    marginLeft: bigScreen ? '36px' : '0px',
    marginTop: '14px'
  };
  return (
    <section style={sectionStyle}>
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="theme">
            <h1 style={{fontWeight: 600, fontSize:'24px'}}>{title}</h1>
          </div>
          <div className="col-sm-6">
            {/* <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li className="breadcrumb-item active">{title}</li>
            </ol> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContentHeader;
