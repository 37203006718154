interface TestPaperData {
    title: string;
    totalMarks: string;
    timeLimit: string;
    unitChapter: string;
    numberOfQuestions: string;
    questions: {
      questionType: string;
      question: string;
      options: string[];
      correctAnswer: string[];
    }[];
  }
  
  interface ExportTestPaperToPDFProps {
    fileName: string;
    innerClass: string;
    testPaperData: TestPaperData;
  }
  function TestPaperDocxExport({ fileName, innerClass, testPaperData }: ExportTestPaperToPDFProps) {
    const generateHintList = (hint: string[]) =>
      hint.map((hintItem, index) => `${index + 1}. ${hintItem}`).join('\n');
  
    const handleExportClick = () => {
      const docContent = `
      Test Paper Details\n\n
      Title: ${testPaperData.title}\n
      Total Marks: ${testPaperData.totalMarks}\n
      Time Limit: ${testPaperData.timeLimit}\n
      Unit Chapter: ${testPaperData.unitChapter}\n
      Number of Questions: ${testPaperData.numberOfQuestions}\n\n
      ${generateQuestionList(testPaperData.questions).join('\n\n')}
      `;
      const blob = new Blob([docContent], { type: 'application/msword' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName + '.doc';
      link.click();
    };

    const generateQuestionList = (questions: TestPaperData['questions']) => {
        return questions.map((questionItem, index) => {
          const optionsText = questionItem.options.length > 0 ? questionItem.options.map((option, optionIndex) => `${String.fromCharCode(65 + optionIndex)}. ${option}`).join('\n') : '';
          const correctAnswerText = questionItem.correctAnswer.join('\n');
    
          return `
            Question ${index + 1}\n\n
            Question Type: ${questionItem.questionType}\n
            Question: ${questionItem.question}\n\n
            Options:\n${optionsText}\n\n
            Correct Answer:\n${correctAnswerText}\n
          `;
        });
      };
  
    return (
      <div>
        <div className={innerClass}>
          <button
            onClick={handleExportClick}
            className='btn btn-primary btn-purple my-2'
            style={{ backgroundColor: '#a084dc', padding: '10px 22px' }}
          >
            Export as Doc
          </button>
        </div>
      </div>
    );
  }
  
  export default TestPaperDocxExport;