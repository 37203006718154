import { useRef } from 'react';
import jsPDF from 'jspdf';

interface ClassNotesProps {
  fileName: string;
  innerClass: string;
  jsonData: {
    subject: string;
    gradeLevel: string;
    unitChapter: string;
    summary: string;
    notes: string;
  };
}

function ClassNotesPdfExport({ fileName, innerClass, jsonData }: ClassNotesProps) {
  const handleExportClick = () => {
    const doc = new jsPDF('p', 'mm', 'a4'); // Specify A4 size

    // Sanitize the content to remove asterisks (*) and pound signs (#)
    const sanitizedSubject = sanitize(jsonData.subject);
    const sanitizedGradeLevel = sanitize(jsonData.gradeLevel);
    const sanitizedUnitChapter = sanitize(jsonData.unitChapter);
    const sanitizedSummary = sanitize(jsonData.summary);
    const sanitizedNotes = sanitize(jsonData.notes);

    // Define content
    let content = `Class Notes
      Subject: ${sanitizedSubject}
      Grade Level: ${sanitizedGradeLevel}
      Unit/Chapter: ${sanitizedUnitChapter}\n\n
      Summary:\n${sanitizedSummary}\n\n
      Notes:\n${sanitizedNotes}\n
    `;

    // Set font size
    doc.setFontSize(10);

    // Split content into lines
    const lines = doc.splitTextToSize(content, doc.internal.pageSize.width - 20);

    // Initialize y position
    let y = 5;
   

    // Loop through lines and add to document, handling page breaks
    lines.forEach((line:any) => {
      if (y + 5 > doc.internal.pageSize.height) {
        // Add new page if content exceeds page height
        doc.addPage();
        y = 10; // Reset y position for new page
        
      }
      doc.text(line,10,y ); // Align text to the left, 10 units from left margin
      y += 5; // Increment y position for next line
    });

    // Save document
    doc.save(`${fileName}.pdf`);
  };

  // Function to sanitize text by removing asterisks (*) and pound signs (#)
  const sanitize = (text: string) => {
    return text.replace(/[*#]/g, '');
  };

  return (
    <div>
      <div className={innerClass}>
        <button
          onClick={handleExportClick}
          className='btn btn-primary btn-purple my-2'
          style={{ backgroundColor: '#a084dc', padding: '8px 22px', fontSize: '15px' }}
        >
          Export as Pdf
        </button>
      </div>
    </div>
  );
}

export default ClassNotesPdfExport;
