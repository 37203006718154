import React from 'react';

export interface TestPaperData {
	title: string;
	totalMarks: string;
	timeLimit: string;
	unitChapter: string;
	numberOfQuestions: string;
	board: string;
	questions: {
		questionType: string;
		question: string;
		options: string[];
		correctAnswer: string[];
	}[];
}

const TestPaperCard = ({ data }: { data: TestPaperData }) => {
	return (
		<div style={{
			fontFamily: 'Poppins, sans-serif',
            
		}}>
		<div className=''>
			<div className='ml-2'>
				<div className='mt-2'>Test Paper Details:</div>
				<div className='text-muted'>Title: {data?.title}</div>
				<div className='text-muted'>Total Marks: {data?.totalMarks}</div>
				<div className='text-muted'>Time Limit: {data?.timeLimit}</div>
				<div className='text-muted'>Unit/Chapter: {data?.unitChapter}</div>
				<div className='text-muted'>Number of Questions: {data?.numberOfQuestions}</div>
				<div className='text-muted'>Board: {data?.board}</div>
			</div>
			<div className='ml-2'>
				<div className='mt-2'>Test Paper Questions:</div>
				{data?.questions &&
					data.questions.length > 0 &&
					data.questions.map((questionItem, questionIndex) => (
						<div key={questionIndex} className='mt-2'>
							{/* <div className="my-3">
              Question Type:
              <span className="ps-3 text-muted fs-5">{questionItem?.questionType}</span>
            </div> */}
							<div className='mt-2'>
								Question {questionIndex + 1}:<span className='text-muted'>{questionItem?.question}</span>
							</div>
							{/* <div className="my-3">
              Question:
              <span className="ps-3 text-muted fs-5">{questionItem?.question}</span>
            </div> */}
							{questionItem?.options?.length > 0 && (
								<div className='my-3'>
									Options:
									<ul>
										{questionItem?.options.map((option, optionIndex) => (
											<li key={optionIndex} className='text-muted'>
												{option}
											</li>
										))}
									</ul>
								</div>
							)}
							{questionItem?.correctAnswer?.length > 0 && (
								<div className='my-3'>
									Correct Answer:
									<ul>
										{questionItem?.correctAnswer?.map((answer, answerIndex) => (
											<li key={answerIndex} className='text-muted'>
												{answer}
											</li>
										))}
									</ul>
								</div>
							)}
						</div>
					))}
			</div>
		</div>
		</div>
	);
};

export default TestPaperCard;
