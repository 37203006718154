
import React, { useState } from 'react';
import { Form, InputGroup, Col, Row } from 'react-bootstrap';
import { setUserInfo } from '@app/store/reducers/userInfo';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';


const ProfileDataForm = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state: any) => state?.user?.userInfo);


  const [editedData, setEditedData] = useState({ ...userData });
  


  const handleSaveChanges = () => {
    dispatch(setUserInfo(editedData));
    localStorage.setItem('user', JSON.stringify(editedData));
    toast.success('Update is done.'); 
  };

  const handleFieldChange = (fieldName: string, value: string) => {
    setEditedData((prevData : any) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };
  console.log(editedData,"hi hi hi")
  return (
    
    <div className='box' style={{ padding: '20px' }}>
          {/* full name */}
          <div className='fs-5 py-2'>Full Name</div>
            <InputGroup className='mb-3'>
              <Form.Control
                className='p-4'
                type='text'
                disabled
                name='name'
                value={editedData.name}
                onChange={(e) => handleFieldChange('name', e.target.value)}
              />
            </InputGroup>
          {/* School name */}

          <div className='fs-5 py-2'>School Name</div>
          <InputGroup className='mb-3'>
            <Form.Control
              className='p-4'
              type='text'
              name='schoolName'
              value={editedData.schoolName}
              onChange={(e) => handleFieldChange('schoolName', e.target.value)}
            />
          </InputGroup>

          {/* Teaches in  */}
          <div className='fs-5 py-2'>Teaches in </div>
          <InputGroup className='mb-3'>
            <Form.Control
            placeholder='Enter the class name'
              className='p-4'
              type='text'
              name='gradeLevel'
              value={editedData.gradeLevel}
              onChange={(e) => handleFieldChange('gradeLevel', e.target.value)}
            />
        </InputGroup>

        {/* Subject */}
        <div className='fs-5 py-2'>Subject</div>
        <InputGroup className='mb-3'>
            <Form.Control
              className='p-4'
              type='text'
              name='subject'
              value={editedData.subject}
              onChange={(e) => handleFieldChange('subject', e.target.value)}
            />
          </InputGroup>

          {/* update button */}
          <div className='d-flex justify-content-end' style={{ marginTop: '10px' }} onClick={handleSaveChanges}>
            <button className='btn-purple px-5' style={{ height: '50px' }}>
                Update
          </button>
        </div>
    </div>

      
   
  );
};

export default ProfileDataForm;
