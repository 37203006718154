import { useRef } from 'react';
import jsPDF from 'jspdf';

interface AssignmentData {
  subject: string;
  assignmentType: string;
  unitChapter: string;
  gradeLevel: string;
  assignment: {
    question: string;
    instructions: string;
    hint: string[];
    hints: string[];
  };
}

interface ExportAssignmentToPDFProps {
  fileName: string;
  innerClass: string;
  assignmentData: AssignmentData;
}

function AssignmentPdfExport({ fileName, innerClass, assignmentData }: ExportAssignmentToPDFProps) {
  const handleExportClick = () => {
    const doc = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4',
    });

    const content = `
      Assignment Details\n\n
      Subject: ${sanitize(assignmentData.subject)}\n
      Assignment Type: ${sanitize(assignmentData.assignmentType)}\n
      Unit/Chapter: ${sanitize(assignmentData.unitChapter)}\n
      Grade Level: ${sanitize(assignmentData.gradeLevel)}\n\n
      Assignment Questions:\n\n
      Question:\n${sanitize(assignmentData.assignment.question)}\n\n
      Instructions:\n${sanitize(assignmentData.assignment.instructions)}\n\n
      Hints:\n${sanitize(formatHints(assignmentData.assignment.hints, assignmentData.assignment.hint))}\n
    `;

    doc.setFontSize(12);

    const lines = doc.splitTextToSize(content, doc.internal.pageSize.width - 20);

    doc.text(lines, 15, 15);

    doc.save(`${fileName}.pdf`);
  };

  const formatHints = (hints: string[] | undefined, hint: string[] | undefined) => {
    if (hints && hints.length > 0) {
      return hints.map((hint, index) => `${index + 1}. ${hint}`).join('\n');
    } else if (hint && hint.length > 0) {
      return hint.map((hint, index) => `${index + 1}. ${hint}`).join('\n');
    } else {
      return 'No hints available';
    }
  };

  const sanitize = (text: string) => {
    return text.replace(/[*#]/g, ''); // Remove asterisks (*) and pound signs (#)
  };

  return (
    <div>
      <div className={innerClass}>
        <button
          onClick={handleExportClick}
          className='btn btn-primary btn-purple my-2'
          style={{ backgroundColor: '#a084dc', padding: '10px 22px' }}
        >
          Export as PDF
        </button>
      </div>
    </div>
  );
}

export default AssignmentPdfExport;
