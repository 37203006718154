import api from './api';
import { toast } from 'react-toastify';

interface QuizData {
	subject: string;
	difficulty: string;
	quizType: string;
	unitChapter: string;
	gradeLevel: string;
	numberOfQuestions: number;
}

export const quizGenerator = async (value: any) => {
	const quizData: QuizData = {
		subject: value.subject,
		difficulty: value.difficultyLevel,
		quizType: value.quizType,
		unitChapter: value.topic,
		gradeLevel: value.grade,
		numberOfQuestions: parseInt(value.questionsCount, 10)
	};
	// console.log(quizData);

	try {
		const response = await api.post(`${import.meta.env.VITE_API_URL}/api/quiz`, quizData);
		return response;
	} catch (error:any) {
		console.log(error)
		let errorMessage = 'Failed to generate quiz!';
		if (error.response && error.response.data) {
		  const responseData = error.response.data;
		  if (Array.isArray(responseData.message)) {
			errorMessage = responseData.message.join(', '); 
		  } else if (typeof responseData.message === 'string') {
			errorMessage = responseData.message;
		  }
		}
		toast.error(errorMessage);
		return 'Error while generating quiz';
	}
};

export const lessonGenerator = async (value: any) => {
	const lessonData = {
		gradeLevel: value.grade,
		subject: value.subject,
		lecture: parseInt(value.lecture, 10),
		unitChapter: value.chapter,
		objectives: value.topic,
		duration: value.duration
	};
	// console.log(value);
	// console.log('url: ', import.meta.env.VITE_API_URL);
	try {
		const response = await api.post(`${import.meta.env.VITE_API_URL}/api/lesson-plan`, lessonData);
		return response;
	} catch (error:any) {
		
		console.log(error)
		let errorMessage = 'Failed to generate lessons!';
		if (error.response && error.response.data) {
		  const responseData = error.response.data;
		  if (Array.isArray(responseData.message)) {
			errorMessage = responseData.message.join(', '); 
		  } else if (typeof responseData.message === 'string') {
			errorMessage = responseData.message;
		  }
		}
		toast.error(errorMessage);
		return 'Error while generating lesson';
	}
};

export const assignmentGenerator = async (value: any) => {
	// console.log(value);

	const assignmentData = {
		subject: value.subject,
		assignmentType : value.assignmentType,
		unitChapter: value.topic,
		gradeLevel: value.grade
	};
	// console.log('url: ', import.meta.env.VITE_API_URL);
	try {
		const response = await api.post(`${import.meta.env.VITE_API_URL}/api/assignment`, assignmentData);
		return response;
	} catch (error:any) {
		console.log(error)
		
		let errorMessage = 'Failed to generate assignment!';
		if (error.response && error.response.data) {
		  const responseData = error.response.data;
		  if (Array.isArray(responseData.message)) {
			errorMessage = responseData.message.join(', '); 
		  } else if (typeof responseData.message === 'string') {
			errorMessage = responseData.message;
		  }
		}
		toast.error(errorMessage);
		return 'Error while generating assignment';
	}
};

export const notesGenerator = async (value: any) => {
	const notesData = {
		gradeLevel: value.grade,
		subject: value.subject,
		unitChapter: value.topic
	};
	// console.log(value);
	try {
		const response = await api.post(`${import.meta.env.VITE_API_URL}/api/notes`, notesData);
		// console.log('response:', response.data);
		return response;
	} catch (error:any) {
		console.log(error)
		let errorMessage = 'Failed to generate notes!'; // Default error message
		if (error.response && error.response.data) {
		  const responseData = error.response.data;
		  if (Array.isArray(responseData.message)) {
			errorMessage = responseData.message.join(', '); // Join array elements into a single string
		  } else if (typeof responseData.message === 'string') {
			errorMessage = responseData.message;
		  }
		}
		toast.error(errorMessage);
		return 'Error while generating lesson';
	}
};

export const testPaperGenerator = async (value: any) => {
	// console.log("test paper value(generator) : ", value);

	const testPaperData = {
		title : value.title,
		totalMarks : value.totalMarks,
		unitChapter: value.unitChapter,
		gradeLevel: value.gradeLevel,
		timeLimit : value.timeLimit,
		numberOfQuestions : parseInt(value.numberOfQuestions, 10),
		board : value.board, 
		subject: value.subject,
	};
	// console.log('url: ', import.meta.env.VITE_API_URL);
	try {
		const response = await api.post(`${import.meta.env.VITE_API_URL}/api/test-paper`, testPaperData);
	
		return response;
	} catch (error:any) {
		let errorMessage = 'Failed to generate test paper!'; // Default error message
		if (error.response && error.response.data) {
		  const responseData = error.response.data;
		  if (Array.isArray(responseData.message)) {
			errorMessage = responseData.message.join(', '); // Join array elements into a single string
		  } else if (typeof responseData.message === 'string') {
			errorMessage = responseData.message;
		  }
		}
		toast.error(errorMessage);
	
		return 'Error while generating test paper';
	}
};
