import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { setAuthentication } from '@store/reducers/auth';
import { setUserInfo } from '@store/reducers/userInfo';
import { setWindowClass } from '@app/utils/helpers';
// import { PfButton } from '@profabric/react-components';
import Hero from '@app/components/hero/Hero';


import * as Yup from 'yup';
import { GoogleLogin } from '@react-oauth/google';
import { authLogin, fetchGoogleUser } from '@app/utils/oidc-providers';
import { Form, InputGroup, Spinner, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faEnvelope, faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import logo from '@image/logo.png';

const Login = () => {
	const [isAuthLoading, setAuthLoading] = useState(false);
	const [isGoogleAuthLoading, setGoogleAuthLoading] = useState(false);
	const dispatch = useDispatch();
	const [showPassword, setShowPassword] = useState(false);

	const navigate = useNavigate();

	const login = async (email: string, password: string) => {
		try {
			console.log('login request sent from login');
			setAuthLoading(true);
			const response: any = await authLogin(email, password);
			dispatch(setUserInfo(response.profile as any));
			dispatch(setAuthentication(response as any));
			setAuthLoading(false);
			await new Promise((resolve) => setTimeout(resolve, 100));
			// if (!response.profile.verified) {
			// 	navigate('/');
			// }
			// else {
			// 	navigate('/socialcalendar')
			// 	// if (
			// 	// 	!response.profile?.subscription ||
			// 	// 	response.profile.subscription.status === 'paused' ||
			// 	// 	(response.profile.subscription.status === 'future' &&
			// 	// 		response.profile.subscription.deleted === true) ||
			// 	// 	response.profile.subscription.status === 'cancelled'
			// 	// )
			// 	// 	navigate('/subscription');
			// 	// else navigate('/socialcalendar');
			// }
			// navigate('/socialcalendar');
		} catch (error: any) {
			setAuthLoading(false);

			toast.error(error.message || 'Login Failed');
		}
	};
	// const login = async (email: string, password: string) => {
	// 	setAuthLoading(true);
	// 	const response: any = await authLogin(email, password);
	// 	dispatch(setAuthentication(response as any));
	// 	toast.success('Login is succeeded!');
	// 	setAuthLoading(false);
	// 	if (!response.profile.verified) {
	// 		navigate('/dashboard');
	// 	}
	// };

	const loginByGoogle = async (credentialResponse: any) => {
		try {
			setGoogleAuthLoading(true);
			setAuthLoading(true);
			const response: any = await fetchGoogleUser(credentialResponse.credential);

			if (response !== null) {
				dispatch(setUserInfo(response.profile as any));
				dispatch(setAuthentication(response as any));
				navigate('/'); // Ensure that this line navigates to the home page.
				setAuthLoading(false);
				setGoogleAuthLoading(false);
			} else {
				// Handle an unexpected response from fetchGoogleUser.
				console.error('Unexpected response:', response);
				toast.error('Login Failed');
				setAuthLoading(false);
				setGoogleAuthLoading(false);
			}
		} catch (error: any) {
			setAuthLoading(false);
			setGoogleAuthLoading(false);
			if (error.message === 'You need to first register with an invite code') {
				navigate('/register');
			} else {
				// Handle other potential errors.
				console.error('Google login error:', error);
				toast.error('Google Login Failed');
			}
		}
	};

	const { handleChange, values, handleSubmit, touched, errors } = useFormik({
		initialValues: {
			email: '',
			password: ''
		},
		validationSchema: Yup.object({
			email: Yup.string().email('Invalid email address').required('Required'),
			password: Yup.string()
				.min(5, 'Must be 5 characters or more')
				.max(30, 'Must be 30 characters or less')
				.required('Required')
		}),
		onSubmit: (values) => {
			login(values.email, values.password);
		}
	});

	useEffect(() => {
		setWindowClass('hold-transition login-page');
	}, []);

	return (
		<div className='container'>
			<Link to="/" style={{ textDecoration: 'none' }}>
    
	<img
        src={logo}
        alt='logo'
        height='80vh'
        style={{ position: 'absolute', top: 10, left: 10, cursor: 'pointer' }}
        className='d-none d-lg-block'
    />
</Link>
			<div className='row d-flex justify-content-center'>
				<div className='mr-n3' style={{ width: '400px' }}>
					<div className='login-box w-100'>
						<div
							className={`border-0 ${
								window.innerWidth < 576
									? 'p-0 overflow-hidden'
									: 'rounded rounded-end-0 shadow card card-primary card-outline'
							}`}
						>
							<div className='card-header text-center d-lg-none d-md-block justify-content-center'>
								<img src={logo} alt='logo' height='80vh' />
							</div>
							<div className='card-body'>
								<h5 className='login-box-msg text-main px-0'>Empowering Teachers, Inspiring Students.</h5>

								<div className='social-auth-links p-0 mt-4 mb-3 col-12 d-flex justify-content-center'>
									<GoogleLogin
										onSuccess={async (credentialResponse) => {
											loginByGoogle(credentialResponse);
										}}
										onError={() => {
											console.log('Login Failed');
											toast.error('Login Failed');
										}}
									/>
								</div>
								<div className='col-12 mt-2'>
									<div className='mb-4 mt-4 d-flex justify-content-center'>Or</div>
								</div>
								<form onSubmit={handleSubmit}>
									<div className='mx-2'>
										<div className='mb-3'>
											<Form.Control
												className='p-4'
												id='email'
												name='email'
												type='email'
												placeholder='Email'
												onChange={handleChange}
												value={values.email}
												isValid={touched.email && !errors.email}
												isInvalid={touched.email && !!errors.email}
												style={{ outline: '1px solid #ced4da', boxShadow: 'none' }} 
											/>

											{touched.email && errors.email && (
												<Form.Control.Feedback type='invalid'>{errors.email}</Form.Control.Feedback>
											)}
										</div>
										

										<div className='mb-3'>
											<div className='position-relative'>
												<Form.Control
													className='p-4 pr-5' 
													id='password'
													name='password'
													type={showPassword ? 'text' : 'password'} 
													placeholder='Password'
													onChange={handleChange}
													value={values.password}
													isValid={touched.password && !errors.password}
													isInvalid={touched.password && !!errors.password}
													style={{ outline: '1px solid #ced4da', boxShadow: 'none' }} 
												/>
												<FontAwesomeIcon
													icon={showPassword ? faEyeSlash : faEye}
													style={{
														position: 'absolute',
														top: '50%',
														right: '10px',
														transform: 'translateY(-50%)',
														cursor: 'pointer'
													}}
													onClick={() => setShowPassword(!showPassword)}
												/>
												{/* {touched.password && errors.password && (
												<Form.Control.Feedback type='invalid'>{errors.password}</Form.Control.Feedback>
											)} */}
											</div>
											{touched.password && errors.password && <div className='text-danger text-sm'>{errors.password}</div>}
										</div>
										<div className='row'>
											<div className='col-12'>
												{/* <PfButton
												block
												type='submit'
												loading={isAuthLoading}
												disabled={isGoogleAuthLoading}
											>
												<span style={{ height: '100px' }}>Sign In</span>
											</PfButton> */}
												<Button disabled={isGoogleAuthLoading} className='w-100 btn-purple' type='submit'>
													{isAuthLoading ? (
														<>
															<Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
															<span className='visually-hidden'> Loading...</span>
														</>
													) : (
														<span style={{ height: '100px' }}>Sign In</span>
													)}
												</Button>
											</div>
											{/* <div className='col-12 mt-3'>
											<div className='mb-1 d-flex justify-content-end small'>
												<Link to='/forgot-password'>Forgot Password ?</Link>
											</div>
										</div> */}
										</div>
									</div>
								</form>
								<div className='col mt-4 text-center justify-content-center'>
									<p>Begin Your Journey with EduAssist </p>
									<Link to='/register' className='text-center'>
										Register Now
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='col-lg-4 d-none d-lg-block ml-n2'>{<Hero height={493} />}</div>
			</div>
		</div>
	);
};

export default Login;
