interface AssessmentMethod {
    method: string;
    questions: string[];
  }

interface LecturePlan {
    lectureNumber: string;
    duration: string;
    topicsCovered: string[];
    keyConcepts: string[];
    activities: string[];
    assessment: AssessmentMethod;
  }
  
  interface ExportToPDFProps {
    fileName: string;
    innerClass: string;
    jsonData: {
      grade: string;
      subject: string;
      unitChapter: string;
      learningObjectives: string[];
      teachingMethodologies: string[];
      advancedActivities: string[];
      simplifiedExplanations: string[];
      lecturePlan: LecturePlan[];
    };
  }
  
  function LessonPlanDocxExport({ fileName, innerClass, jsonData }: ExportToPDFProps) {
    const generateHintList = (hint: string[]) =>
      hint.map((hintItem, index) => `${index + 1}. ${hintItem}`).join('\n');
  
    const handleExportClick = () => {
      const docContent = `
       Grade: ${jsonData.grade}\n
      Subject: ${jsonData.subject}\n
      Unit Chapter: ${jsonData.unitChapter}\n\n
      Learning Objectives:\n
      ${jsonData.learningObjectives.map((obj) => `- ${obj}`).join('\n')}\n\n
      Teaching Methodologies:\n
      ${jsonData.teachingMethodologies.map((method) => `- ${method}`).join('\n')}\n\n
      ${jsonData.simplifiedExplanations.map((explanation) => `- ${explanation}`).join('\n')}\n\n
      Lecture Plan:\n
      ${jsonData.lecturePlan.map(generateLecturePlan).join('\n\n')}\n
      `;
      const blob = new Blob([docContent], { type: 'application/msword' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName + '.doc';
      link.click();
    };

    const generateLecturePlan = (plan: LecturePlan) => {
        const planContent = `Lecture Number: ${plan.lectureNumber}\n
          Duration: ${plan.duration}\n
          Topics Covered:\n
          ${plan.topicsCovered.map((topic) => `- ${topic}`).join('\n')}\n
          Key Concepts:\n
          ${plan.keyConcepts.map((concept) => `- ${concept}`).join('\n')}\n
          Activities:\n
          ${plan.activities.map((activity) => `- ${activity}`).join('\n')}\n
          Assessment Questions:\n
          ${plan.assessment.questions.map((question, index) => `${index + 1}. ${question}`).join('\n')}\n
        `;
        return planContent;
      };
  
    return (
      <div>
        <div className={innerClass}>
          <button
            onClick={handleExportClick}
            className='btn btn-primary btn-purple my-2'
            style={{ backgroundColor: '#a084dc', padding: '10px 22px' }}
          >
            Export as Doc
          </button>
        </div>
      </div>
    );
  }
  
  export default LessonPlanDocxExport;