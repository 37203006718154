import React, { useEffect, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
// import CopyButton from '@app/utils/Copybutton';
import { Tooltip } from 'react-tooltip';

interface PlatformProps {
	post: string;
}

const PostFrom = () => {
	const dispatch = useDispatch();

	const [updating, setUpdating] = useState(false);
	const initialValues = {
		post: '',
	};

	// Validation schema using Yup
	const validationSchema = Yup.object().shape({
		post: Yup.string()
			.required('Post is required')
			.min(30, 'At least 30 characters are required')
			.max(3000, 'At max 30 characters are required'),
	});

	// Formik configuration
	const { handleChange, values, handleSubmit, touched, errors, setFieldValue } =
		useFormik({
			initialValues,
			validationSchema,
			onSubmit: async (values) => {
				// 	const data = {
				// 		ownerId: userId,
				// 		type: 'post',
				// 		date: date,
				// 		platforms: [
				// 			{
				// 				name: 'custom',
				// 				topic: thoughts,
				// 				post: values.post,
				// 				hashtags: values.hashtags
				// 			}
				// 		]
				// 	};
				// 	handleUpdating(true);
				// 	try {
				// 		await refreshAccessToken();
				// 		const response = await fetch(`${import.meta.env.VITE_API_URL}/api/calendar/update`, {
				// 			method: 'PATCH',
				// 			headers: {
				// 				'Content-Type': 'application/json'
				// 			},
				// 			body: JSON.stringify(data)
				// 		});
				// 		if (response.ok) {
				// 			const responseData = await response.json();
				// 			dispatch(setCalendar(responseData as any));
				// 			toast.success('Post Added');
				// 		} else {
				// 			toast.error('Failed to update post');
				// 		}
				// 	} catch (error) {
				// 		toast.error('Failed to update post');
				// 	} finally {
				// 		handleUpdating(false);
				// 	}
			}
		});
	return (
		<div className='d-flex flex-column h-100'>
			<div
				className='mt-1'
				data-tooltip-id={`copy`}
				data-tooltip-content={`Copy Content`}
				data-tooltip-place='left'
			>
				{/* <CopyButton platform={{ topic: thoughts, ...platform }} /> */}
				<Tooltip id={`copy`} />
			</div>
			{updating ? (
				<div className='flex-grow-1 d-flex justify-content-center align-items-center'>
					<Spinner animation='border' role='status'></Spinner>
					<span className='visually-hidden'> Updating...</span>
				</div>
			) : (
				<form onSubmit={handleSubmit}>
					<div className='flex-grow-1 p-2'>
						<Form.Group controlId='post'>
							<Form.Control
								as='textarea'
								rows={10}
								className='h-100'
								name='post'
								disabled={updating}
								onChange={handleChange}
								value={values.post}
								isValid={touched.post && !errors.post}
								isInvalid={touched.post && !!errors.post}
							/>
							{values.post ? values.post.length : 0}/3000 characters
							<Form.Control.Feedback type='invalid'>
								{errors.post}
							</Form.Control.Feedback>
						</Form.Group>
					</div>
				</form>
			)}
		</div>
	);
};

export default PostFrom;
