import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAllTestPaper } from '@app/services/databaseApi';
import { ContentHeader } from '@app/components';
import MuiTable from '@app/components/basic-table/MuiTable';
import { bigScreenCheck } from '@app/utils/helpers';
import { useDispatch } from 'react-redux';
import { setTestPaper } from '@app/store/reducers/testPaper';
import dayjs from 'dayjs'; // Import dayjs library

interface Data {
	id: string;
	time: string;
	title: string;
	totalMarks: string;
	unitChapter: string;
	timeLimit: string;
	numberOfQuestions: number;
	board: string;
	subject: string;
}

interface Column {
	id: 'time' | 'title' | 'unitChapter' | 'board' | 'subject';
	label: string;
	minWidth?: number;
	align?: string;
	format?: (value: string) => string;
}

function createData(
	id: string,
	time: string,
	title: string,
	totalMarks: string,
	unitChapter: string,
	timeLimit: string,
	numberOfQuestions: number,
	board: string,
	subject: string,
): Data {
	return { id, time, title, totalMarks, unitChapter, timeLimit, numberOfQuestions, board, subject };
}

const PreviousTestPaper = () => {
	const bigScreen = bigScreenCheck();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const buttonStyle = {
		height: '50px',
		marginLeft: bigScreen ? '59vh' : '0px', // margin on the right
		padding: '0 10px', // minimum padding
		width: '170px', // fixed width
		alignSelf: 'flex-end', // align to the right within the flex container
	  };

	const formatDate = (dateTime: string): string => {
		return dayjs(dateTime).format('DD/MM/YYYY hh:mm A');
	};

	const [rows, setRows] = useState<any[]>([]);

	const columns: readonly Column[] = [
		{ id: 'time', label: 'Time', minWidth: 150, format: formatDate }, // Apply formatDate to the time column
		{
			id: 'title',
			label: 'Title',
			align: 'left'
		},
		{
			id: 'unitChapter',
			label: 'Chapter',
			align: 'left',
			minWidth: 100
		},
		{
			id: 'board',
			label: 'Board',
			align: 'left'
		},
		{
			id: 'subject',
			label: 'Subject',
			align: 'left'
		}
	];

	useEffect(() => {
		fetchTestPaperHistory();
	}, []);

	const fetchTestPaperHistory = async () => {
		try {
			const testPaperHistory = await getAllTestPaper();
			dispatch(setTestPaper(testPaperHistory));
			setRows(testPaperHistory.map((item: any) => createData(
				item?._id || 'invalid id',
				formatDate(item?.updatedAt) || 'unavailable', // Apply formatDate to updatedAt
				item?.title || 'unavailable',
				item?.totalMarks || 'unavailable',
				item?.unitChapter || 'unavailable',
				item?.timeLimit || 'unavailable',
				item?.numberOfQuestions || 'unavailable',
				item?.board || 'unavailable',
				item?.subject || 'unavailable',
			)));
		} catch (error) {
			console.error('Error fetching test paper history:', error);
		}
	};

	return (
		<div>
			<div style={{display: "flex"}}>
			<ContentHeader title={'Test Paper'} />
			<div className={`d-flex justify-content-end ${bigScreen && 'me-5'} mb-3`}>
				<button className='btn-outline-purple px-7'onClick={() => navigate('/testPaper')} style={buttonStyle}>
					Generate Test Paper	
				</button>
			</div>
			</div>
			<div className={bigScreen ? 'mx-5' : ''}>
				<MuiTable columns={columns} rows={rows} route={'test-paper'} />
			</div>
		</div>
	);
};

export default PreviousTestPaper;
