import { useRef } from 'react';
import jsPDF from 'jspdf';

interface TestPaperData {
  title: string;
  totalMarks: string;
  timeLimit: string;
  unitChapter: string;
  numberOfQuestions: string;
  questions: {
    questionType: string;
    question: string;
    options: string[];
    correctAnswer: string[];
  }[];
}

interface ExportTestPaperToPDFProps {
  fileName: string;
  innerClass: string;
  testPaperData: TestPaperData;
}

function TestPaperPdfExport({ fileName, innerClass, testPaperData }: ExportTestPaperToPDFProps) {
  const handleExportClick = () => {
    const doc = new jsPDF();

    // Define content
    const content = `
      Test Paper Details\n\n
      Title: ${testPaperData.title}\n
      Total Marks: ${testPaperData.totalMarks}\n
      Time Limit: ${testPaperData.timeLimit}\n
      Unit Chapter: ${testPaperData.unitChapter}\n
      Number of Questions: ${testPaperData.numberOfQuestions}\n\n
      ${generateQuestionList(testPaperData.questions).join('\n\n')}
    `;

    // Set font size
    doc.setFontSize(12);

    // Split content into lines
    const lines = doc.splitTextToSize(content, doc.internal.pageSize.width - 20);

    // Initialize y position
    let y = 5;

    // Loop through lines and add to document, handling page breaks
    lines.forEach((line:any) => {
      if (y + 5 > doc.internal.pageSize.height) {
        // Add new page if content exceeds page height
        doc.addPage();
        y = 10; // Reset y position for new page
      }
      doc.text(line,10,y); // Align text to the left, 10 units from left margin
      y += 5; // Increment y position for next line
    });

    // Save document
    doc.save(`${fileName}.pdf`);
  };

  const generateQuestionList = (questions: TestPaperData['questions']) => {
    return questions.map((questionItem, index) => {
      const optionsText = questionItem.options.length > 0 ? questionItem.options.map((option, optionIndex) => `${String.fromCharCode(65 + optionIndex)}. ${option}`).join('\n') : '';
      const correctAnswerText = questionItem.correctAnswer.join('\n');

      return `
        Question ${index + 1}\n\n
        Question Type: ${questionItem.questionType}\n
        Question: ${questionItem.question}\n\n
        Options:\n${optionsText}\n\n
        Correct Answer:\n${correctAnswerText}\n
      `;
    });
  };

  return (
    <div>
      <div className={innerClass}>
        <button
          onClick={handleExportClick}
          className='btn btn-primary btn-purple my-2'
          style={{ backgroundColor: '#a084dc', padding: '10px 22px' }}
        >
          Export as PDF
        </button>
      </div>
    </div>
  );
}

export default TestPaperPdfExport;
