interface ClassNotesProps {
    fileName: string;
    innerClass: string;
    jsonData: {
      subject: string;
      gradeLevel: string;
      unitChapter: string;
      summary: string;
      notes: string;
    };
  }
  
  
  function ClassNotesDocxExport({ fileName, innerClass, jsonData }: ClassNotesProps) {
    const generateHintList = (hint: string[]) =>
      hint.map((hintItem, index) => `${index + 1}. ${hintItem}`).join('\n');
  
    const handleExportClick = () => {
      const docContent = `
      Subject: ${jsonData.subject}\n
      Grade Level: ${jsonData.gradeLevel}\n
      Unit/Chapter: ${jsonData.unitChapter}\n\n
      Summary:\n${jsonData.summary}\n\n
      Notes:\n${jsonData.notes}\n
      `;
      const blob = new Blob([docContent], { type: 'application/msword' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName + '.doc';
      link.click();
    };
  
    return (
      <div>
        <div className={innerClass}>
          <button
            onClick={handleExportClick}
            className='btn btn-primary btn-purple my-2'
            style={{ backgroundColor: '#a084dc', padding: '10px 22px' }}
          >
            Export as Doc
          </button>
        </div>
      </div>
    );
  }
  
  export default ClassNotesDocxExport;