import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
// import {useTranslation} from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSidebarMenu } from '@app/store/reducers/ui';
import MessagesDropdown from '@app/modules/main/header/messages-dropdown/MessagesDropdown';
import NotificationsDropdown from '@app/modules/main/header/notifications-dropdown/NotificationsDropdown';
// import LanguagesDropdown from '@app/modules/main/header/languages-dropdown/LanguagesDropdown';
import UserDropdown from '@app/modules/main/header/user-dropdown/UserDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
	// const [t] = useTranslation();
	const dispatch = useDispatch();
	const navbarVariant = useSelector((state: any) => state.ui.navbarVariant);
	const headerBorder = useSelector((state: any) => state.ui.headerBorder);

	const handleToggleMenuSidebar = () => {
		dispatch(toggleSidebarMenu());
	};

	const getContainerClasses = useCallback(() => {
		let classes = `main-header navbar navbar-expand ${navbarVariant}`;
		if (headerBorder) {
			classes = `${classes} border-bottom-0`;
		}
		return classes;
	}, [navbarVariant, headerBorder]);

	return (
		<nav className={getContainerClasses()} style={{backgroundColor: "#ffffff"}}>
			<ul className='navbar-nav'>
				<li className='nav-item'>
					<button onClick={handleToggleMenuSidebar} type='button' className='nav-link'>
						<FontAwesomeIcon icon={faBars} className='nav-icon mx-3' />
					</button>
				</li>
				<li className='nav-item d-none d-sm-inline-block'>
					<Link to='/' className='nav-link'>
						Home
					</Link>
				</li>
			</ul>
			<ul className='navbar-nav ml-auto' style={{marginRight: "50px"}}>
				{/* <MessagesDropdown /> */}
				{/* <NotificationsDropdown /> */}
				{/* <LanguagesDropdown /> */}
				<span style={{marginTop: "10px", fontWeight: 600, marginRight: "10px"}}>My Profile</span>
				<UserDropdown />
			</ul>
		</nav>
	);
};

export default Header;
