import React, { useState } from 'react';
import { Form, InputGroup, Col, Row } from 'react-bootstrap';
import { setUserInfo } from '@app/store/reducers/userInfo';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const ProfileAccountDataForm = () => {
	const dispatch = useDispatch();
	const userData = useSelector((state: any) => state?.user?.userInfo);

	const [editedData, setEditedData] = useState({ ...userData });

	const handleSaveChanges = () => {
		dispatch(setUserInfo(editedData));
		localStorage.setItem('user', JSON.stringify(editedData));
		toast.success('Update is done.'); 
	};

	const handleFieldChange = (fieldName: string, value: string) => {
		setEditedData((prevData: any) => ({
			...prevData,
			[fieldName]: value
		}));
	};

	return (
		<div className='box' style={{ padding: '20px' }}>
			{/* Email */}
			<div className='fs-5 py-2'>Email</div>
			<InputGroup className='mb-3'>
				<Form.Control
					className='p-4'
					type='email'
					name='email'
					disabled
					value={editedData.email}
					onChange={(e) => handleFieldChange('email', e.target.value)}
				/>
			</InputGroup>

			{/* Phone number */}
			<div className='fs-5 py-2'>Phone Number</div>
			<InputGroup className='mb-3'>
				<Form.Control
					className='p-4'
					type='tel'
					name='phoneNumber'
					value={editedData.mobileNumber}
					onChange={(e) => handleFieldChange('mobileNumber', e.target.value)}
				/>
			</InputGroup>


			{/* update button */}
			<div className='d-flex justify-content-end' style={{ marginTop: '10px' }} onClick={handleSaveChanges}>
				<button className='btn-purple px-5' style={{ height: '50px' }}>
					Update
				</button>
			</div>
		</div>
	);
};

export default ProfileAccountDataForm;
