import { ContentHeader } from '@app/components';
import ExportToDocx from '@app/components/export/DocsExport';
// import { AnswerModel, QuestionModel } from '@app/components/quiz-model/quiz-model';

import { setTestPaper } from '@app/store/reducers/testPaper';
import { bigScreenCheck } from '@app/utils/helpers';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux/';
// import LessonPlanCard from './LessonPlanCard';
import ExportButton from '@app/components/export/ExportButton';
import { getAllTestPaper, deleteTestPaper } from '@app/services/databaseApi';
import TestPaperCard from './TestPaperCard';
import TestPaperPdfExport from '@app/components/export/TestPaperPdfExport';
import TestPaperDocxExport from '@app/components/export/TestPaperDocxExport';
import { useNavigate } from 'react-router-dom';

const TestPaper = () => {
	const dispatch = useDispatch();
	const bigScreen = bigScreenCheck();
	const navigate=useNavigate();

	const queryParameters = new URLSearchParams(location.search);
	const id = queryParameters.get('id') || '0';

	const testPaperData = useSelector((state: any) => state?.testPaper?.testPaperData);

	useEffect(() => {
		fetchTestPaperHistory();
	}, []);

	const fetchTestPaperHistory = async () => {
		const testPaperHistory = await getAllTestPaper();
		dispatch(setTestPaper(testPaperHistory[id]));
	};

	const handleDelete = async () => {
        await deleteTestPaper(testPaperData?._id);
        
        navigate('/test-paper-history');
    };

	const handleClick = () => {
        navigate("/assignment-history");
    };
	return (
		<div>
			<ContentHeader title={'Test Paper'} />

			<div className={bigScreen ? 'mx-5' : ''}>
				<div className='d-flex justify-content-between'>
				<div className='d-flex btn-purple'>
                        <button className='btn' onClick={handleClick} style={{ color: "white", width: "66px"}}>
                            Back
                        </button>
                    </div>
					<div>  
					<ExportButton
						docxChild={
							<TestPaperDocxExport
								fileName={'test paper'}
								innerClass={'d-flex justify-content-center'}
								testPaperData={testPaperData}
							/>
						}
						pdfChild={
							<TestPaperPdfExport
								fileName={'test paper'}
								innerClass={'d-flex justify-content-center'}
								testPaperData={testPaperData}
							/>
						}
					/>
				</div>
				</div>
				<div className='border rounded p-3 my-3'>
					<TestPaperCard data={testPaperData} />
				</div>
				<div className='d-flex justify-content-end'>
				<button
                        className='btn btn-outline-danger px-5'
                        onClick={handleDelete}
                        style={{ height: '50px' }}
                    >
                        Delete Test Paper
                    </button>
				</div>
			</div>
		</div>
	);
};

export default TestPaper;
