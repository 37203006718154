import axios from "axios";
import { setupInterceptors } from "@app/utils/interceptors";


const api = setupInterceptors(
  axios.create({
    baseURL: import.meta.env.NEXT_PUBLIC_ENDPOINT_AUTH,
    headers: {
      "Content-Type": "application/json",
    },
  })
);

export default api;