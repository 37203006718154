import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAllLessonPlan } from '@app/services/databaseApi';
import { ContentHeader } from '@app/components';
import MuiTable from '@app/components/basic-table/MuiTable';
import { bigScreenCheck } from '@app/utils/helpers';
import { setLesson } from '@app/store/reducers/lesson';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs'; // Import dayjs library

interface Data {
	id: string;
	time: string;
	grade: string;
	subject: string;
	topic: string;
	duration: string;
}

interface Column {
	id: 'time' | 'grade' | 'subject' | 'topic' | 'duration';
	label: string;
	minWidth?: number;
	align?: string;
	format?: (value: string) => string;
}

function createData(id: string, time: string, grade: string, subject: string, topic: string, duration: string): Data {
	return { id, time, grade, subject, topic, duration };
}

const PreviousLessonPlan = () => {
	const bigScreen = bigScreenCheck();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	
	const buttonStyle = {
		height: '50px',
		marginLeft: bigScreen ? '59vh' : '10px', // margin on the right
		padding: '0 10px', // minimum padding
		width: '160px', // fixed width
		alignSelf: 'flex-end', // align to the right within the flex container
	  };	
	
	const formatDate = (dateTime: string): string => {
		return dayjs(dateTime).format('DD/MM/YYYY hh:mm A');
	};

	useEffect(() => {
		fetchLessonHistory();
	}, []);

	const [rows, setRows] = useState<any[]>([]);

	const columns: readonly Column[] = [
		{ id: 'time', label: 'Time', minWidth: 150, format: formatDate }, // Apply formatDate to the time column
		{
			id: 'grade',
			label: 'Grade',
			align: 'center'
		},
		{
			id: 'subject',
			label: 'Subject',
			align: 'left',
			minWidth: 100
		},
		{
			id: 'topic',
			label: 'Topic',
			align: 'left'
		},
		{
			id: 'duration',
			label: 'Duration',
			align: 'left'
		}
	];

	

	const fetchLessonHistory = async () => {
		try {
			const lessonHistoryList = await getAllLessonPlan();
			dispatch(setLesson(lessonHistoryList));
			setRows(lessonHistoryList.map((item: any) => createData(
				item?._id || 'invalid id',
				formatDate(item?.updatedAt) || 'unavailable', // Apply formatDate to updatedAt
				item?.grade || 'unavailable',
				item?.subject || 'unavailable',
				item?.learningObjectives[0] || 'unavailable',
				item?.lecturePlan?.length + ' x ' + item?.lecturePlan[0]?.duration || 'unavailable'
			)));
		} catch (error) {
			console.error('Error fetching lesson history:', error);
		}
	};

	return (
		<div>
			<div style={{display: "flex"}}>
			<ContentHeader title={'Lesson Plan'}/>
			<div className={`d-flex justify-content-end ${bigScreen && 'me-5'} mb-3`}>
				<button className='btn-outline-purple px-7'onClick={() => navigate('/lesson')} style={buttonStyle}>
					Generate Lesson	
				</button>
			</div>
			</div>
			<div className={bigScreen ? 'mx-5' : ''}>
				<MuiTable columns={columns} rows={rows} route={'lesson-plan'} />
			</div>
		</div>
	);
};

export default PreviousLessonPlan;
