import React from 'react';

export interface AssignmentData {
    subject: string;
    assignmentType: string;
    unitChapter: string;
    gradeLevel: string;
    assignment: {
        question: string;
        instructions: string;
        hints: string[];
        hint: string[];
    };
}

const convertMarkdownToHTML = (markdownText: any) => {
    // Ensure markdownText is a string
    markdownText = typeof markdownText === 'string' ? markdownText : '';

    // Convert **text** to <strong>text</strong>
    let htmlText = markdownText.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

    // Convert ### Heading to <h3>Heading</h3>
    htmlText = htmlText.replace(/^### (.+)$/gm, '<h4>$1</h4>');

    htmlText = htmlText.replace(/^## (.+)$/gm, '<h3>$1</h3>');

    // Convert --- to <hr>
    htmlText = htmlText.replace(/^---$/gm, '<hr>');

    // Convert * item to <li>item</li> for bullet points
    htmlText = htmlText.replace(/(?:^|\n)\* (.*)/gm, '<li>$1</li>');

    // Convert numbered item to <li>item</li> for numbered lists
    htmlText = htmlText.replace(/(?:^|\n)\d+\. (.*)/gm, '<li>$1</li>');

    return htmlText;
};

const AssignmentCard = ({ data }: { data: AssignmentData }) => {
    return (
        <div style={{
			fontFamily: 'Poppins, sans-serif',
            
		}}>
<div className="container">
            <div className='ml-3'>
                <div className='mt-2'>Assignment Details:</div>
                <div className='text-muted'>Subject: {data?.subject}</div>
                <div className='text-muted'>Assignment Type: {data?.assignmentType}</div>
                <div className='text-muted'>Unit/Chapter: {data?.unitChapter}</div>
                <div className='text-muted'>Grade Level: {data?.gradeLevel}</div>
            </div>
            <div className='ml-3'>
                <div className='mt-2'>Assignment Questions:</div>
                <div className='ps-5 my-4'>
                    <div className='my-3'>
                        Question:
                        <span className='text-muted' dangerouslySetInnerHTML={{ __html: convertMarkdownToHTML(data?.assignment?.question) }} />
                    </div>
                    <div className='my-3'>
                        Instructions:
                        <span className='text-muted' dangerouslySetInnerHTML={{ __html: convertMarkdownToHTML(data?.assignment?.instructions) }} />
                    </div>
                    <div className='my-3'>
                        Hints:
                        <ul>
                            {data?.assignment?.hints?.map((hintItem, hintIndex) => (
                                <li key={hintIndex} className='text-muted' dangerouslySetInnerHTML={{ __html: convertMarkdownToHTML(hintItem) }} />
                            ))}
                            {/* Render 'hint' if it exists */}
                            {data?.assignment?.hint && (
                                <li className='text-muted' dangerouslySetInnerHTML={{ __html: convertMarkdownToHTML(data?.assignment?.hint) }} />
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
};

export default AssignmentCard;
