import { createSlice } from '@reduxjs/toolkit';

export interface AuthState {
	notesData?: any;
}

const initialState: AuthState = {
	notesData: undefined
};

export const notesSlice: any = createSlice({
	name: 'notes',
	initialState,
	reducers: {
		setNotes: (state: any, { payload }: any) => {
			state.notesData = payload;
			console.log('notes payload ', payload);
		}
	}
});

export const { setNotes } = notesSlice.actions;

export default notesSlice.reducer;
