import { SmallBox } from '@app/components';
import React, { useEffect } from 'react';
import { ContentHeader } from '@components';

import Reader from '@image/reader.png';
import LessonPlan from '@image/lessonPlan.png';
import AssignmentGenerator from '@image/assignmentGenerator.png';
import Calendar from '@image/calendar.png';
import QuizGenerator from '@image/quizGenerator.png';
import TestPaper from '@image/testPaper.png';
import TakingNotes from '@image/takingNotes.png';
import { useSelector } from 'react-redux';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faLock, faEnvelope } from '@fortawesome/free-solid-svg-icons';
// import { useNavigate } from 'react-router-dom';
// import { setUserInfo } from '@app/store/reducers/userInfo';
// import { useDispatch } from 'react-redux';

const Dashboard = () => {
	const userInfo = useSelector((state: any) => state?.user?.userInfo);
	const capitalizeName = (name:any) => {
		if (!name) return '';
		return name.charAt(0).toUpperCase() + name.slice(1);
	};

	return (
		<div>
			<ContentHeader title='Home' />
			<section className='content'>
				<div className='container-fluid'>
					<div
						className='card rounded-3 d-flex flex-row justify-content-around align-items-center'
						style={{ backgroundColor: '#A084DC33' }}
					>
						<div className='col-6 py-4 ps-3'>
						<h3 className='py-2'>Hello {capitalizeName(userInfo.name)},</h3>
							<span className='fs-5' style={{ fontWeight: 300 }}>
								We strive to empower educators, inspire learners, and nurture curiosity, making education an engaging
								and accessible journey for all.
							</span>
						</div>
						<div className='pb-2 mt-n4'>
							<img src={Reader} alt='reader' />
						</div>
					</div>
				</div>
				<div className='container-fluid'>
					<div className='row justify-content-center'>
						<SmallBox title='Lesson Plan Generator' route='/lesson-history' image={LessonPlan} />
						<SmallBox title='Quiz Generator' route='/quiz-history' image={QuizGenerator} />
						<SmallBox title='Assignment Generator' route='/assignment-history' image={AssignmentGenerator} />
						{/* <SmallBox title='Academic Calendar Generator' route='/academic' image={Calendar} /> */}
						<SmallBox title='Class Notes Generator' route='/notes-history' image={TakingNotes} />
						<SmallBox title='Test Paper Generator' route='/test-paper-history' image={TestPaper} />
					</div>
				</div>
			</section>
		</div>
	);
};

export default Dashboard;
